export enum CountryCode {
	GLOBAL = 'global',
	INTERNATIONAL = 'international',
	EN = 'en',
	ES = 'es',
	CH = 'ch',
	FR = 'fr',
	IT = 'it',
	DE = 'de',
	US = 'us',
}
