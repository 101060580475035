'use client';

import { getFieldFileUploadValueValidations } from '@/components/shared/Forms/FileUploadFormField/FileUploadFormField.utils';
import { FileUpload } from '@/components/shared/Forms/FileUploadFormField/components/FileUpload';
import { IFormFieldFileUploadResponse } from '@/interfaces/blocks/forms';
import { FC } from 'react';
import { Controller, FieldValues, Validate, useFormContext } from 'react-hook-form';

export interface IFormFieldFileUploadProps {
	field: IFormFieldFileUploadResponse;
	translations: { [key: string]: string };
}

export type IFormFieldFileUploadValidations = Record<
	'required' | 'maxFileSize' | 'maximumNumberOfFiles',
	Validate<File[], FieldValues>
>;

export const FormFieldUpload: FC<IFormFieldFileUploadProps> = ({ field, translations }) => {
	const { control, setValue, setError, trigger } = useFormContext();

	if (!field?.contentLink?.expanded) {
		return;
	}

	const id = field.contentLink.id;
	const {
		fieldLabel,
		placeholderText,
		allowedFileTypes,
		mandatoryErrorMessage,
		fieldName,
		fieldDescription,
		allowCapturePhotosAndVideos,
		maximumFileSize,
		maximumNumberOfFiles,
		allowedTotalSizeOnMultiple,
		isMandatory,
		allowedFilesInfoText,
	} = field.contentLink.expanded;

	const name = fieldName || String(id);

	const validations = getFieldFileUploadValueValidations(
		{
			isMandatory,
			maximumNumberOfFiles,
			allowedTotalSizeOnMultiple,
		},
		mandatoryErrorMessage
	);

	return (
		<Controller
			control={control}
			name={name}
			defaultValue={[]}
			rules={{
				validate: validations,
			}}
			render={({ field: { value, ref }, fieldState: { isTouched, error }, formState: { isSubmitting } }) => {
				return (
					<FileUpload
						ref={ref}
						fieldLabel={fieldLabel}
						placeholderText={placeholderText}
						allowedFilesInfoText={allowedFilesInfoText}
						allowedFileTypes={allowedFileTypes}
						mandatoryErrorMessage={mandatoryErrorMessage}
						fieldName={fieldName}
						fieldDescription={fieldDescription}
						allowCapturePhotosAndVideos={allowCapturePhotosAndVideos}
						maximumFileSize={maximumFileSize}
						maximumNumberOfFiles={maximumNumberOfFiles}
						allowedTotalSizeOnMultiple={allowedTotalSizeOnMultiple}
						isMandatory={isMandatory}
						formValue={value}
						validations={validations}
						setFormValue={setValue}
						setFormError={setError}
						translations={translations}
						disabled={isSubmitting}
						trigger={trigger}
						formErrors={error}
						isFieldTouched={isTouched}
						fieldValue={value}
						isSubmitting={isSubmitting}
					/>
				);
			}}
			key={field.contentLink.id}
		/>
	);
};
