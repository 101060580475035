import { styled } from '@/styled-system/jsx';
import { poly } from '@/libs/poly';

export const Text = styled(poly('p'), {
	base: {
		color: 'inherit',
		textAlign: 'left',
	},
	defaultVariants: {
		size: 'lg',
	},
	variants: {
		size: {
			lg: {
				// Body 1: 18px
				fontSize: '1.125rem',
				lineHeight: '142%',
				letterSpacing: '-0.02rem',
			},
			md: {
				// Body 2: 16px
				fontSize: '1rem',
				lineHeight: '141%',
				letterSpacing: '-0.015rem',
			},
			sm: {
				// Body 3: 14px
				fontSize: '0.875rem',
				fontWeight: 'regular',
				lineHeight: '146%',
				letterSpacing: '-0.015rem',
			},
			xs: {
				// Caption: 12px
				fontSize: '0.75rem',
				letterSpacing: '-0.01rem',
				fontWeight: 'regular',
				lineHeight: '130%',
			},
			xxs: {
				// Extra small: 10px
				fontSize: '0.625rem',
				fontWeight: 'semibold',
				lineHeight: '160%',
				letterSpacing: '-0.005rem',
			},
		},
		noOfLines: {
			1: {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
		},
	},
});
