const domain = process.env.NEXT_PUBLIC_CLOUDINARY_DOMAIN;
const folder = process.env.NEXT_PUBLIC_CLOUDINARY_UPLOAD_FOLDER;
const account = process.env.NEXT_PUBLIC_CLOUDINARY_ACCOUNT;
const defaultImageTransformations = 'f_auto/q_auto:best/g_face,c_fill,g_auto,';
const defaultVideoTransformations = 'f_auto/q_auto:best/c_fill,';

export const cloudinaryOverrides = {
	domain,
	folder,
	account,
};

export interface ICloudinaryEnvironmentVariables {
	domain?: string;
	folder?: string;
	account?: string;
}

interface ICloudinaryMediaSourceUrlProps {
	src: string;
	width?: number | string;
	height?: number | string;
	transformations?: string;
	cloudinaryVariables?: ICloudinaryEnvironmentVariables;
}

interface ICloudinaryImageProps extends ICloudinaryMediaSourceUrlProps {
	maxSrcSetWidth?: number;
}

const removeTrailingSlash = (url: string): string => {
	if (url.endsWith('/')) {
		return url.slice(0, url.length - 1);
	} else {
		return url;
	}
};

const constructCloudinaryUrlPrefix = (cloudinaryVariables?: ICloudinaryEnvironmentVariables) => {
	const domainUrl = cloudinaryVariables?.domain || domain;
	const accountUrl = cloudinaryVariables?.account || account;

	let cloudinaryUrl = `${domainUrl}`;

	if (accountUrl && accountUrl.length > 0) {
		cloudinaryUrl += `/${accountUrl}`;
	}

	return cloudinaryUrl;
};

export const getCloudinaryMediaBreakpoints = (maxWidth = 3840, minWidth = 256): number[] => {
	return [256, 512, 768, 1024, 1280, 1440, 1920].filter((width) => width <= maxWidth && width >= minWidth);
};

export const schemaImage = (src: string): string => {
	const cloudinaryUrlPrefix = constructCloudinaryUrlPrefix();

	const imgSrc = `${cloudinaryUrlPrefix}/image/upload/${folder}` + src;

	return imgSrc;
};

export const getCloudinaryVideoSourceUrl = ({
	src,
	width,
	height = 'auto',
	transformations = defaultVideoTransformations,
	cloudinaryVariables,
}: ICloudinaryMediaSourceUrlProps) => {
	const cloudinaryUrlPrefix = constructCloudinaryUrlPrefix(cloudinaryVariables);
	const sanitizedSrc = removeTrailingSlash(src);

	const folderUrl = cloudinaryVariables?.folder || folder;

	if (width) {
		return `${cloudinaryUrlPrefix}/video/upload/${transformations}w_${width},h_${height}/${folderUrl}${sanitizedSrc}`;
	}

	return `${cloudinaryUrlPrefix}/video/upload/${folderUrl}${sanitizedSrc}`;
};

export const getCloudinaryImageSourceUrl = ({
	src,
	width = 'iw',
	height,
	transformations = defaultImageTransformations, // provide transformations string by ending it with a comma separator
	cloudinaryVariables,
}: ICloudinaryMediaSourceUrlProps) => {
	if (!src) {
		return '';
	}
	const cloudinaryUrlPrefix = constructCloudinaryUrlPrefix(cloudinaryVariables);
	const sanitizedSrc = removeTrailingSlash(src);

	const folderUrl = cloudinaryVariables?.folder || folder;

	if (width) {
		return `${cloudinaryUrlPrefix}/image/upload/${transformations}w_${width}${
			height ? `,h_${height}` : ''
		}/${folderUrl}${sanitizedSrc}`;
	}

	return `${cloudinaryUrlPrefix}/image/upload/${folderUrl}${sanitizedSrc}`;
};

export const getCloudinaryImageSourceSetUrl = (props: ICloudinaryMediaSourceUrlProps) => {
	const imageSourceUrl = getCloudinaryImageSourceUrl(props);

	return imageSourceUrl + ` ${props.width}w`;
};

export const getCloudinaryImageSourceSet = (
	src: string,
	maxSrcSetWidth?: number,
	cloudinaryVariables?: ICloudinaryEnvironmentVariables
) => {
	if (!src) {
		return '';
	}
	const mediaBreakpoints = getCloudinaryMediaBreakpoints(maxSrcSetWidth);

	return mediaBreakpoints
		.map((widthBreakpoint) => getCloudinaryImageSourceSetUrl({ src, width: widthBreakpoint, cloudinaryVariables }))
		.join(',');
};

export const getCloudinaryImageProps = (props: ICloudinaryImageProps) => {
	const imageSourceUrl = getCloudinaryImageSourceUrl(props);
	const imageSourceSet = getCloudinaryImageSourceSet(props.src, props.maxSrcSetWidth, props.cloudinaryVariables);

	return {
		imgSrc: imageSourceUrl,
		srcSet: imageSourceSet,
	};
};
