import { Heading } from '@/components/core/Heading/Heading';
import { Text } from '@/components/core/Text/Text';
import { SearchIcon } from '@/icons/SearchIcon';
import { Box, Flex, FlexProps } from '@/styled-system/jsx';
import { FC } from 'react';

interface IFilterEmptyStateProps extends FlexProps {
	title: string;
	body: string;
}

export const FilterEmptyState: FC<IFilterEmptyStateProps> = ({ title, body, ...rest }) => {
	return (
		<Flex
			w="100%"
			flexDirection={{ base: 'column', md: 'row' }}
			p={{ base: 6, md: 12 }}
			gap={{ base: 6, md: 9 }}
			bg="surface.interactive.card.secondary"
			{...rest}
		>
			<SearchIcon width={42} height={42} color="text.regular.default" />
			<Box w="80%">
				<Heading type="h4">{title}</Heading>
				<Text>{body}</Text>
			</Box>
		</Flex>
	);
};
