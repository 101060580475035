import { Button } from '@/components/core/Button/Button';
import { Text } from '@/components/core/Text/Text';
import useQueryParams from '@/hooks/useQueryParams';
import { CloseIcon } from '@/icons/CloseIcon';
import { Flex } from '@/styled-system/jsx';

export const FilterCount = ({ count = '', label }: { count?: number | string; label: string }) => {
	const { query, change } = useQueryParams();

	if (Object.keys(query).length === 0) {
		return null;
	}

	return (
		<Flex alignSelf="auto" gap={2} alignItems="center">
			<Text size="sm" w="max-content">
				{count} {label}
			</Text>
			{Object.keys(query).length > 0 ? (
				<Button
					style={{ w: '18px', h: '18px' }}
					size="unsized"
					leftIcon={<CloseIcon width={16} height={16} />}
					onClick={() => {
						change({});
					}}
				></Button>
			) : null}
		</Flex>
	);
};
