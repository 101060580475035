import { FC } from 'react';
import { ButtonLink, IButtonLinkProps } from '@/components/core/ButtonLink/ButtonLink';
import { generateTrackingId, getTrackingHref } from '@/utils/tracking/tracking';
import { ITrackedProps, Tracked } from '@/components/shared/tracking/Tracked';
import { ITrackingTarget } from '@/types/tracking';
import { publicDomainUrl } from '@/constants/global';

interface IButtonLinkTrackedProps extends IButtonLinkProps {
	trackingData?: Partial<ITrackedProps>;
	trackingTarget?: ITrackingTarget;
	preventAutomatedTracking?: boolean;
	children?: React.ReactNode;
}

export const ButtonLinkTracked: FC<IButtonLinkTrackedProps> = ({
	trackingData = {},
	trackingTarget,
	preventAutomatedTracking,
	href,
	children,
	...props
}) => {
	if (preventAutomatedTracking) {
		return <ButtonLink href={href} {...props} />;
	}

	const trackingId = generateTrackingId();

	return (
		<Tracked
			{...trackingData}
			trackingId={trackingId}
			trackingTarget={trackingTarget}
			href={getTrackingHref(href, publicDomainUrl)}
		>
			<ButtonLink data-trackingid={trackingId} href={href} {...props}>
				{children}
			</ButtonLink>
		</Tracked>
	);
};
