const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];
const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export default function getEnglishLocale(country: string): ILocale {
	return {
		name: 'en' + (country ? '-' + country : ''),
		weekdays: weekdays,
		weekStart: country == 'us' ? 0 : 1,
		weekdaysShort: weekdays.map((day) => day.substring(0, 3)),
		weekdaysMin: weekdays.map((day) => day.substring(0, 2)),
		months: months,
		monthsShort: shortMonths,
		formats: {},
		relativeTime: {},
	};
}
