/**
 * @packageDocumentation
 * @module tracking_types
 */

/**
 * The Tracking information which will be converted and sent to Vontobel via Tealium Tracking.
 */
interface ITrackingInformation {
	/**
	 * Provides a general category in which the tracking values may be categorized.
	 * @example form
	 * @example link
	 */
	techCategory?: string;

	/**
	 * Provides a more detailed category / subcategory.
	 * @example link-internal
	 * @example social
	 */
	category?: string;

	/**
	 * Provides the action, which triggered the tracking event. Most common is the event action click, but generally speaking this coincides with the event in the DOM.
	 * @example click
	 * @example scroll
	 */
	action?: string;

	/**
	 * __
	 */
	nonInteraction?: boolean;

	/**
	 * The label is highly dependent on its Use case, most commonly it refers to something relating the goal of the link.
	 * @example Email address of an email link
	 * @example The title of an article
	 */
	label?: string | undefined;

	/**
	 * In most cases the value is something, the user has submitted and not something that is set by the software.
	 * @example First and Last name in a form
	 * @example Date for a date picker
	 */
	value?: string | undefined;

	/**
	 * Generally the conversion ID is used to determine the area in which the event was triggered. The conversion ID is provided by vontobel for any component.
	 * In most cases the conversion ID is empty and it is only triggered, once the area of the software changes, e.g. when the first form field is entered.
	 * @example "vt-appointment-scheduler"
	 */
	conversionId?: string | undefined;

	/** The media source, i.e. where the media can be found.
	 * @example "corpweb-cms"
	 */
	mediaSource?: string;
	/** The Media Type.
	 * @example "document"
	 */
	mediaType?: string;
	/**
	 * The value is used to describe further information about the media, for example the title of the document or something similar.
	 */
	mediaValue?: string;

	assetType?: string;
	assetValue?: string;
	targetUrl?: string;
}

/**
 * The TrackedBlockInformation is derived from values in the CMS or are defined by the CMS user.ö
 */
interface ITrackedBlockInformation {
	/**
	 * The CMS Id of the of the block. This is the ID of the "class" so to say, so it will be the same for every Block of type "LinkBlock".
	 */
	blockId: string;
	/**
	 * The name of the block. Similarly to blockId, this is the name of the block "class".
	 * @example "LinkBlock"
	 */
	blockName: string;
	/**
	 * The ID of the block instance in the CMS.
	 */
	blockInstanceId: string;
	/**
	 * The title of the block instance in the CMS.
	 */
	blockInstanceName: string;

	/**
	 * The version of the CMS block.
	 */
	blockVersion: string;

	/**
	 * Whether the component should be considered for collecting block data. When this flag is set to false, children will ignore this compnent when trying to merge parent component tracking Data.
	 * Even when this option is false it will still be considered in the component path and sent to vontobel.
	 */
	blockIsRelevant: boolean;

	/**
	 * The Fallback language of this CMS-Block.
	 */
	fallbackLanguage?: string;
	/**
	 * The Fallback country of this CMS-Block.
	 */
	fallbackCountry?: string;
}

/**
 * Since the TrackingInformation and the TrackingBlockInformation follow different rules for merging the relating properties, they should be defined separately.
 */
interface ITrackingEventData {
	/**
	 * How deep the tracked component lies within the tree of tracked components. For a component on the 2nd layer, meaning the component has one parent component,
	 * the nestingLevel would be 1.
	 * Elemtents that have no tracked parents have a nesting level of 0.
	 */
	nestingLevel: number;
	/**
	 * Contains the block informaiton of all parent components, starting with the root component. The last component is this Tracked component.
	 * Note that every block information is contained in levels, even if copmonentIsRelevant is set to false.
	 *
	 * The last element of this array may coincide with the component of ITrackingEventData, but when componetIsRelevant is set to false this will not be case.
	 */
	levels: ITrackedBlockInformation[];
	/**
	 * The EventData contains information about the relevant tracking block and tracking information about the tracked event.
	 */
	event: ITrackingInformation;
	/**
	 * The tracking Block information of the current tracked block.
	 */
	component?: ITrackedBlockInformation;
}

/**
 * When tracking an internal link, the block that is linking to the page might implement tracking data of the target url.
 * These will be replaced when reverting back the automated tracking functionality.
 */
interface ITrackingTarget {
	/**
	 * The CMS-Id delivered via the content delivery API of the target page / content.
	 */
	targetContentId?: string;
	/**
	 * The Title of the delivered page.
	 */
	targetPageTitle?: string;
	/**
	 * The targeted file, in case the link is pointing to a file.
	 */
	targetFile?: string;
}

enum TrackingCategory {
	Disclaimer = 'disclaimer',
}

export enum EventCategory {
	SOCIAL = 'social',
	MEDIA = 'media',
	LINK_INTERNAL = 'link-internal',
	LINK_ANCHOR = 'link-anchor',
	LINK_EMAIL = 'link-email',
	LINK_TEL = 'link-tel',
	LINK_EXTERNAL = 'link-external',
	LINK_PRINT = 'link-print',
}

export const defaultConversionIdByEventCategory: { [key in EventCategory]?: string } = {
	[EventCategory.LINK_EMAIL]: 'vt-link-email',
	[EventCategory.LINK_TEL]: 'vt-link-tel',
	[EventCategory.MEDIA]: 'vt-link-media',
};

/**
 * This class is used for tracking configurations.
 * The configuration will try to load different tracking attributes depending on the math that is given.
 */
interface ITrackingConfigurationItem {
	EventCategory: EventCategory;
	EventTechCategory: string;
	EventAction: string;
	EventLabel: string;
	EventValue?: string;
	ConversionId?: string;
	MediaType?: string;
	MediaValue?: string;
	MediaSource?: string;
}

/**
 * The Tracking usecase is used for generated tracking Data based on this enumeration. In some cases this makes the CMS-Configuration easier.
 * See the automatedTracking module for more information.
 */
enum TrackingUseCase {
	/**
	 * Any link to a document of some sort (picture, pdf, etc) will be tracked using this setting.
	 */
	Document = 'Document',
	/**
	 * Links to print something (for example the current article) are tracked using this configuration
	 */
	Print = 'Print',
}

/**
 * The data sent when triggering the DOM attached event.
 * The event is DOM attached to circumvent client side rendering.
 */
interface IAttachedDOMTrackingEventInfo {
	/**
	 * The Event that was originally responsible for the tracking event.
	 */
	originalEvent: Event;
	/**
	 * In some cases, while sending the event information, additional custom logic might be necessary, that cannot be received from the block information.
	 * The customTrackingInformation has the highest priority.
	 */
	customTrackingInformation: ITrackingInformation;
	/**
	 * When set to true, all merging steps are ignored and only the customTrackingInformation will be sent.
	 * However the blockInformation and nesting information are still gathered, making this a viable option
	 * to overwrite any tracking behavior while not disrupting the basic functionality too much.
	 */
	skipMerge: boolean;
}

/**
 * Settings needed to configure the Tealium instance from Vontobel.
 */
interface ITrackingPageSettings {
	/**
	 * Country of the current page.
	 * @category General CMS Data
	 */
	country: string;
	/**
	 * Language of the current page.
	 */
	language: string;
	/**
	 * Fallback language of the current page.
	 */
	fallback_language: string;
	/**
	 * Fallback Country of the current Page.
	 */
	fallback_country: string;
	/**
	 * Version of the Page, starting at 0.
	 */
	page_version: string;
	/**
	 * Used to categorize Pages for tracking.
	 */
	page_type: string;
	/**
	 * ID of the page. Same ID used to request the content delivery api.
	 */
	page_id: string;
	/**
	 * When the page updated last in the format "yyyy-mm-dd"
	 */
	last_update: string;
	/**
	 * Used to group similar pages for tracking.
	 */
	content_id?: string;

	// articlepage related
	/**
	 * If ArticlePage, this is the author of the article.
	 */
	page_author?: string;
	/**
	 * If ArticlePage, this is the date when the article was published.
	 */
	publish_date?: string;

	// User related information saved in cookie data.
	/**
	 * Relating to the current and its visitor group.
	 */
	investor_type?: string;
	/**
	 * Domicile of the current User.
	 */
	domicile?: string;
	/**
	 * HashId of the salesforce Profile for the current user.
	 */
	hash_id?: string;
	/**
	 * The preferred language for the current user.
	 */
	preferred_language: string;

	// Possible Settings, not currently in use.
	pageTitle?: string;
	asset_source?: string;
	asset_type?: string;
	asset_value?: string;

	touchpoint_version?: number;
}

/**
 * Automatic link tracking works with both absolute and relative hrefs and will try to match the href to a configuration item.
 * The end tracked event requires the absolute href.
 * We pass it to the Tracked component as it can't be calculated at a later point because it requires the public url env variable.
 */
interface ITrackingHref {
	originalHref: string;
	absoluteHref?: string;
}

enum EventAttachMode {
	Auto,
	Custom,
}

export {
	type ITrackingInformation,
	type ITrackedBlockInformation,
	type ITrackingEventData,
	type ITrackingTarget,
	TrackingCategory,
	type ITrackingConfigurationItem,
	TrackingUseCase,
	type IAttachedDOMTrackingEventInfo,
	type ITrackingPageSettings,
	EventAttachMode,
	type ITrackingHref,
};
