import { EventCategory, ITrackingConfigurationItem } from '@/types/tracking';

interface IAutomatedTrackingConfigItem extends ITrackingConfigurationItem {
	HrefRegex: RegExp;
}

type AutomatedTrackingConfig = IAutomatedTrackingConfigItem[];

const automatedTrackingConfig: AutomatedTrackingConfig = [
	{
		HrefRegex: /^https?:\/\/(www\.)?facebook\.com\/sharer\/sharer\.php/i,
		EventCategory: EventCategory.SOCIAL,
		EventTechCategory: 'link',
		EventAction: 'share',
		EventLabel: 'facebook',
		ConversionId: 'vt-social-share',
	},
	{
		HrefRegex: /^https?:\/\/(www\.)?facebook\.com\//i,
		EventCategory: EventCategory.SOCIAL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: 'facebook',
	},
	{
		HrefRegex: /^https?:\/\/(www\.)?twitter\.com\/intent\/tweet?/i,
		EventCategory: EventCategory.SOCIAL,
		EventTechCategory: 'link',
		EventAction: 'share',
		EventLabel: 'twitter',
		ConversionId: 'vt-social-share',
	},
	{
		HrefRegex: /^https?:\/\/(www\.)?twitter\.com\//i,
		EventCategory: EventCategory.SOCIAL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: 'twitter',
	},
	{
		HrefRegex: /^https?:\/\/(www\.)?xing\.com\/spi\/shares\/new?/i,
		EventCategory: EventCategory.SOCIAL,
		EventTechCategory: 'link',
		EventAction: 'share',
		EventLabel: 'xing',
		ConversionId: 'vt-social-share',
	},
	{
		HrefRegex: /^https?:\/\/(www\.)?xing\.com\//i,
		EventCategory: EventCategory.SOCIAL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: 'xing',
	},
	{
		HrefRegex: /^https?:\/\/(www\.)?linkedin\.com\/sharing?/i,
		EventCategory: EventCategory.SOCIAL,
		EventTechCategory: 'link',
		EventAction: 'share',
		EventLabel: 'linkedin',
		ConversionId: 'vt-social-share',
	},
	{
		HrefRegex: /^https?:\/\/(www\.)?linkedin\.com\//i,
		EventCategory: EventCategory.SOCIAL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: 'linkedin',
	},
	{
		HrefRegex:
			/^(https?:\/\/[a-z0-9._-]*\.?vontobel.com)?\/(siteassets|globalassets|contentassets|document)(\/[a-z0-9._-]+)*\/([a-z0-9._-]+\.(jpg|jpeg|png|svg))(\?.*)?$/i,
		EventCategory: EventCategory.MEDIA,
		EventTechCategory: 'link',
		EventAction: 'download',
		EventLabel: '{regex-4}',
		MediaType: 'image',
		MediaValue: '{document-id}',
		MediaSource: 'corpweb-cms',
		ConversionId: '{conversion-id}',
	},
	{
		HrefRegex:
			/^(https?:\/\/[a-z0-9._-]*\.?vontobel.com)?\/(siteassets|globalassets|contentassets|document)(\/[a-z0-9._-]+)*\/([a-z0-9-_]+(?:\.[a-z0-9-]+))+(\?.*)?$/i,
		EventCategory: EventCategory.MEDIA,
		EventTechCategory: 'link',
		EventAction: 'download',
		EventLabel: '{regex-4}',
		MediaType: 'document',
		MediaValue: '{document-id}',
		MediaSource: 'corpweb-cms',
		ConversionId: '{conversion-id}',
	},
	{
		HrefRegex:
			/^((\/)|(\.\/)|(\.\.\/))(siteassets|globalassets|contentassets|document)(\/[a-z0-9._-]+)*\/([a-z0-9._-]+\.(jpg|jpeg|png|svg))(\?.*)?$/i,
		EventCategory: EventCategory.MEDIA,
		EventTechCategory: 'link',
		EventAction: 'download',
		EventLabel: '{regex-4}',
		MediaType: 'image',
		MediaValue: '{document-id}',
		MediaSource: 'corpweb-cms',
		ConversionId: '{conversion-id}',
	},
	{
		HrefRegex:
			/^((\/)|(\.\/)|(\.\.\/))(siteassets|globalassets|contentassets|document)(\/[a-z0-9._-]+)*\/([a-z0-9-_]+(?:\.[a-z0-9-]+))+(\?.*)?$/i,
		EventCategory: EventCategory.MEDIA,
		EventTechCategory: 'link',
		EventAction: 'download',
		EventLabel: '{regex-4}',
		MediaType: 'document',
		MediaValue: '{document-id}',
		MediaSource: 'corpweb-cms',
		ConversionId: '{conversion-id}',
	},
	{
		HrefRegex: /^https?:\/\/[a-z0-9._-]*\.vontobel\.com\/([^#]+)?#(.+)?/i,
		EventCategory: EventCategory.LINK_ANCHOR,
		EventTechCategory: 'link',
		EventAction: 'click',
		ConversionId: '{conversion-id}',
		EventLabel: '{regex-2}',
	},
	{
		HrefRegex: /^(https?:\/\/[a-z0-9._-]*\.vontobel\.com)(\/|$)/i,
		EventCategory: EventCategory.LINK_INTERNAL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: '{target-contentid}|{target-pagetitle}',
		ConversionId: '{conversion-id}',
	},
	{
		HrefRegex: /^(https?:\/\/[a-z0-9._-]*\.vontobel\.com)(\/|$)/i,
		EventCategory: EventCategory.LINK_INTERNAL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: '{target-url}',
		ConversionId: '{conversion-id}',
	},
	{
		HrefRegex: /^https?:\/\/((a-)?www\.)cosmofunding\.com(\/|$)/i,
		EventCategory: EventCategory.LINK_INTERNAL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: '{target-url}',
		ConversionId: '{conversion-id}',
	},
	{
		HrefRegex: /^#(.+)?/i,
		EventCategory: EventCategory.LINK_ANCHOR,
		EventTechCategory: 'link',
		EventAction: 'click',
		ConversionId: '{conversion-id}',
		EventLabel: '{regex-1}',
	},
	{
		HrefRegex: /^\/([^#]+)?#(.+)?/i,
		EventCategory: EventCategory.LINK_ANCHOR,
		EventTechCategory: 'link',
		EventAction: 'click',
		ConversionId: '{conversion-id}',
		EventLabel: '{regex-2}',
	},
	{
		HrefRegex: /^mailto:(.*?)\?.*$/i,
		EventCategory: EventCategory.LINK_EMAIL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: '{regex-1}',
		ConversionId: '{conversion-id}',
	},
	{
		HrefRegex: /^mailto:(.*)$/i,
		EventCategory: EventCategory.LINK_EMAIL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: '{regex-1}',
		ConversionId: '{conversion-id}',
	},
	{
		HrefRegex: /^tel:(.*)\?.*$/i,
		EventCategory: EventCategory.LINK_TEL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: '{regex-1}',
		ConversionId: '{conversion-id}',
	},
	{
		HrefRegex: /^tel:(.*)$/i,
		EventCategory: EventCategory.LINK_TEL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: '{regex-1}',
		ConversionId: '{conversion-id}',
	},
	{
		HrefRegex: /^((\/)|(\.\/)|(\.\.\/))(.*)/i,
		EventCategory: EventCategory.LINK_INTERNAL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: '{target-contentid}|{target-pagetitle}',
		ConversionId: '{conversion-id}',
	},
	{
		HrefRegex: /^((\/)|(\.\/)|(\.\.\/))(.*)/i,
		EventCategory: EventCategory.LINK_INTERNAL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: '{regex-5}',
		ConversionId: '{conversion-id}',
	},
	{
		HrefRegex: /.*/,
		EventCategory: EventCategory.LINK_EXTERNAL,
		EventTechCategory: 'link',
		EventAction: 'click',
		EventLabel: '{target-url}',
		ConversionId: '{conversion-id}',
	},
];

export { type IAutomatedTrackingConfigItem, type AutomatedTrackingConfig, automatedTrackingConfig };
