import { FC, SVGAttributes } from 'react';

export const CopyIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M26.4127 5.5873H8.63492V8.63491H5.5873V26.4127H23.3651V23.3651H26.4127V5.5873ZM21.8413 24.8889H7.11111V10.1587H8.63492V23.3651H21.8413V24.8889ZM24.8889 21.8413H10.1587V7.11111H24.8889V21.8413Z"
				fill="currentColor"
			/>
		</svg>
	);
};
