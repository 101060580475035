'use client';
import { ILinkProps, Link } from '@/components/core/Link/Link';
import { Tracked } from '@/components/shared/tracking/Tracked';

import { css, cx } from '@/styled-system/css';
import { LinkVariantProps } from '@/styled-system/recipes';
import { ITrackedBlockInformation } from '@/types/tracking';
import { getTrackingHref } from '@/utils/tracking/tracking';
import React, { FC } from 'react';

interface IFooterLink extends LinkVariantProps {
	pageLink: { name?: string; url?: string };
	className?: string;
	domainUrl?: string;
}

export const mapPagePropsToLinkProps = (pageLink: IFooterLink['pageLink']): Omit<ILinkProps, 'color' | 'size'> => {
	const ret = {
		href: pageLink.url,
		title: pageLink.name,
	} as ILinkProps;

	return ret;
};

export const FooterLegalLink: FC<IFooterLink> = ({ pageLink, className, domainUrl, ...rest }) => {
	const trackingRef = React.createRef<HTMLLinkElement>();

	const legalLinkBlockInfo: ITrackedBlockInformation = {
		blockId: 'Footer-legallink',
		blockInstanceId: pageLink?.name || '',
		blockName: 'Footer-legallink',
		blockInstanceName: pageLink?.name || '',
		blockIsRelevant: true,
		blockVersion: '',
	};

	return (
		<Tracked
			trackingElementRef={trackingRef}
			trackedBlockInformation={legalLinkBlockInfo}
			href={getTrackingHref(pageLink?.url || '', domainUrl)}
		>
			<Link
				size="sm"
				color="text.inverted.default"
				role="link"
				className={cx(
					css({
						borderBottomColor: 'transparent',
						textDecoration: 'none',
						_hover: {
							textDecoration: 'underline',
						},
					}),
					className
				)}
				{...mapPagePropsToLinkProps(pageLink)}
				{...rest}
				ref={trackingRef}
			>
				{pageLink.name}
			</Link>
		</Tracked>
	);
};
