/**
 * @packageDocumentation
 * @module localeFormatting_types
 */

/**
 * Enumeration for different types of date formatting that may be defined in the CMS localization Resource manager.
 * The values of the enumeration instances are equivalent to the keys in the translation object, prepended by "format/".
 */
enum DateFormat {
	ShortDate = 'date/short',
	LongDate = 'date/long',
	ShortDateTime = 'datetime/short',
	LongDateTime = 'datetime/long',
}

export { DateFormat };
