'use client';

import { useArticleFilters } from '@/components/block-templates/ArticleFilterBlock/ArticleFilter.hook';
import { areArticleFiltersApplied } from '@/components/block-templates/ArticleFilterBlock/components/ArticleFilterBlock.utils';
import { ArticleFilterDropdown } from '@/components/block-templates/ArticleFilterBlock/components/ArticleFilterDropdown';
import { ArticleFilterHeaderLoader } from '@/components/block-templates/ArticleFilterBlock/components/ArticleFilterSkeletons';
import { ArticleSearchInput } from '@/components/block-templates/ArticleFilterBlock/components/ArticleSearchInput';
import { FilterToggle } from '@/components/shared/filters/FilterToggle';

import useQueryParams from '@/hooks/useQueryParams';

import { FC } from '@/interfaces/blocks/shared';
import { css } from '@/styled-system/css';
import { Flex, Stack } from '@/styled-system/jsx';
import { FilterBarOptions, VisibilitySettings } from '@/types/article-filter';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import { useState } from 'react';

interface IArticleFilterHeaderProps {
	filters: FilterBarOptions;
	translations: TranslationLabelValues;
	visibilitySettings: VisibilitySettings;
}

export const ArticleFilterHeader: FC<IArticleFilterHeaderProps> = ({ filters, visibilitySettings, translations }) => {
	const [isFilterVisible, setIsFilterVisible] = useState(Boolean(!visibilitySettings.ShowSearchInput));

	const { defaults, options, changeFilter } = useArticleFilters(filters);

	const { query } = useQueryParams();

	if (!query || !filters) {
		return <ArticleFilterHeaderLoader />;
	}

	const { topicCategories, investorTypeCategories, years } = filters;

	const dropdownFlex =
		[
			visibilitySettings.ShowCategoryTopics,
			visibilitySettings.ShowCategoryInvestorType,
			visibilitySettings.ShowCategoryYear,
		].filter(Boolean).length - 1 || 0;

	return (
		<Stack
			w="100%"
			alignItems="flex-start"
			justifyContent="flex-start"
			direction={{ base: 'column', md: 'row' }}
			gap={{
				base: 4,
				lg: 8,
			}}
		>
			{visibilitySettings.ShowSearchInput && (
				<Flex
					className={css({
						gap: 4,
						w: {
							base: '100%',
							md: 'auto',
							lg: 'calc((100% - (var(--display-options-spacing-lg) * 2)) / 3)',
						},
					})}
				>
					<ArticleSearchInput
						placeholder={translate(translations, 'article.filter.input.textPlaceholder')}
						defaultValue={query.search}
					/>
					<FilterToggle
						isActive={areArticleFiltersApplied(query)}
						onClick={() => setIsFilterVisible((visible) => !visible)}
					/>
				</Flex>
			)}

			<Flex
				gap={4}
				flex={dropdownFlex}
				w="100%"
				display={{ base: isFilterVisible ? 'flex' : 'none', md: 'flex' }}
				flexDirection={{ base: 'column', md: 'row' }}
				alignItems="flex-start"
				justifyContent="flex-end"
			>
				{topicCategories?.length > 0 && visibilitySettings.ShowCategoryTopics && (
					<ArticleFilterDropdown
						flex="1"
						multiple={true}
						placeholder={translate(translations, 'article.filter.input.topicCategory')}
						selectedOptions={defaults.defaultTopic}
						// topics and investor types are sharing same filter key = "category", they have to be merged
						onChange={(items) => changeFilter('category', [...defaults.defaultInvestorType, ...(items.all || [])])}
						options={options.topicCategoryOptions}
					/>
				)}
				{investorTypeCategories?.length > 0 && visibilitySettings.ShowCategoryInvestorType && (
					<ArticleFilterDropdown
						flex="1"
						multiple={true}
						placeholder={translate(translations, 'article.filter.input.investorTypeCategory')}
						selectedOptions={defaults.defaultInvestorType}
						// topics and investor types are sharing same filter key = "category", they have to be merged
						onChange={(items) => changeFilter('category', [...defaults.defaultTopic, ...(items.all || [])])}
						options={options.investorTypeCategoryOptions}
					/>
				)}
				{years?.length > 0 && visibilitySettings.ShowCategoryYear && (
					<ArticleFilterDropdown
						flex="1"
						multiple={true}
						placeholder={translate(translations, 'article.filter.input.years')}
						selectedOptions={defaults.defaultYears}
						onChange={(items) => changeFilter('year', items.all)}
						options={options.yearsOptions}
					/>
				)}
			</Flex>
		</Stack>
	);
};
