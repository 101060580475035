import { ButtonType } from '@/types/button';

export const getButtonVariant = (buttonType: ButtonType) => {
	switch (buttonType) {
		case ButtonType.PRIMARY: {
			return 'primary';
		}
		case ButtonType.SECONDARY: {
			return 'secondary';
		}
		case ButtonType.TERTIARY: {
			return 'tertiary';
		}
		case ButtonType.TRANSPARENT: {
			return 'transparent';
		}
		case ButtonType.PLAIN: {
			return 'plain';
		}
		case ButtonType.PRIMARY_DANGER: {
			return 'dangerPrimary';
		}
		case ButtonType.SECONDARY_DANGER: {
			return 'dangerSecondary';
		}
	}
};
