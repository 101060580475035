/**
 * @packageDocumentation
 * @module tracking_utils_automated
 */

import {
	EventCategory,
	ITrackedBlockInformation,
	ITrackingHref,
	ITrackingInformation,
	ITrackingTarget,
	defaultConversionIdByEventCategory,
} from '@/types/tracking';
import { automatedTrackingConfig, type IAutomatedTrackingConfigItem } from '@/utils/tracking/automatedTracking.config';
import {
	IUseCaseTrackingConfigItem,
	TrackingUseCase,
	useCaseTrackingConfig,
} from '@/utils/tracking/usecaseTracking.config';

function generatePlaceholderReplacements(
	url: string,
	configItem: IAutomatedTrackingConfigItem | IUseCaseTrackingConfigItem,
	trackingTarget?: ITrackingTarget,
	trackingInformation?: ITrackingInformation,
	trackedBlockInformation?: Partial<ITrackedBlockInformation>
): IPlaceholderDictionary {
	const placeholders: IPlaceholderDictionary = {};

	// url replacements
	placeholders['target-url'] = url ?? '';

	placeholders['target-pagetitle'] = trackingTarget?.targetPageTitle ?? '';
	placeholders['target-contentid'] = trackingTarget?.targetContentId ?? '';
	placeholders['target-file'] = trackingTarget?.targetFile ?? '';

	placeholders['conversion-id'] =
		trackingInformation?.conversionId ?? getDefaultConversionIdByEventCategory(configItem.EventCategory);

	placeholders['document-id'] = trackedBlockInformation?.blockInstanceId ?? '';

	return placeholders;
}

function getDefaultConversionIdByEventCategory(eventCategory?: EventCategory): string {
	if (!eventCategory) {
		return '';
	}

	return defaultConversionIdByEventCategory[eventCategory] ?? '';
}

function generateRegexPlaceholderReplacements(
	configItem: IAutomatedTrackingConfigItem,
	url: string
): IPlaceholderDictionary {
	const placeholders: IPlaceholderDictionary = {};

	// Regular expression replacements
	const regex = configItem.HrefRegex;
	const matches = regex.exec(url);

	if (matches == null) {
		return placeholders;
	}

	for (let i = 0; i < matches.length; ++i) {
		const match = matches[i];

		placeholders[`regex-${i}`] = match;
	}

	return placeholders;
}

function replacePlaceholders(template: string | undefined, placeholders: IPlaceholderDictionary): string | undefined {
	if (template === undefined) {
		return template;
	}
	let replacedString = template;

	for (const key in placeholders) {
		replacedString = replacedString.replace(`{${key}}`, placeholders[key]);
	}

	return replacedString;
}

function findMatchingConfiguration(
	url: string,
	urlTrackingTarget?: ITrackingTarget
): IAutomatedTrackingConfigItem | undefined {
	const foundConfigItem = automatedTrackingConfig.find((configItem) => {
		const hrefRegex = configItem.HrefRegex;

		if (hrefRegex.test(url)) {
			// check whether url target placeholders can be replaced.
			const relevantPropertyValues = configItem.EventLabel + configItem.MediaValue;

			// if there is target-contentid in the config item and not in provided info,
			// then we skip found configuration and look for the next match
			if (relevantPropertyValues.indexOf('{target-contentid}') >= 0 && !urlTrackingTarget?.targetContentId) {
				return false;
			}

			// if there is target-pagetitle in the config item and not in provided info,
			// then we skip found configuration and look for the next match
			if (relevantPropertyValues.indexOf('{target-pagetitle}') >= 0 && !urlTrackingTarget?.targetPageTitle) {
				return false;
			}

			// if there is target-file in the config item and not in provided info,
			// then we skip found configuration and look for the next match
			if (relevantPropertyValues.indexOf('{target-file}') >= 0 && !urlTrackingTarget?.targetFile) {
				return false;
			}

			return true;
		}

		return false;
	});

	return foundConfigItem;
}

/**
 * Matches the url via regex to automatically generate tracking attributes and also fill in information
 * like target url or partial regex matches if needed.
 * @param url the URL to match
 * @param trackingTarget If the link is pointing to an internal URL the trackingTarget is used to describe CMS information of that target
 * @returns Automatically generated Tracking information
 * @example getAutomaticLinkTrackingAttributes("email:something@vontobel.com").category // "link-email"
 * @example getAutomaticLinkTrackingAttributes("email:something@vontobel.com").label // "something@vontobel.com"
 */
const getAutomaticLinkTrackingInformation = (
	href: ITrackingHref,
	trackingTarget?: ITrackingTarget,
	trackingInformation?: ITrackingInformation,
	trackedBlockInformation?: Partial<ITrackedBlockInformation>
): ITrackingInformation => {
	const matchingConfigItem: IAutomatedTrackingConfigItem =
		findMatchingConfiguration(href.originalHref, trackingTarget) ??
		automatedTrackingConfig[automatedTrackingConfig.length - 1];

	const placeholders = generatePlaceholderReplacements(
		href.originalHref,
		matchingConfigItem,
		trackingTarget,
		trackingInformation,
		trackedBlockInformation
	);

	Object.assign(placeholders, generateRegexPlaceholderReplacements(matchingConfigItem, href.originalHref));

	const automaticLinkTrackingInformation: ITrackingInformation = {
		category: matchingConfigItem.EventCategory,
		action: matchingConfigItem.EventAction,
		techCategory: matchingConfigItem.EventTechCategory,
		nonInteraction: false,
		targetUrl: href.absoluteHref || href.originalHref,
		...(matchingConfigItem.EventValue !== undefined && { value: matchingConfigItem.EventValue }),
		...(matchingConfigItem.MediaSource !== undefined && { mediaSource: matchingConfigItem.MediaSource }),
		...(matchingConfigItem.MediaType !== undefined && { mediaType: matchingConfigItem.MediaType }),
	};

	if (matchingConfigItem.MediaValue !== undefined) {
		const mediaValue = replacePlaceholders(matchingConfigItem.MediaValue, placeholders);

		if (mediaValue) {
			automaticLinkTrackingInformation.mediaValue = mediaValue ?? undefined;
		}
	}

	if (matchingConfigItem.ConversionId !== undefined) {
		const conversionId = replacePlaceholders(matchingConfigItem.ConversionId, placeholders);

		if (conversionId) {
			automaticLinkTrackingInformation.conversionId = conversionId ?? undefined;
		}
	}

	if (matchingConfigItem.EventLabel !== undefined) {
		const eventLabel = replacePlaceholders(matchingConfigItem.EventLabel, placeholders);

		if (eventLabel) {
			automaticLinkTrackingInformation.label = eventLabel ?? undefined;
		}
	}

	return automaticLinkTrackingInformation;
};

interface IPlaceholderDictionary {
	[key: string]: string;
}

/**
 * Matches the use case via the usecaseTracking.config and generates tracking information based on that
 * @param useCase the URL to match
 * @param trackingTarget Any link tracked with usecase tracking has to know some information about the tracking target, since it is used in the generation process of the tracking information
 * @returns Automatically generated Tracking information
 * @example getAutomaticLinkTrackingAttributes(TrackingUseCase.Print, {targetContentId: "1", targetPageTitle: "Test title"}).category // "print"
 */
const getUseCaseTrackingInformation = (
	useCase: TrackingUseCase,
	trackingTarget: ITrackingTarget
): ITrackingInformation => {
	const matchingConfigItem: IUseCaseTrackingConfigItem = useCaseTrackingConfig.find((c) => c.UseCase == useCase)!;

	const placeholders = generatePlaceholderReplacements('', matchingConfigItem, trackingTarget);

	const relevantReplacementOptions = matchingConfigItem.EventLabel + matchingConfigItem.MediaValue;

	if (relevantReplacementOptions.indexOf('target-contentid') >= 0 && trackingTarget.targetContentId === undefined) {
		return {};
	}

	if (relevantReplacementOptions.indexOf('target-file') >= 0 && trackingTarget.targetFile === undefined) {
		return {};
	}

	if (relevantReplacementOptions.indexOf('target-pagetitle') >= 0 && trackingTarget.targetPageTitle === undefined) {
		return {};
	}

	const useCaseTrackingInformation: ITrackingInformation = {
		category: matchingConfigItem.EventCategory,
		action: matchingConfigItem.EventAction,
		techCategory: matchingConfigItem.EventTechCategory,
		nonInteraction: false,
		...(matchingConfigItem.EventValue !== undefined && { value: matchingConfigItem.EventValue }),
		...(matchingConfigItem.MediaSource !== undefined && { mediaSource: matchingConfigItem.MediaSource }),
		...(matchingConfigItem.MediaType !== undefined && { mediaType: matchingConfigItem.MediaType }),
	};

	if (matchingConfigItem.MediaValue !== undefined) {
		const mediaValue = replacePlaceholders(matchingConfigItem.MediaValue, placeholders);

		if (mediaValue) {
			useCaseTrackingInformation.mediaValue = mediaValue ?? undefined;
		}
	}

	if (matchingConfigItem.ConversionId !== undefined) {
		const conversionId = replacePlaceholders(matchingConfigItem.ConversionId, placeholders);

		if (conversionId) {
			useCaseTrackingInformation.conversionId = conversionId ?? undefined;
		}
	}

	if (matchingConfigItem.EventLabel !== undefined) {
		const eventLabel = replacePlaceholders(matchingConfigItem.EventLabel, placeholders);

		if (eventLabel) {
			useCaseTrackingInformation.label = eventLabel ?? undefined;
		}
	}

	return useCaseTrackingInformation;
};

export { getAutomaticLinkTrackingInformation, getUseCaseTrackingInformation };
