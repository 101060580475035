import { replaceAllOccurrences, sanitizeString } from '@/utils/string';

const heroTitleHTMLEntitiesWhitelist = [
	{
		html: '&shy;',
		unicode: '\u00AD',
	},
];

export const sanitizeHeroTitle = (title?: string) => {
	if (!title) {
		return title;
	}

	return sanitizeString(
		title,
		heroTitleHTMLEntitiesWhitelist.map((e) => e.html)
	);
};

export const decodeWhitelistedHTMLEntities = (title: string) => {
	if (!title) {
		return title;
	}

	return replaceAllOccurrences(
		title,
		heroTitleHTMLEntitiesWhitelist.map((entity) => ({ targetString: entity.html, replacementString: entity.unicode }))
	);
};
