'use client';

import { ContentSearchCard } from '@/components/block-templates/ContentSearchBlockTemplate/components/ContentSearchCard';
import {
	ContentSearchState,
	ContentSearchStateMessage,
} from '@/components/block-templates/ContentSearchBlockTemplate/components/ContentSearchStateMessage';
import { Button } from '@/components/core/Button/Button';
import { Text } from '@/components/core/Text/Text';
import { MimeType } from '@/enums/MimeType';
import { Box, Flex, FlexProps } from '@/styled-system/jsx';
import { skeleton } from '@/styled-system/recipes';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import { splitLocale } from '@/utils/language';
import { useSearchParams } from 'next/navigation';
import { FC, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';

interface IContentSearchResultsProps extends FlexProps {
	locale: string;
	contentSearchTranslations: TranslationLabelValues;
	timezoneTranslations: TranslationLabelValues;
	searchInstanceId: string;
	numberOfResults: number;
}

export const ContentSearchResults: FC<IContentSearchResultsProps> = ({
	locale,
	contentSearchTranslations,
	timezoneTranslations,
	searchInstanceId,
	numberOfResults,
	...rest
}) => {
	const searchParams = useSearchParams();
	const query = searchParams.get('s');
	const { language } = splitLocale(locale);

	const urlSearchParams = new URLSearchParams({
		q: query || '',
		num: numberOfResults.toString(),
		cx: searchInstanceId,
		lr: `lang_${language}`,
	});

	const {
		data: searchResultsResponse,
		isLoading,
		error,
		size,
		setSize,
	} = useSWRInfinite(
		(pageIndex, previousPageData) => {
			return getKey(pageIndex, previousPageData, urlSearchParams);
		},
		(url: string) =>
			fetch(url).then((res) => {
				if (!res.ok) {
					throw new Error('Failed to fetch');
				}

				return res.json();
			}),
		{ revalidateOnFocus: false, revalidateFirstPage: false, revalidateAll: false }
	);

	const { items, meta, isEmpty, isReachingEnd } = useMemo(
		() => extractSWRInfiniteData(searchResultsResponse),
		[searchResultsResponse]
	);

	const totalResultsString = meta?.totalResults && parseInt(meta?.totalResults, 10) > 100 ? '>100' : meta?.totalResults;

	if (!query) {
		return null;
	}

	if (isEmpty) {
		return (
			<Box>
				<Text mb="8">
					{totalResultsString} {translate(contentSearchTranslations, 'contentSearch.resultsCountLabel')}
				</Text>
				<ContentSearchStateMessage
					state={ContentSearchState.EMPTY}
					title={translate(contentSearchTranslations, 'contentSearch.empty.title')}
					content={translate(contentSearchTranslations, 'contentSearch.empty.text')}
				/>
			</Box>
		);
	}

	if (error) {
		return (
			<ContentSearchStateMessage
				state={ContentSearchState.ERROR}
				title={translate(contentSearchTranslations, 'contentSearch.error.title')}
				content={translate(contentSearchTranslations, 'contentSearch.error.text')}
			/>
		);
	}

	return (
		<Flex flexDir="column" {...rest}>
			{!isLoading && (
				<Text>{`${totalResultsString} ${translate(contentSearchTranslations, 'contentSearch.resultsCountLabel')}`}</Text>
			)}
			<Flex flexDir="column" gap="4" mt="8">
				{isLoading
					? Array.from(Array(numberOfResults).keys()).map((i) => {
							return <Box key={i} height="130px" borderRadius="lg" className={skeleton()} />;
						})
					: items?.map((item, i) => {
							return (
								<ContentSearchCard
									key={`${i}:${item.title}`}
									contentSearchTranslations={contentSearchTranslations}
									timezoneTranslations={timezoneTranslations}
									searchResult={item}
								/>
							);
						})}
			</Flex>
			{!isReachingEnd && (
				<Button variant="secondary" my="5" alignSelf="center" onClick={() => setSize(size + 1)}>
					{translate(contentSearchTranslations, 'contentSearch.loadMore')}
				</Button>
			)}
		</Flex>
	);
};

export interface ISearchResultItem {
	title: string;
	link: string;
	snippet: string;
	mime?: MimeType;
	pagemap?: {
		cse_thumbnail: Array<{ src: string }>;
		metatags?: Array<{
			'article:published_time': string;
		}>;
	};
}

interface ISearchResultsQueries {
	nextPage: Array<{ startIndex: number }>;
}

interface ISearchResultsResponse {
	items: Array<ISearchResultItem>;
	queries: ISearchResultsQueries;
	searchInformation: {
		totalResults: string;
	};
}

const getKey = (
	pageIndex: number,
	previousPageData: ISearchResultsResponse | undefined,
	urlSearchParams: URLSearchParams
) => {
	const searchQuery = urlSearchParams.get('q');

	// Search query is required query parameter
	if (!searchQuery) {
		return null;
	}

	const nextPageIndex = previousPageData?.queries?.nextPage?.[0]?.startIndex;

	if (previousPageData && !nextPageIndex) {
		return null;
	}

	const fields =
		'queries(nextPage/startIndex),searchInformation(totalResults),items(title,link,snippet,mime,pagemap/cse_thumbnail/src,pagemap/metatags/article:published_time)';

	const startIndex = nextPageIndex || pageIndex + 1;
	const searchParams = urlSearchParams.toString();

	return `/api/GoogleCustomSearch?fields=${fields}&start=${startIndex}&${searchParams}`;
};

export function extractSWRInfiniteData(data?: Array<ISearchResultsResponse>) {
	const meta = data?.at(-1)?.searchInformation;
	const isEmpty = meta?.totalResults === '0';
	const isReachingEnd = isEmpty || !data?.[data.length - 1]?.queries?.nextPage;

	const items = data?.flatMap((page) => {
		return page.items;
	});

	return {
		isEmpty,
		isReachingEnd,
		items,
		meta,
	};
}
