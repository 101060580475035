import dynamic from 'next/dynamic';
import { forwardRef } from 'react';

const RadioButtonsComponent = dynamic(() =>
	import('@/components/core/RadioButtons/RadioButtons').then((mod) => mod.RadioButtonsComponent)
);

export const RadioButtons: typeof RadioButtonsComponent = forwardRef((props, ref) => (
	<RadioButtonsComponent ref={ref} {...props} />
));
