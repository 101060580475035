import dynamic from 'next/dynamic';
import { forwardRef } from 'react';

const CheckboxGroupComponent = dynamic(() =>
	import('@/components/core/Checkbox/CheckboxGroup').then((mod) => mod.CheckboxGroupComponent)
);

const CheckboxComponent = dynamic(() =>
	import('@/components/core/Checkbox/Checkbox').then((mod) => mod.CheckboxComponent)
);

export const Checkbox: typeof CheckboxComponent = forwardRef((props, ref) => (
	<CheckboxComponent ref={ref} {...props} />
));

export const CheckboxGroup: typeof CheckboxGroupComponent = forwardRef((props, ref) => (
	<CheckboxGroupComponent ref={ref} {...props} />
));
