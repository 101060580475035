import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const scrollbarFn = /* @__PURE__ */ createRecipe('scrollbar', {}, [])

const scrollbarVariantMap = {
  "variant": [
    "hidden"
  ]
}

const scrollbarVariantKeys = Object.keys(scrollbarVariantMap)

export const scrollbar = /* @__PURE__ */ Object.assign(memo(scrollbarFn.recipeFn), {
  __recipe__: true,
  __name__: 'scrollbar',
  __getCompoundVariantCss__: scrollbarFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: scrollbarVariantKeys,
  variantMap: scrollbarVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, scrollbarVariantKeys)
  },
  getVariantProps: scrollbarFn.getVariantProps,
})