import { publicDomainUrl } from '@/constants/global';
import { CountryCode } from '@/enums/Language';
import { ICultureInstance } from '@/hooks/useCulture';
import { IPageLanguage } from '@/interfaces/coreInformation';
import { ILanguageSettings, checkCultureExistance } from '@/utils/language';
import { LogLevel, logger } from '@/utils/logger';
import { lookupCaseInsensitive } from '@/utils/misc';
import { getAutomaticLinkTrackingInformation } from '@/utils/tracking/automatedTracking';
import { CustomTrackingEvent, doTrack, getTrackingHref } from '@/utils/tracking/tracking';
import { RefObject } from 'react';

export const changeMarketToNewCountry = (
	newCountry: string,
	cultureInstance: ICultureInstance,
	currentLanguage: string,
	currentCountry: string,
	existingLanguages: IPageLanguage[],
	languageSettings: ILanguageSettings,
	trackingRef?: RefObject<HTMLElement>
) => {
	const { changeCulture, resetCulture, createCultureUrl } = cultureInstance;

	logger.group('Culture resolving:', '', LogLevel.DEBUG);
	logger.debug(`Market has changed from: ${currentCountry} to ${newCountry}`);

	if (newCountry.toLowerCase() === CountryCode.GLOBAL) {
		changeMarketToGlobalMarket(currentLanguage, languageSettings, cultureInstance, existingLanguages, trackingRef);

		return;
	}

	if (!isMarketSupportedOnWebsite(newCountry, languageSettings)) {
		// If the selected market does not exist on the website (e.g. CZ), User should be redirected to the according global page

		changeMarketIfCountryNotSupported(newCountry, languageSettings, existingLanguages, trackingRef, cultureInstance);

		return;
	}

	if (!checkCultureExistance(existingLanguages, currentLanguage, newCountry)) {
		logger.debug(`❌ ${newCountry} doesn't supports currently selected language ${currentLanguage}`);
		// if user switched to IT country from current en-CH
		// we need to check if en-IT exists (try to change country with current language)

		// if it doesn't, then take first language from IT available languages
		// but first check if site is supported in IT

		logger.debug(`Checking if country ${newCountry} is supported in any language...`);

		const selectedCountryInAnyLanguage = existingLanguages.find((item) => {
			return item?.name.toLocaleLowerCase().endsWith(newCountry.toLowerCase());
		});

		if (selectedCountryInAnyLanguage) {
			const [language, country] = selectedCountryInAnyLanguage.name.split('-');

			logger.debug(`${newCountry} supports ${language}`);
			logger.groupEnd();

			trackingRef && fireTracking(trackingRef, createCultureUrl(language, country));
			changeCulture(language, country);

			return;
		}

		// if the selected country has NO SUPPORTED LANGUAGE, then we must redirect to homepage
		// redirect culture should be country: selecte country, language: preferred language
		// what is preferred languaged you ask?

		logger.debug(`Checking the preferred language for ${newCountry}...`);

		const preferredLanguage = languageSettings.mappingsJson[newCountry.toUpperCase()];

		trackingRef && fireTracking(trackingRef, `/${preferredLanguage}-${newCountry}`);
		resetCulture(`${preferredLanguage}-${newCountry}`);

		logger.debug(`Preferred language is ${preferredLanguage}!`);
		logger.groupEnd();

		return;
	}

	logger.debug(`✅ ${newCountry} supports currently selected language ${currentLanguage}`);
	logger.groupEnd();

	trackingRef && fireTracking(trackingRef, createCultureUrl(undefined, newCountry || ''));
	changeCulture(undefined, newCountry || '');
};

const changeMarketToGlobalMarket = (
	currentLanguage: string,
	languageSettings: ILanguageSettings,
	cultureInstance: ICultureInstance,
	existingLanguages: IPageLanguage[],
	trackingRef: RefObject<HTMLElement> | undefined
) => {
	const { changeCulture, createCultureUrl, resetCulture } = cultureInstance;

	const availableGlobalLanguages = lookupCaseInsensitive(languageSettings.availableSiteCultures, CountryCode.GLOBAL)!;
	let newLanguage = availableGlobalLanguages[0];

	if (availableGlobalLanguages.includes(currentLanguage)) {
		logger.debug(`${currentLanguage} is supported in global market!`);
		newLanguage = currentLanguage;
	}

	if (checkCultureExistance(existingLanguages, newLanguage)) {
		changeCulture(newLanguage);
		trackingRef && fireTracking(trackingRef, createCultureUrl(currentLanguage));
	} else {
		resetCulture(newLanguage);
	}
};

const isMarketSupportedOnWebsite = (newCountry: string, languageSettings: ILanguageSettings): boolean =>
	!!lookupCaseInsensitive(languageSettings.availableSiteCultures, newCountry);

const changeMarketIfCountryNotSupported = (
	newCountry: string,
	languageSettings: ILanguageSettings,
	existingLanguages: IPageLanguage[],
	trackingRef: RefObject<HTMLElement> | undefined,
	cultureInstance: ICultureInstance
): void => {
	const preferredLanguage = lookupCaseInsensitive(languageSettings.mappingsJson, newCountry) ?? 'en';

	changeMarketToGlobalMarket(preferredLanguage, languageSettings, cultureInstance, existingLanguages, trackingRef);
};

const fireTracking = (trackingRef: RefObject<HTMLElement>, url: string | undefined): void => {
	if (!trackingRef.current) {
		logger.warn('Tracking Event could not be fired in MarketSelect block since no tracking element was found.');

		return;
	}

	if (!url) {
		logger.debug(`Tried firing Tracking event for empty Id on tracking target ${trackingRef.current}`);

		return;
	}

	const event = CustomTrackingEvent.FromTarget(trackingRef.current, 'change');
	const trackingInfo = getAutomaticLinkTrackingInformation(getTrackingHref(url, publicDomainUrl));

	doTrack(event, trackingInfo);
};
