import type { SVGAttributes } from 'react';

export const SearchIcon = (props: SVGAttributes<SVGElement>) => (
	<svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M26.1486 25.2038L19.7638 18.8191C20.9727 17.4121 21.7092 15.5886 21.7092 13.5924C21.7092 9.15811 18.1028 5.55176 13.6686 5.55176C9.23428 5.55176 5.62793 9.15811 5.62793 13.5924C5.62793 18.0267 9.23428 21.633 13.6686 21.633C15.5581 21.633 17.2952 20.9727 18.6667 19.8806L25.0717 26.2857C25.3714 26.5854 25.854 26.5854 26.1486 26.2857C26.4482 25.986 26.4482 25.5035 26.1486 25.2089V25.2038ZM7.15174 13.5873C7.15174 9.9962 10.0724 7.07049 13.6686 7.07049C17.2648 7.07049 20.1854 9.99112 20.1854 13.5873C20.1854 17.1835 17.2648 20.1041 13.6686 20.1041C10.0724 20.1041 7.15174 17.1835 7.15174 13.5873Z"
			fill="currentColor"
		/>
	</svg>
);
