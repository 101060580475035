import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

export interface QueryParams {
	[key: string]: string | Array<string>;
}

function parseQueryString(queryString: string): Record<string, string[]> {
	const params = new URLSearchParams(queryString);
	const result: Record<string, string[]> = {};

	params.forEach((value, key) => {
		result[key] = value.includes(',') ? value.split(',') : [value];
	});

	return result;
}

const useQueryParams = () => {
	const searchParams = useSearchParams();

	const defaultQueryParams: Record<string, string[]> = Array.from(searchParams.entries()).reduce(
		(result, [key, value]) => {
			result[key] = value?.split(',') || [];

			return result;
		},
		{} as { [key: string]: string[] }
	);

	// ****************************

	const [query, setQueryParams] = useState<Record<string, string[]>>({
		...defaultQueryParams,
	});

	const change = (newParams: QueryParams) => {
		// Create a new URLSearchParams object with the updated parameters
		const searchParamString = [];

		for (const key in newParams) {
			const value = newParams[key];

			if (value) {
				if (Array.isArray(value)) {
					searchParamString.push(`${key}=${value.join(',')}`);
				} else {
					searchParamString.push(`${key}=${value}`);
				}
			}
		}

		// Update the URL with the new query parameters
		const newUrl = window.location.origin + window.location.pathname + `?${searchParamString.join('&')}`;

		window.history.pushState({}, '', decodeURI(newUrl));

		setQueryParams({ ...parseQueryString(searchParamString.join('&')) });
	};

	useEffect(() => {
		const handlePopstate = () => {
			const parsedQuery = parseQueryString(window.location.search);

			setQueryParams(parsedQuery);
		};

		window.addEventListener('locationchange', handlePopstate);

		return () => {
			window.removeEventListener('locationchange', handlePopstate);
		};
	}, []);

	return {
		get query() {
			return query;
		},
		get change() {
			return change;
		},
	};
};

export default useQueryParams;
