'use client';

import { HeaderContext } from '@/components/shared/Header/components/HeaderProvider/HeaderProvider';
import { HTMLStyledProps, styled } from '@/styled-system/jsx';
import { FC, useContext } from 'react';

export const HeaderContainer: FC<HTMLStyledProps<'nav'>> = ({ children, ...rest }) => {
	const { headerRef } = useContext(HeaderContext);

	return (
		<styled.nav
			ref={headerRef}
			position="sticky"
			top={0}
			zIndex={{
				sm: 997,
				lg: 1000,
			}}
			background="surface.interactive.transparent.regular.default"
			width="full"
			alignItems="center"
			pr="env(safe-area-inset-right)"
			pl="env(safe-area-inset-left)"
			_print={{
				position: 'relative',
			}}
			{...rest}
		>
			{children}
		</styled.nav>
	);
};
