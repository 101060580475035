import { FC, SVGAttributes } from 'react';

export const AlertIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12 4.23999L3.04761 19.8095H20.9524L12 4.23999ZM12 6.45713L19.0057 18.64H4.99427L12 6.45713Z"
				fill="currentColor"
			/>
			<path d="M12.3352 15.1314L12.9562 10.3009H11.1467L11.7676 15.1314H12.3352Z" fill="currentColor" />
			<path
				d="M12.0533 15.5581C11.5886 15.5581 11.2381 15.9048 11.2381 16.3809C11.2381 16.8571 11.5847 17.2038 12.0533 17.2038C12.5219 17.2038 12.8686 16.8571 12.8686 16.3809C12.8686 15.9048 12.5219 15.5581 12.0533 15.5581Z"
				fill="currentColor"
			/>
		</svg>
	);
};
