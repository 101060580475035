export const possibleVisibilitySettings = [
	'ShowJobFilterBar',
	'ShowSearchInput',
	'ShowDivisionFilter',
	'ShowLocationFilter',
];

export interface VisibilitySettings {
	ShowJobFilterBar?: boolean;
	ShowSearchInput?: boolean;
	ShowDivisionFilter?: boolean;
	ShowLocationFilter?: boolean;
}

export function toIdentifier(name: string) {
	// Remove all symbols
	name = name.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ]/g, ' ');

	// Prune the name
	name = name.trim();

	// Replace all blank space with "_"
	name = name.replace(/\s+/g, '_');

	// Return lowercase name
	return name.toLowerCase();
}
