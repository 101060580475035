import { TagsCloseButton } from '@/components/core/Tags/components/TagsCloseButton';
import { createStyleContext } from '@/libs/create-style-context';
import { tags } from '@/styled-system/recipes';

const { withProvider, withContext } = createStyleContext(tags);

const Root = withProvider('div', 'root');

const Item = withContext('div', 'item');

const Label = withContext('span', 'label');

const CloseButton = withContext(TagsCloseButton, 'closeButton');

export const Tags = { Root, Item, Label, CloseButton };
