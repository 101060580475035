'use client';

import { Heading } from '@/components/core/Heading/Heading';
import { TrackedContainer } from '@/components/shared/TrackedContainer/TrackedContainer';
import { MarketSelect } from '@/components/shared/language/MarketSelect';
import { ITranslatableComponentProps } from '@/interfaces/ITranslatableComponentProps';
import { IPageLanguage } from '@/interfaces/coreInformation';
import { css } from '@/styled-system/css';

import { Flex, FlexProps } from '@/styled-system/jsx';
import { ITrackedBlockInformation } from '@/types/tracking';
import { translate } from '@/utils/i18n/translation-labels/translationLabels';
import { ILanguageSettings } from '@/utils/language';

interface IFooterInternationlSectionProps extends FlexProps, ITranslatableComponentProps {
	forceCulture?: string;
	languageSettings: ILanguageSettings;
	existingLanguages: Array<IPageLanguage>;
}

const FrameworkSelectClassName = css({
	minWidth: '80px',
	width: 'fit-content',
	borderRadius: '48px',
	px: 4,
	py: 3,
	height: '48px',
	backgroundColor: 'transparent',
	borderColor: 'surface.static.primary',
});

const SelectIconClassName = css({
	width: '18px',
	height: '18px',
});

export const FooterInternationalSection = ({
	translations,
	forceCulture,
	existingLanguages,
	languageSettings,
	...rest
}: IFooterInternationlSectionProps) => {
	const trackedBlockInfo: ITrackedBlockInformation = {
		blockId: 'footerInternationalSection',
		blockName: 'footerInternationalSection',
		blockInstanceId: 'footerInternationalSection',
		blockInstanceName: 'footerInternationalSection',
		blockIsRelevant: true,
		blockVersion: '',
	};

	if (!languageSettings?.availableSiteCultures || Object.keys(languageSettings?.availableSiteCultures).length < 2) {
		return null;
	}

	return (
		<Flex flexDirection="column" {...rest}>
			<Heading as="p" type="h4" mb={4}>
				{translate(translations, 'footer.region.caption')}
			</Heading>
			<TrackedContainer trackedBlockInformation={trackedBlockInfo}>
				<MarketSelect
					forceCulture={forceCulture}
					closeOnSelect={false}
					translations={translations}
					existingLanguages={existingLanguages}
					languageSettings={languageSettings}
					className={FrameworkSelectClassName}
					variant="defaultDark"
					iconClassName={SelectIconClassName}
					positioning={{ placement: 'bottom-start' }}
					id="footer__market-select"
				/>
			</TrackedContainer>
		</Flex>
	);
};
