'use client';

import { IPageContentLink } from '@/interfaces/coreInformation';
import { SiteName } from '@/types/feature-flags';
import { ReactNode, createContext, useMemo } from 'react';

interface IPageProviderClientProps {
	contentLink: IPageContentLink;
	siteName: SiteName;
	themeName: string;
	children: ReactNode;
}

export const PageContext = createContext({
	pageLink: undefined as IPageContentLink | undefined,
	siteName: 'corpweb' as SiteName,
	themeName: 'default' as string,
});

export const PageContextProvider: React.FC<IPageProviderClientProps> = ({
	contentLink,
	siteName,
	themeName,
	children,
}) => {
	const contextValue = useMemo(
		() => ({
			pageLink: contentLink,
			siteName: siteName,
			themeName: themeName,
		}),
		[contentLink]
	);

	return <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>;
};
