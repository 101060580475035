export const sanitizeString = (inputString: string, stringsToRemove: Array<string>) => {
	let sanitizedString = inputString;

	for (const stringToRemove of stringsToRemove) {
		const regex = new RegExp(stringToRemove, 'g');

		sanitizedString = sanitizedString.replace(regex, '');
	}

	return sanitizedString;
};

export const replaceAllOccurrences = (
	inputString: string,
	replacingPairs: Array<{ targetString: string; replacementString: string }>
) => {
	let modifiedString = inputString;

	for (const replacingPair of replacingPairs) {
		if (!replacingPair.replacementString || !replacingPair.targetString) {
			continue;
		}

		const regex = new RegExp(replacingPair.targetString, 'g');

		modifiedString = modifiedString.replace(regex, replacingPair.replacementString);
	}

	return modifiedString;
};
