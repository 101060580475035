'use client';

import type { ISelectOptions, ISelectProps } from '@/components/core/Select/Select';
import { Select } from '@/components/core/Select';
import { useCulture } from '@/hooks/useCulture';
import { ITranslatableComponentProps } from '@/interfaces/ITranslatableComponentProps';
import { IPageLanguage } from '@/interfaces/coreInformation';
import { translate } from '@/utils/i18n/translation-labels/translationLabels';
import { ILanguageSettings, extractAvailableLanguages, splitLocale } from '@/utils/language';
import { FC, useMemo } from 'react';
import { SelectValueChangeDetails, createListCollection } from '@ark-ui/react';

interface ILanguageSelectProps extends Omit<ISelectProps, 'collection'>, ITranslatableComponentProps {
	forceCulture?: string;
	hideOnEmpty?: boolean;
	languageSettings: ILanguageSettings;
	existingLanguages: Array<IPageLanguage>;
}

export const LanguageSelect: FC<ILanguageSelectProps> = ({
	forceCulture,
	languageSettings,
	existingLanguages,
	translations,
	hideOnEmpty = false,
	...rest
}) => {
	const { changeCulture, ...restCulture } = useCulture({ existingLanguages, savePreferredLanguage: true });

	const { language, country } = forceCulture ? splitLocale(forceCulture) : restCulture;

	const languageSelectData = useMemo(() => {
		const availableLanguages = extractAvailableLanguages(existingLanguages, country);

		const languageSelectData = createListCollection({
			items:
				languageSettings?.availableSiteCultures?.[country]?.map((availableLanguage: string) => ({
					label: translate(translations, `language.${availableLanguage}`),
					value: availableLanguage,
					disabled: !availableLanguages.includes(availableLanguage),
				})) || [],
		});

		return languageSelectData;
	}, [existingLanguages, language, country]);

	const defaultLanguage = useMemo(() => {
		if (languageSettings?.availableSiteCultures?.[country]?.includes(language)) {
			return languageSelectData.items?.find((lang) => lang.value === language);
		}

		return languageSelectData.items?.[0];
	}, [language, country, languageSelectData]);

	const onLanguageChange = (details: SelectValueChangeDetails<ISelectOptions>) => {
		if (details) {
			// no isCultureExisting needed here because languages are taken from country
			changeCulture(details.value.toString() || '', country);
		}
	};

	if (hideOnEmpty && languageSelectData.size <= 1) {
		return null;
	}

	return (
		<Select
			disabled={languageSelectData.size <= 1}
			onValueChange={onLanguageChange}
			defaultValue={defaultLanguage?.value ? [defaultLanguage.value] : undefined}
			collection={languageSelectData}
			// The language select component may be overlapped by other components (e.g., a backdrop overlay).
			// This can cause unwanted behavior where clicking to select a language triggers the dropdown to close,
			// because the click event propagates up and is interpreted as an "onInteractOutside" event.
			// To prevent this, we use `event.preventDefault()` to stop the click event from bubbling up
			// and causing the dropdown to close without triggering language selection.
			onPointerDown={(event) => {
				event.preventDefault();
			}}
			{...rest}
		/>
	);
};
