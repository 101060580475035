'use client';

import { useRef } from 'react';
import { ITrackedProps, Tracked } from '@/components/shared/tracking/Tracked';
import { IPortfolioSimulatorAppProps, PortfolioSimulatorApp } from '@vontobel/portfolio-simulator';
import styles from '@vontobel/portfolio-simulator/build/raw-styles.js';
import root from 'react-shadow';
// font-face styles have to be imported separately as global styles, because ShadowDOM is not allowed to fetch fonts.
import '@vontobel/portfolio-simulator/build/fonts.css';

export interface IPortfolioSimulatorProps extends Omit<IPortfolioSimulatorAppProps, 'trackingElementRef'> {
	id: string;
	trackedProps: Partial<ITrackedProps>;
}

export const PortfolioSimulator = ({ trackedProps, id, ...appProps }: IPortfolioSimulatorProps) => {
	const trackingElementRef = useRef<HTMLDivElement>(null);

	return (
		<root.div style={{ width: '100%', position: 'relative' }} id={id}>
			{/* In order to make the Portfolio Simulator styles work inside ShadowDOM, we need to inject them inside <style> tag. */}
			<style type="text/css">{styles}</style>
			<Tracked
				trackingElementRef={trackingElementRef}
				trackingInformation={{
					psInteraction: {
						category: 'volt-portfolio-simulator',
						techCategory: 'interactive-element',
						nonInteraction: false,
					},
				}}
				{...trackedProps}
			>
				<PortfolioSimulatorApp trackingElementRef={trackingElementRef} {...appProps} />
			</Tracked>
		</root.div>
	);
};
