import { getLinkTrackingTarget } from '@/components/block-templates/LinkBlockTemplate/LinkBlockTemplate.utils';
import { IButtonLinkProps } from '@/components/core/ButtonLink/ButtonLink';
import { ButtonLinkTracked } from '@/components/core/ButtonLink/ButtonLinkTracked';
import { ILinkProps } from '@/components/core/Link/Link';
import { LinkTracked } from '@/components/core/Link/LinkTracked';
import { getIcon } from '@/const/icon';
import { Icon, IconPosition } from '@/enums/Icon';
import { LinkClickBehaviour } from '@/enums/Link';
import { IBlockComponentProps, IBlockContent, IBlockResponse } from '@/interfaces/coreInformation';
import { ButtonType } from '@/types/button';
import { getButtonVariant } from '@/utils/button/button';
import { logger } from '@/utils/logger';
import { getTrackingPropertiesFromCMSBlock } from '@/utils/tracking/tracking';
import { FC } from 'react';

export interface ILinkBlockTemplateProps extends IBlockContent {
	iconPosition?: IconPosition;
	link?: string;
	icon?: Icon;
	displayText?: string;
	linkClickBehaviour?: string;
	title?: string;
	buttonType?: ButtonType;
	linkTarget?: {
		contentid?: string;
		pagetitle?: string;
		file?: string;
	};
}

export const getLinkBlockProps = (
	blockDefinition: IBlockResponse<ILinkBlockTemplateProps>
): ILinkBlockTemplateProps => {
	return blockDefinition?.contentLink?.expanded;
};

export const mapBlockPropsToLinkProps = (
	linkBlockProps: ILinkBlockTemplateProps
): Omit<ILinkProps, 'color' | 'size'> => {
	const ret = {
		href: linkBlockProps?.link,
		title: linkBlockProps?.title,
	} as ILinkProps;

	if (linkBlockProps?.icon) {
		switch (linkBlockProps.iconPosition) {
			case IconPosition.LEFT:
				ret.leftIcon = getIcon(linkBlockProps.icon);
				break;
			case IconPosition.RIGHT:
				ret.rightIcon = getIcon(linkBlockProps.icon);
				break;
			case IconPosition.LEFT_AND_RIGHT:
				ret.leftIcon = getIcon(linkBlockProps.icon);
				ret.rightIcon = getIcon(linkBlockProps.icon);
				break;
		}
	}

	if (linkBlockProps?.linkClickBehaviour) {
		switch (linkBlockProps.linkClickBehaviour) {
			case LinkClickBehaviour.NEW_WINDOW:
				ret.target = '_blank';
				ret.rel = 'noreferrer';
				break;
			case LinkClickBehaviour.SAME_WINDOW:
				ret.target = '_self';
				break;
		}
	}

	return ret;
};

interface ITrackedLinkBlock {
	preventAutomatedTracking?: boolean;
	useParentTrackingCMStrackingData?: boolean;
}

export interface IButtonLinkBlockComponentProps
	extends Omit<IBlockComponentProps<ILinkBlockTemplateProps>, 'pageData' | 'id'>,
		Partial<IButtonLinkProps>,
		ITrackedLinkBlock {}

export interface ILinkBlockComponentProps
	extends Omit<IBlockComponentProps<ILinkBlockTemplateProps>, 'pageData' | 'id'>,
		Partial<ILinkProps>,
		ITrackedLinkBlock {}

type ILinkTemplateProps = ILinkBlockComponentProps | IButtonLinkBlockComponentProps;

export const LinkTemplate: FC<ILinkTemplateProps> = ({ ...props }) => {
	const isButtonType = Boolean(props.blockDefinition?.contentLink?.expanded?.buttonType);

	return isButtonType ? (
		<ButtonLinkBlockTemplate {...(props as IButtonLinkBlockComponentProps)} />
	) : (
		<LinkBlockTemplate {...(props as ILinkBlockComponentProps)} />
	);
};

const ButtonLinkBlockTemplate: FC<IButtonLinkBlockComponentProps> = ({
	blockDefinition,
	preventAutomatedTracking,
	useParentTrackingCMStrackingData,
	id,
	...props
}) => {
	const linkBlockProps = getLinkBlockProps(blockDefinition);
	const linkProps = {
		...mapBlockPropsToLinkProps(linkBlockProps),
		...props,
	};

	const trackingData = !useParentTrackingCMStrackingData
		? getTrackingPropertiesFromCMSBlock(blockDefinition.contentLink?.expanded)
		: {};

	return (
		<ButtonLinkTracked
			id={id}
			trackingData={trackingData}
			trackingTarget={getLinkTrackingTarget(blockDefinition.contentLink?.expanded)}
			preventAutomatedTracking={preventAutomatedTracking}
			variant={getButtonVariant(linkBlockProps.buttonType!)}
			mode={props.mode}
			size="large"
			{...linkProps}
		>
			{linkBlockProps.displayText}
		</ButtonLinkTracked>
	);
};

const LinkBlockTemplate: FC<ILinkBlockComponentProps> = ({
	blockDefinition,
	preventAutomatedTracking,
	useParentTrackingCMStrackingData,
	id,
	...props
}) => {
	const linkBlockProps = getLinkBlockProps(blockDefinition);
	const linkProps = {
		...mapBlockPropsToLinkProps(linkBlockProps),
		...props,
	};

	if (!linkBlockProps?.displayText) {
		logger.warn(`LinkBlock ${linkBlockProps?.contentLink?.id}: displayText is required`);

		return null;
	}

	const trackingData = !useParentTrackingCMStrackingData
		? getTrackingPropertiesFromCMSBlock(blockDefinition.contentLink?.expanded)
		: {};

	return (
		<LinkTracked
			id={id}
			trackingData={trackingData}
			trackingTarget={getLinkTrackingTarget(blockDefinition.contentLink?.expanded)}
			preventAutomatedTracking={preventAutomatedTracking}
			{...linkProps}
		>
			{linkBlockProps.displayText}
		</LinkTracked>
	);
};
