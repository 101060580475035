import { FC, SVGAttributes } from 'react';

export const DocumentIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M25.3968 4.06349H6.60317V27.9365H25.3968V4.06349ZM8.12698 26.4127V5.5873H23.873V26.4127H8.12698Z"
				fill="currentColor"
			/>
			<path
				d="M19.8095 14.7302H10.6667C10.2451 14.7302 9.90476 15.0705 9.90476 15.4921C9.90476 15.9136 10.2451 16.254 10.6667 16.254H19.8095C20.2311 16.254 20.5714 15.9136 20.5714 15.4921C20.5714 15.0705 20.2311 14.7302 19.8095 14.7302Z"
				fill="currentColor"
			/>
			<path
				d="M15.2381 17.7778H10.6667C10.2451 17.7778 9.90476 18.1181 9.90476 18.5397C9.90476 18.9613 10.2451 19.3016 10.6667 19.3016H15.2381C15.6597 19.3016 16 18.9613 16 18.5397C16 18.1181 15.6597 17.7778 15.2381 17.7778Z"
				fill="currentColor"
			/>
			<path
				d="M19.8095 11.6825H10.6667C10.2451 11.6825 9.90476 12.0229 9.90476 12.4444C9.90476 12.866 10.2451 13.2064 10.6667 13.2064H19.8095C20.2311 13.2064 20.5714 12.866 20.5714 12.4444C20.5714 12.0229 20.2311 11.6825 19.8095 11.6825Z"
				fill="currentColor"
			/>
		</svg>
	);
};
