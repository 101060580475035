/**
 * @packageDocumentation
 * @module localeFormatting_utils
 */

import { DateFormat } from '@/types/localeFormatting';
import {
	TranslationLabelValues,
	getTranslationInstance,
	translate,
} from '@/utils/i18n/translation-labels/translationLabels';
import { initializeDayJS } from '@/utils/i18n/dayjs/dayjs.config';
import dayjs from 'dayjs';

const FORMATTING_TRANSLATION_PREFIX = 'format/';
const FORMATTING_LABEL_TEXT_QUOTES = FORMATTING_TRANSLATION_PREFIX + 'text/quotes';
const TEXT_REPLACEMENT_PLACEHOLDER = '{{text}}';
const FORMATTING_LABEL_NUMBER = FORMATTING_TRANSLATION_PREFIX + 'number';

/**
 * Formats the date according to the specification received from the translation label.
 * @param date The Date to format.
 * @param locale Contains language and country information, e.g. "de-ch"
 * @param dateFormat Which date format to use. See the documentation of DateFormat.
 */
const formatDate = async (
	date: Date,
	locale: string,
	dateFormat: DateFormat = DateFormat.ShortDate
): Promise<string> => {
	initializeDayJS();

	const { t } = await getTranslationInstance(locale);
	const format = t(FORMATTING_TRANSLATION_PREFIX + dateFormat);

	return dayjs(date).locale(locale).format(format);
};

const formatRelativeDate = (date: Date | string, locale: string): string => {
	const loc = locale.split('-')[0] === 'en' ? 'en' : locale;
	const formatter = new Intl.RelativeTimeFormat(loc, { style: 'short' });

	const dateObj = new Date(date);
	const now = new Date();

	const diff = dateObj.getTime() - now.getTime();
	const days = Math.floor(diff / (1000 * 60 * 60 * 24));

	return formatter.format(days, 'day');
};

/**
 * Adds beginning and end quotes around the provided text.
 * @param text The Text to inject in the quotes.
 * @param locale Contains language and country information, e.g. "de-ch"
 */
const formatQuote = async (text: string, locale: string): Promise<string> => {
	const { t } = await getTranslationInstance(locale);
	const format = t(FORMATTING_LABEL_TEXT_QUOTES);

	return format.replace(TEXT_REPLACEMENT_PLACEHOLDER, text);
};

/**
 * Gets the character for the beginning quote.
 * @param locale Contains language and country information, e.g. "de-ch"
 */
const getBeginQuote = async (locale: string): Promise<string> => {
	const { t } = await getTranslationInstance(locale);
	const format = t(FORMATTING_LABEL_TEXT_QUOTES);
	const beginQuoteIndexRange = format.indexOf(TEXT_REPLACEMENT_PLACEHOLDER);

	return format.substring(0, beginQuoteIndexRange);
};

/**
 * Gets the character for the ending quote.
 * @param locale Contains language and country information, e.g. "de-ch"
 */
const getEndQuote = async (locale: string): Promise<string> => {
	const { t } = await getTranslationInstance(locale);
	const format = t(FORMATTING_LABEL_TEXT_QUOTES);
	const endQuoteIndex = format.indexOf(TEXT_REPLACEMENT_PLACEHOLDER) + TEXT_REPLACEMENT_PLACEHOLDER.length;

	return format.substring(endQuoteIndex);
};

/**
 * Formats a number according to the specification received from the translation label.
 * @param number The number to be formatted
 * @param locale Contains language and country information, e.g. "de-ch"
 */
const formatNumber = async (number: number, locale: string): Promise<string> => {
	const { t } = await getTranslationInstance(locale);
	const format = t(FORMATTING_LABEL_NUMBER);

	// Get Separators
	// The placeholders for digits '#' and for decimals '0' are hardcoded because of regex readability
	// so if a change is necessary, every occurrence of the '#' or '0' has to be changed respectively
	const thousandSeparator = format.match(/#([^#])#/i)?.at(1) ?? '';
	const decimalSeparator = format.match(/#([^#0])0/i)?.at(1) ?? '';

	// Cut off decimal places
	const decimalPlaces = format.match(/0+/i)?.at(0)?.length ?? 0;
	const numberString = number.toFixed(decimalPlaces);

	// Split parts of number
	const splittedNumber = numberString.split('.');
	let wholePart = splittedNumber[0];
	const decimalPart = splittedNumber.length > 1 ? splittedNumber[1] : '';

	// Add thousand separator
	wholePart = wholePart.replace(/\B(?<!\.[0-9]*)(?=([0-9]{3})+(?![0-9]))/g, thousandSeparator);

	// Add decimal separator
	return wholePart + decimalSeparator + decimalPart;
};

/**
 * Formats the publication date to the strict timezone Europe/Zurich.
 */
const formatPublicationDateToEuZurich = (
	date?: Date,
	prefix?: string,
	includeAbbreviation?: boolean,
	timezoneTranslations?: TranslationLabelValues,
	format = 'DD.MM.YYYY'
) => {
	if (!date) {
		return '';
	}

	initializeDayJS();

	const zurichTimezoneDate = dayjs(date).tz('Europe/Zurich');
	let formattedDate = zurichTimezoneDate.format(format);

	if (prefix) {
		formattedDate = `${prefix} ${formattedDate}`;
	}

	if (includeAbbreviation && timezoneTranslations) {
		const isZurichDST = zurichTimezoneDate.utcOffset() === 120;

		const suffix = isZurichDST
			? translate(timezoneTranslations, 'timezone.abbreviations.zurich.cest')
			: translate(timezoneTranslations, 'timezone.abbreviations.zurich.cet');

		formattedDate = `${formattedDate} ${suffix}`;
	}

	return formattedDate;
};

export {
	formatDate,
	formatRelativeDate,
	formatQuote,
	getBeginQuote,
	getEndQuote,
	formatNumber,
	formatPublicationDateToEuZurich,
};
