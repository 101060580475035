import type { SVGAttributes } from 'react';

export const FilterIcon = (props: SVGAttributes<SVGElement>) => (
	<svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M18.0368 12.2311c.9819 0 1.7778-.7959 1.7778-1.7778 0-.9818-.7959-1.7777-1.7778-1.7777-.9818 0-1.7778.7959-1.7778 1.7777 0 .9819.796 1.7778 1.7778 1.7778Z"
			fill="transparent"
		/>
		<path
			d="M9.9098 17.8184c.9819 0 1.7778-.7959 1.7778-1.7778 0-.9818-.7959-1.7777-1.7778-1.7777-.9818 0-1.7777.7959-1.7777 1.7777 0 .9819.7959 1.7778 1.7777 1.7778Z"
			fill="transparent"
		/>
		<path
			d="M22.0902 23.3244c.9818 0 1.7777-.7959 1.7777-1.7777 0-.9819-.7959-1.7778-1.7777-1.7778-.9819 0-1.7778.7959-1.7778 1.7778 0 .9818.7959 1.7777 1.7778 1.7777Z"
			fill="transparent"
		/>
		<path
			d="M4.8254 11.1746h9.986c.3302 1.4781 1.6457 2.5803 3.2203 2.5803s2.8902-1.1073 3.2204-2.5803h5.9174a.7609.7609 0 0 0 .7619-.7619.7609.7609 0 0 0-.7619-.762h-5.9428c-.3607-1.4323-1.6559-2.499-3.2-2.499-1.5442 0-2.8394 1.0616-3.2 2.499H4.8254a.7609.7609 0 0 0-.762.762c0 .4216.3404.7619.762.7619Zm13.2114-2.499c.9803 0 1.7778.7974 1.7778 1.7777 0 .9804-.7975 1.7778-1.7778 1.7778-.9803 0-1.7778-.7974-1.7778-1.7778 0-.9803.7975-1.7777 1.7778-1.7777Z"
			fill="currentColor"
		/>
		<path
			d="M27.1746 15.2381H13.1048c-.3607-1.4324-1.6559-2.4991-3.2-2.4991-1.5442 0-2.8394 1.0616-3.2 2.4991H4.8203A.7609.7609 0 0 0 4.0584 16c0 .4216.3403.7619.762.7619h1.859c.3301 1.4781 1.6457 2.5803 3.2203 2.5803s2.8901-1.1073 3.2203-2.5803h14.0444A.7609.7609 0 0 0 27.9263 16a.7609.7609 0 0 0-.7619-.7619h.0102ZM9.9098 17.8184c-.9803 0-1.7777-.7974-1.7777-1.7778 0-.9803.7974-1.7777 1.7777-1.7777.9804 0 1.7778.7974 1.7778 1.7777 0 .9804-.7974 1.7778-1.7778 1.7778Z"
			fill="currentColor"
		/>
		<path
			d="M25.3156 20.8254c-.3302-1.4781-1.6458-2.5803-3.2204-2.5803-1.5746 0-2.8901 1.1073-3.2203 2.5803H4.8254a.7609.7609 0 0 0-.762.7619c0 .4216.3404.7619.762.7619h14.0698c.3607 1.4324 1.6559 2.4991 3.2 2.4991 1.5442 0 2.8394-1.0616 3.2-2.4991h1.8845a.7609.7609 0 0 0 .7619-.7619.7609.7609 0 0 0-.7619-.7619h-1.8641Zm-3.2254 2.499c-.9804 0-1.7778-.7974-1.7778-1.7777 0-.9804.7974-1.7778 1.7778-1.7778.9803 0 1.7777.7974 1.7777 1.7778 0 .9803-.7974 1.7777-1.7777 1.7777Z"
			fill="currentColor"
		/>
	</svg>
);
