'use client';

import { ITrackedContainerProps } from '@/components/shared/TrackedContainer/TrackedContainer';
import { Tracked } from '@/components/shared/tracking/Tracked';
import { IPageData } from '@/interfaces/coreInformation';
import { EventAttachMode, ITrackedBlockInformation, ITrackingInformation, ITrackingTarget } from '@/types/tracking';
import { getAutomaticLinkTrackingInformation } from '@/utils/tracking/automatedTracking';
import { doTrack, getTrackingHref } from '@/utils/tracking/tracking';
import { FC, useEffect } from 'react';

interface ITextSnippetTrackedProps extends ITrackedContainerProps {
	textBlockId: string | number | undefined;
	pageData: IPageData;
	children: React.ReactNode;
}

export const TextSnippetTracked: FC<ITextSnippetTrackedProps> = ({
	textBlockId,
	pageData,
	children,
	cmsTrackingInformation,
	trackingInformation,
	trackedBlockInformation,
	trackingUseCase,
}) => {
	const trackingSelector = `[data-id="${textBlockId}"] a`;

	const bindTracking = (anchors: Array<HTMLAnchorElement>) => {
		anchors.forEach((anchor) => {
			if (anchor.dataset.bound) {
				return;
			}

			anchor.dataset.bound = 'true';

			let trackingTarget: ITrackingTarget | undefined;
			let trackingInformation: ITrackingInformation | undefined;
			let trackedBlockInformation: Partial<ITrackedBlockInformation> | undefined;

			const {
				trackingConversionid: conversionId,
				trackingPageid: trackingContentId,
				trackingPagename: trackingPageTitle,
				trackingDocumentid: blockInstanceId,
			} = anchor.dataset;

			if (trackingContentId && trackingPageTitle) {
				trackingTarget = {
					targetContentId: trackingContentId,
					targetPageTitle: trackingPageTitle,
				};
			}

			if (conversionId) {
				trackingInformation = {
					conversionId,
				};
			}

			if (blockInstanceId) {
				trackedBlockInformation = {
					blockInstanceId,
				};
			}

			const trackingInfo = getAutomaticLinkTrackingInformation(
				getTrackingHref(anchor.href, pageData.domainUrl),
				trackingTarget,
				trackingInformation,
				trackedBlockInformation
			);

			anchor.addEventListener('click', (event: Event) => {
				if (event.target) {
					doTrack(event as Event & { target: HTMLAnchorElement }, trackingInfo);
				}
			});
		});
	};

	useEffect(() => {
		const links = window.document.querySelectorAll(trackingSelector);

		bindTracking(Array.from(links) as HTMLAnchorElement[]);
	}, []);

	return (
		<Tracked
			cmsTrackingInformation={cmsTrackingInformation}
			trackingInformation={trackingInformation}
			trackedBlockInformation={trackedBlockInformation}
			trackingUseCase={trackingUseCase}
			trackingSelector={trackingSelector}
			attachTrackingEvent={EventAttachMode.Custom}
		>
			{children}
		</Tracked>
	);
};
