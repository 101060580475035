export enum LogLevel {
	CRITICAL = 1,
	ERROR = 2,
	WARN = 3,
	INFO = 4,
	PERFORMANCE = 5,
	DEBUG = 6,
}

const logLevel = LogLevel[process.env.NEXT_PUBLIC_LOG_LEVEL || 'WARN'];

type Timer = {
	start: number;
	id: string;
};

export function startTimer(id: string): Timer {
	return {
		start: performance.now(),
		id,
	};
}

export function endTimer(timer: Timer) {
	if (logLevel >= LogLevel.PERFORMANCE) {
		console.debug(
			buildLog(
				`${timer.id} executed in ${((performance.now() - timer.start) / 1000).toFixed(5)} seconds`,
				'PERFORMANCE',
				timer.id
			)
		);
	}
}

function buildLog(message: string, logLevel: string, id?: string) {
	return `${logLevel} ${id ? ` [${id}] ` : ''} - ${message}`;
}

export const logger = {
	log: (message: string, id = '', level: LogLevel = LogLevel.INFO) => {
		if (level >= logLevel) {
			console.log(buildLog(message, LogLevel[level], id));
		}
	},
	// Use sparingly, moslty on the client, as async logging messes up indentation
	group: (message: string, id = '', level: LogLevel = LogLevel.INFO) => {
		if (level >= logLevel) {
			console.group(buildLog(message, LogLevel[level], id));
		}
	},
	groupEnd: () => {
		console.groupEnd();
	},
	error: (message: string, id?: string) => {
		if (logLevel >= LogLevel.ERROR) {
			console.warn(buildLog(message, 'ERROR', id));
		}
	},
	warn: (message: string, id?: string) => {
		if (logLevel >= LogLevel.WARN) {
			console.warn(buildLog(message, 'WARN', id));
		}
	},
	debug: (message: string, id?: string) => {
		if (logLevel >= LogLevel.DEBUG) {
			console.debug(buildLog(message, 'DEBUG', id));
		}
	},
	info: (message: string, id?: string) => {
		if (logLevel >= LogLevel.INFO) {
			console.debug(buildLog(message, 'INFO', id));
		}
	},
	critical: (message: string, id?: string) => {
		if (logLevel >= LogLevel.ERROR) {
			console.warn(buildLog(message, 'CRITICAL', id));
		}
	},
};
