import { IFormContainerBlockContent } from '@/components/block-templates/Forms/FormContainerBlockTemplate/FormContainerBlockTemplate';
import { getClientData } from '@/components/shared/Forms/HiddenFormField';
import { IFormFieldResponse, ISelectFormFieldContent } from '@/interfaces/blocks/forms';
import { UtagType } from 'typings/common';

const ORDER_TO_FORM_FIELD_MAPPING = [
	'salutationInput',
	'firstNameInput',
	'lastNameInput',
	'jsonField',
	'mailInput',
	'resultJsonField',
	'resultMailField',
	'newsletters',
	'pageUrl',
	'clientData',
] as const;

type WealthCheckInputKeyType = (typeof ORDER_TO_FORM_FIELD_MAPPING)[number];

export function getSalesforceConfiguration(formContainer: IFormContainerBlockContent, pageId: number, utag?: UtagType) {
	const wealthCheckBlock = formContainer?.formSalesforce?.at(0)?.contentLink?.expanded;

	const wealthCheckInputs: {
		[property in WealthCheckInputKeyType]?: IFormFieldResponse;
	} = {};

	for (let i = 0; i < ORDER_TO_FORM_FIELD_MAPPING.length; ++i) {
		wealthCheckInputs[ORDER_TO_FORM_FIELD_MAPPING[i]] = wealthCheckBlock?.formFields?.at(i);
	}

	if (wealthCheckInputs) {
		return {
			pageId,
			formContainerId: formContainer?.contentLink?.id ?? 0,
			fields: {
				firstname: {
					fieldId: wealthCheckInputs.firstNameInput?.contentLink?.id ?? 0,
				},
				lastname: {
					fieldId: wealthCheckInputs.lastNameInput?.contentLink?.id ?? 0,
				},
				json: {
					fieldId: wealthCheckInputs.jsonField?.contentLink?.id ?? 0,
				},
				mail: {
					fieldId: wealthCheckInputs.mailInput?.contentLink?.id ?? 0,
				},
				newsletters: getNewsletterConfig(
					wealthCheckInputs.newsletters?.contentLink?.expanded as ISelectFormFieldContent
				),
				resultJson: {
					fieldId: wealthCheckInputs.jsonField?.contentLink?.id ?? 0,
				},
				salutation: getSalutationConfig(
					wealthCheckInputs?.salutationInput?.contentLink?.expanded as ISelectFormFieldContent
				),
				resultMail: {
					fieldId: wealthCheckInputs.resultMailField?.contentLink?.id ?? 0,
				},
				pageUrl: {
					fieldId: wealthCheckInputs.pageUrl?.contentLink?.id ?? 0,
					value: typeof window !== 'undefined' ? window.location.href : undefined,
				},
				clientData: {
					fieldId: wealthCheckInputs.clientData?.contentLink?.id ?? 0,
					value: utag ? getClientData(utag) : undefined,
				},
			},
		};
	}
}

function getSalutationConfig(formField: ISelectFormFieldContent | undefined) {
	const salutationItems = formField?.selectionItems;

	return {
		fieldId: formField?.contentLink?.id ?? 0,
		values: {
			mr: salutationItems?.at(0)?.contentLink?.id ?? 0,
			ms: salutationItems?.at(1)?.contentLink?.id ?? 0,
		},
	};
}

function getNewsletterConfig(formField: ISelectFormFieldContent | undefined) {
	const newsletterItems = formField?.selectionItems;

	return {
		fieldId: formField?.contentLink?.id ?? 0,
		values: {
			newsletter: newsletterItems?.at(0)?.contentLink?.id ?? 0,
			wants_consultation: newsletterItems?.at(1)?.contentLink?.id ?? 0,
			result_mail: newsletterItems?.at(2)?.contentLink?.id ?? 0,
		},
	};
}
