import { FC, SVGAttributes } from 'react';

export const LoginIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M17.4628 14.9181L17.4526 14.9283L13.8716 11.3473L12.7948 12.4241L15.5986 15.2279H4.08371V16.7619H15.6189L12.805 19.5759L13.8818 20.6527L18.5396 15.9949L17.4628 14.9181Z"
				fill="currentColor"
			/>
			<path
				d="M10.6666 21.8413V26.4127H26.4127V5.5873H10.6666V10.1587H9.14282V4.06349H27.9365V27.9365H9.14282V21.8413H10.6666Z"
				fill="currentColor"
			/>
		</svg>
	);
};
