import { Image, ImageProps } from '@/components/core/Image/Image';
import { ICloudinaryEnvironmentVariables } from '@/utils/cloudinary';

interface ITextImageTeaserImageProps extends ImageProps {
	displayOption?: string;
	cloudinaryOverrides?: ICloudinaryEnvironmentVariables;
}

const generateTeaserImageSizes = (displayOption?: string) => {
	if (!displayOption?.length) {
		return '(max-width: 768px) 100vw, (max-width: 1024px) 485px, 615px';
	}

	switch (displayOption) {
		case 'quarterWidth': {
			return '(max-width: 768px) 100vw, (max-width: 1024px) 225px, 285px';
		}
		case 'thirdWidth': {
			return '(max-width: 768px) 100vw, (max-width: 1024px) 305px, 385px';
		}
		case 'halfWidth': {
			return '(max-width: 768px) 100vw, (max-width: 1024px) 480px, 600px';
		}
		case 'fullWidth': {
			return '(max-width: 768px) 100vw, (max-width: 1024px) 485px, 615px';
		}
		default: {
			return '(max-width: 768px) 100vw, (max-width: 1024px) 485px, 615px';
		}
	}
};

export const TeaserImage = ({ src, displayOption, alt, cloudinaryOverrides, ...rest }: ITextImageTeaserImageProps) => {
	const sizes = generateTeaserImageSizes(displayOption);

	return <Image src={src} alt={alt} sizes={sizes} cloudinaryOverrides={cloudinaryOverrides} {...rest} />;
};
