import { RecipeVariant, cva } from '@/styled-system/css';

/**
 * Text snippet styles
 *
 * Table styles need "!important" to override the default styles
 *
 * "Text styles" can be found in CMS, some of them can only be applied to paragraphs ("Standard text", "Image legend"),
 * while others can be applied to any text element ("Small text", "Quote").
 *
 * "Link styles" can be found in CMS, they can only be applied to anchor elements.
 *
 * "Custom classes" are custom classes that can be applied to any element, this can only be done manually in the CMS
 * by editing TinyMCE's HTML source code.
 * https://vontobel.atlassian.net/wiki/spaces/VTREVMP/pages/4076634127/Custom+RichText+classes
 *
 * @param {string} parentBackgroundColor - The background color of the parent element
 * @returns {Object} The styled-system css definition
 */
export const textSnippet = cva({
	base: {
		width: 'full',
		maxW: 'full',
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
		gap: 4,
		color: 'text.regular.default',

		'& strong': {
			fontWeight: 'bold',
		},

		'& h2': {
			fontSize: '2rem',
			lineHeight: '120%',
			letterSpacing: '-0.06rem',
			fontWeight: 'bold',
		},

		'& h3': {
			fontSize: '1.75rem',
			lineHeight: '120%',
			letterSpacing: '0rem',
			fontWeight: 'bold',
		},

		'& h4': {
			fontSize: '1.5rem',
			lineHeight: '125%',
			letterSpacing: '0rem',
			fontWeight: 'bold',
		},

		'& h5': {
			fontSize: '1.25rem',
			lineHeight: '130%',
			letterSpacing: '0rem',
			fontWeight: 'bold',
		},

		'& h6': {
			fontSize: '1.125rem',
			lineHeight: '130%',
			letterSpacing: '-0.01rem',
			fontWeight: 'semibold',
		},

		// Apply styles only to nested headings (inside any other tag within TextSnippet),
		// spacing for direct children is handled by the component's root flex gap.
		'& * h2, & * h3, & * h4, & * h5, & * h6': {
			mb: 4,
		},

		'& p': {
			fontSize: '1.125rem',
			lineHeight: '142%',
			letterSpacing: '-0.02rem',
		},

		'& ul, ol': {
			display: 'flex',
			flexDirection: 'column',
			flexWrap: 'wrap',
			listStylePosition: 'outside',
			gap: 4,
			fontSize: '1.125rem',
			lineHeight: '142%',
			letterSpacing: '-0.02rem',
		},

		'& ul': {
			marginLeft: '25px',
		},

		'& ul li': {
			position: 'relative',
			_before: {
				content: '""',
				backgroundColor: 'text.regular.default',
				width: '9px',
				height: '1px',
				position: 'absolute',
				left: '-25px',
				// Position the dash in the middle of the first line. Line height is 1.42em, so half is 0.71em.
				top: 'calc((1em * 0.71) + 1px)',
			},
		},

		// Add spacing for nested lists
		'& ul li ol, ul li ul,ol li ol, ol li ul': {
			marginTop: 4,
		},

		'& ol': {
			listStyleType: 'none',
			counterReset: 'priority',
		},

		'& ol li': {
			position: 'relative',
			paddingLeft: '28px',
			_before: {
				content: 'counter(priority) ". "',
				counterIncrement: 'priority',
				position: 'absolute',
				right: 'calc(100% - 12px)',
			},
		},

		'& table': {
			border: 'none !important',
			display: 'block',
			maxWidth: '100%',
			overflow: 'auto',
			backgroundColor: 'inherit',

			'& tr': {
				_hover: {
					backgroundColor: 'surface.interactive.transparent.regular.hover',
				},
			},
		},

		'& table thead': {
			border: 'none !important',
		},

		'& table tbody': {
			backgroundColor: 'inherit',
			border: 'none !important',
		},

		'& table tr': {
			backgroundColor: 'inherit',
			border: 'none !important',
		},

		'& table tr td, table tr th': {
			border: ['none !important', 'none'],
			paddingBlock: 4,
			paddingInline: 2,
			minWidth: '125px',
			borderWidth: '0px !important',
		},

		'& table tr:not(:last-child) td': {
			borderBottom: '1px solid !important',
			borderBottomColor: 'other.border.regular.default !important',
		},

		'& em': {
			fontStyle: 'italic',
		},

		'@media (min-width: 769px) and (max-width: 1024px)': {
			'& table': {
				display: 'table',
			},
			'& h2': {
				fontSize: '2.5rem',
			},
			'& h3': {
				fontSize: '2.25rem',
			},
			'& h4': {
				fontSize: '1.75rem',
			},
			'& h5': {
				fontSize: '1.5rem',
			},
			'& h6': {
				fontSize: '1.25rem',
			},
			'& .quote-text': {
				fontSize: '1.375rem',
			},
		},

		'@media (min-width: 1024px)': {
			'& table': {
				display: 'table',
			},
			'& h2': {
				fontSize: '3rem',
			},
			'& h3': {
				fontSize: '2.5rem',
			},
			'& h4': {
				fontSize: '2rem',
			},
			'& h5': {
				fontSize: '1.5rem',
			},
			'& h6': {
				fontSize: '1.125rem',
			},
			'& .quote-text': {
				fontSize: '1.5rem',
			},
		},

		'& a': {
			color: 'actions.links.regular.default',
			borderBottom: '1px solid',
			borderBottomColor: 'actions.links.regular.default',

			_hover: {
				color: 'actions.links.regular.hoverAndPressed',
				borderBottomColor: 'actions.links.regular.hoverAndPressed',
			},

			_active: {
				color: 'actions.links.regular.hoverAndPressed',
				borderBottomColor: 'actions.links.regular.hoverAndPressed',
			},
		},

		'& hr': {
			border: '1px solid',
			borderColor: 'other.border.regular.default',
		},

		// Text style - "Small text"
		'& :not(:is(h1, h2, h3, h4, h5, h6)).small-text': {
			fontSize: '0.875rem',
			lineHeight: '146%',
		},

		// Text style - "Quote"
		'& .quote-text': {
			fontSize: '1.25rem',
			fontStyle: 'italic',
			fontWeight: 'regular',
			lineHeight: '142%',
		},

		// Text style - "Image legend"
		'& .image-legend': {
			fontSize: '0.75rem',
			lineHeight: '130%',
			letterSpacing: '0.015rem',
		},

		// Link style - "PDF Icon"
		'& a.pdf-icon': {
			position: 'relative',
			marginLeft: '1.5rem',

			_before: {
				display: 'inline-block',
				content: "''",
				position: 'absolute',
				left: '-1.5rem',
				width: '1.5rem',
				height: '1.5rem',
				backgroundColor: 'actions.links.regular.default',
				maskImage:
					"url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE5LjA0ODQgMy4wNDc4NUg0Ljk1MzEyVjIwLjk1MjZIMTkuMDQ4NFYzLjA0Nzg1Wk02LjA5NTk4IDE5LjgwOThWNC4xOTA3MUgxNy45MDU1VjE5LjgwOThINi4wOTU5OFoiIGZpbGw9ImN1cnJlbnRDb2xvciIgLz48cGF0aCBkPSJNOS40NjA2NiAxMC4yNzgySDguMjE4NzVWMTMuMzMzNUg4Ljc4MjU2VjEyLjE2MzlIOS40MTExM0MxMC4xMDgzIDEyLjE2MzkgMTAuNDY2NCAxMS42OTE2IDEwLjQ2NjQgMTEuMTg4N0MxMC40NjY0IDEwLjY4NTggMTAuMTMxMSAxMC4yNzQ0IDkuNDY0NDYgMTAuMjc0NEw5LjQ2MDY2IDEwLjI3ODJaTTkuNDIyNTYgMTEuNjM0NEg4Ljc3ODc1VjEwLjgwNzdIOS40NDE2MUM5Ljc0NjM3IDEwLjgwNzcgOS44OTExMyAxMC45OTA2IDkuODkxMTMgMTEuMjE5MkM5Ljg5MTEzIDExLjQ0NzcgOS43NDYzNyAxMS42MzQ0IDkuNDIyNTYgMTEuNjM0NFoiIGZpbGw9ImN1cnJlbnRDb2xvciIgLz48cGF0aCBkPSJNMTEuOTAyMSAxMC4yNzgzSDEwLjg5MjZWMTMuMzMzNkgxMS44Mjk3QzEyLjkzMDcgMTMuMzMzNiAxMy40NjAyIDEyLjUxODMgMTMuNDYwMiAxMS43NDVDMTMuNDYwMiAxMC45NzE3IDEyLjk0NTkgMTAuMjc4MyAxMS45MDIxIDEwLjI3ODNaTTExLjgzNzMgMTIuODAwMkgxMS40NTY0VjEwLjgwNzhIMTEuOTIxMUMxMi41ODAyIDEwLjgwNzggMTIuODg4OCAxMS4yNjEyIDEyLjg4ODggMTEuNzQ1QzEyLjg4ODggMTIuMjYzMSAxMi41NDIxIDEyLjgwMDIgMTEuODM3MyAxMi44MDAyWiIgZmlsbD0iY3VycmVudENvbG9yIiAvPjxwYXRoIGQ9Ik0xMy45NjY4IDEzLjMzNzRIMTQuNTM0NFYxMi4wNTc0SDE1Ljk0NzdWMTEuNTI0SDE0LjUzNDRWMTAuODA3OEgxNi4wMjAxVjEwLjI3ODNIMTMuOTY2OFYxMy4zMzc0WiIgZmlsbD0iY3VycmVudENvbG9yIiAvPjwvc3ZnPg==')",
			},

			_hover: {
				_before: {
					backgroundColor: 'actions.links.regular.hoverAndPressed',
				},
			},

			_active: {
				_before: {
					backgroundColor: 'actions.links.regular.hoverAndPressed',
				},
			},
		},

		// Link style - "Primary button"
		'& a.button-primary': {
			display: 'flex',
			gap: 2,
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: '48px',
			borderWidth: '1px',
			cursor: 'pointer',
			width: 'min-content',
			minWidth: 'min-content',
			whiteSpace: 'nowrap',
			fontWeight: 'semibold',
			paddingInline: 4,
			paddingBlock: 2,
			fontSize: '0.875rem',
			height: '40px',
			borderColor: 'transparent',

			color: 'text.inverted.default',
			backgroundColor: 'actions.primary.regular.fillDefault',
			_disabled: {
				backgroundColor: 'actions.primary.regular.fillDisabled',
			},
			'&:not(:disabled)': {
				_hover: {
					backgroundColor: 'actions.primary.regular.fillHover',
					color: 'text.inverted.default',
				},
				_active: {
					color: 'actions.primary.regular.textAndIconPressed',
					backgroundColor: 'actions.primary.regular.fillPressed',
				},
			},
		},

		// Link style - "Secondary button"
		'& a.button-secondary': {
			display: 'flex',
			gap: 4,
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: '48px',
			borderWidth: '1px',
			cursor: 'pointer',
			width: 'min-content',
			minWidth: 'min-content',
			whiteSpace: 'nowrap',
			fontWeight: 'semibold',
			paddingInline: 4,
			paddingBlock: 2,
			fontSize: '0.875rem',
			height: '40px',

			color: 'text.regular.default',
			borderStyle: 'solid',
			borderColor: 'actions.secondary.regular.strokeDefault',
			_disabled: {
				color: 'text.regular.disabled',
				borderColor: 'actions.secondary.regular.strokeDisabled',
			},
			'&:not(:disabled)': {
				_hover: {
					color: 'text.regular.default',
					backgroundColor: 'actions.secondary.regular.fillHover',
				},
				_active: {
					color: 'text.regular.default',
					backgroundColor: 'actions.secondary.regular.fillPressed',
				},
			},
		},

		// Link style - "Tertiary button"
		'& a.button-tertiary': {
			display: 'flex',
			gap: 2,
			flexDirection: 'row',
			border: '1px solid transparent',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: '48px',
			cursor: 'pointer',
			width: 'min-content',
			minWidth: 'min-content',
			whiteSpace: 'nowrap',
			fontWeight: 'semibold',
			paddingInline: 4,
			paddingBlock: 2,
			fontSize: '0.875rem',
			height: '40px',
			borderBottom: 'none',

			color: 'text.regular.default',
			backgroundColor: 'actions.tertiary.regular.fillDefault',
			_disabled: {
				color: 'text.regular.disabled',
				backgroundColor: 'actions.tertiary.regular.fillDisabled',
			},
			'&:not(:disabled)': {
				_hover: {
					color: 'text.regular.default',
					backgroundColor: 'actions.tertiary.regular.fillHover',
				},
				_active: {
					color: 'text.regular.default',
					backgroundColor: 'actions.tertiary.regular.fillPressed',
				},
			},
		},

		// Custom class - "smaller-text"
		'& :not(:is(h1, h2, h3, h4, h5, h6)).smaller-text': {
			fontSize: '0.75rem',
			lineHeight: '146%',
		},

		// Custom class - "default-text-color"
		'& .default-text-color': {
			color: 'text.regular.default',
		},

		// Custom class - "custom-link"
		'& .custom-link': {
			cursor: 'pointer',
			color: 'actions.links.regular.default',
			borderBottom: '1px solid',
			borderBottomColor: 'actions.links.regular.default',

			_hover: {
				color: 'actions.links.regular.hoverAndPressed',
				borderBottomColor: 'actions.links.regular.hoverAndPressed',
			},

			_active: {
				color: 'actions.links.regular.hoverAndPressed',
				borderBottomColor: 'actions.links.regular.hoverAndPressed',
			},
		},

		// Custom class - "custom-text-h2"
		'& .custom-text-h2': {
			fontSize: '5rem',
			lineHeight: '120%',
			letterSpacing: '-0.01rem',
			fontWeight: 'bold',
		},

		// Custom class - "custom-title"
		'& .custom-title': {
			fontSize: '4rem',
			lineHeight: '120%',
			letterSpacing: '-0.01rem',
			fontWeight: 'regular',
		},
	},
	variants: {
		parentBackgroundColor: {
			Inverted: {
				color: 'text.inverted.default',

				'& ul li': {
					_before: {
						backgroundColor: 'text.inverted.default',
					},
				},

				'& a': {
					color: 'actions.links.inverted.default',
					borderBottom: '1px solid',
					borderBottomColor: 'actions.links.inverted.default',

					_hover: {
						color: 'actions.links.inverted.hoverAndPressed',
						borderBottomColor: 'actions.links.inverted.hoverAndPressed',
					},

					_active: {
						color: 'actions.links.inverted.hoverAndPressed',
						borderBottomColor: 'actions.links.inverted.hoverAndPressed',
					},
				},

				'& a.pdf-icon': {
					_before: {
						backgroundColor: 'actions.links.inverted.default',
					},
					_hover: {
						_before: {
							backgroundColor: 'actions.links.inverted.hoverAndPressed',
						},
					},
					_active: {
						_before: {
							backgroundColor: 'actions.links.inverted.hoverAndPressed',
						},
					},
					_visited: {
						_before: {
							backgroundColor: 'actions.links.inverted.default',
						},
					},
				},

				'& hr': {
					border: '1px solid',
					borderColor: 'other.border.inverted.default',
				},

				'& table': {
					'& tr': {
						_hover: {
							backgroundColor: 'other.indicator.inverted.hover',
						},
					},
				},

				'& table tr:not(:last-child) td': {
					borderBottom: '1px solid !important',
					borderBottomColor: 'other.border.inverted.default !important',
				},

				'& a.button-primary': {
					color: 'text.regular.default',
					backgroundColor: 'actions.primary.inverted.fillDefault',
					_disabled: {
						color: 'text.inverted.disabled',
						backgroundColor: 'actions.primary.inverted.fillDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							color: 'text.regular.default',
							backgroundColor: 'actions.primary.inverted.fillHover',
						},
						_active: {
							backgroundColor: 'actions.primary.inverted.fillPressed',
							color: 'text.regular.default',
						},
					},
				},

				'& a.button-secondary': {
					color: 'text.inverted.default',
					backgroundColor: 'transparent',
					borderColor: 'actions.secondary.inverted.default',
					_disabled: {
						opacity: '30%',
						color: 'actions.secondary.inverted.default',
						borderColor: 'actions.secondary.inverted.default',
					},
					'&:not(:disabled)': {
						_hover: {
							color: 'text.inverted.default',
							backgroundColor: 'actions.secondary.inverted.fillHover',
						},
						_active: {
							color: 'text.inverted.default',
							backgroundColor: 'actions.secondary.inverted.fillPressed',
						},
					},
				},

				'& a.button-tertiary': {
					color: 'text.inverted.default',
					backgroundColor: 'actions.tertiary.inverted.fillDefault',
					_disabled: {
						color: 'text.inverted.disabled',
						backgroundColor: 'actions.tertiary.inverted.fillDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							color: 'text.inverted.default',
							backgroundColor: 'actions.tertiary.inverted.fillHover',
						},
						_active: {
							color: 'text.inverted.default',
							backgroundColor: 'actions.tertiary.inverted.fillPressed',
						},
					},
				},
			},
			Secondary: {
				color: 'text.regular.default',
				'& ul li': {
					_before: {
						backgroundColor: 'text.regular.default',
					},
				},

				'& hr': {
					border: '1px solid',
					borderColor: 'other.border.regular.strong',
				},

				'& table': {
					'& tr': {
						_hover: {
							backgroundColor: 'actions.tertiary.regular.fillHover',
						},
					},
				},

				'& table tr:not(:last-child) td': {
					borderBottom: '1px solid !important',
					borderBottomColor: 'other.border.regular.weak !important',
				},

				'& a.button-tertiary': {
					color: 'text.inverted.default',
					backgroundColor: 'actions.tertiary.inverted.fillDefault',
					_disabled: {
						color: 'text.inverted.disabled',
						backgroundColor: 'actions.tertiary.inverted.fillDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							color: 'text.inverted.default',
							backgroundColor: 'actions.tertiary.inverted.fillHover',
						},
						_active: {
							color: 'text.inverted.default',
							backgroundColor: 'actions.tertiary.inverted.fillPressed',
						},
					},
				},
			},
			PrimaryInverted: {
				color: 'text.inverted.default',
				'& ul li': {
					_before: {
						backgroundColor: 'text.inverted.default',
					},
				},

				'& a': {
					color: 'actions.links.inverted.default',
					borderBottom: '1px solid',
					borderBottomColor: 'actions.links.inverted.default',

					_hover: {
						color: 'actions.links.inverted.hoverAndPressed',
						borderBottomColor: 'actions.links.inverted.hoverAndPressed',
					},

					_active: {
						color: 'actions.links.inverted.hoverAndPressed',
						borderBottomColor: 'actions.links.inverted.hoverAndPressed',
					},
				},

				'& a.pdf-icon': {
					_before: {
						backgroundColor: 'actions.links.inverted.default',
					},
					_hover: {
						_before: {
							backgroundColor: 'actions.links.inverted.hoverAndPressed',
						},
					},
					_active: {
						_before: {
							backgroundColor: 'actions.links.inverted.hoverAndPressed',
						},
					},
					_visited: {
						_before: {
							backgroundColor: 'actions.links.inverted.default',
						},
					},
				},

				'& hr': {
					border: '1px solid',
					borderColor: 'other.border.inverted.default',
				},

				'& table': {
					'& tr': {
						_hover: {
							backgroundColor: 'other.indicator.inverted.hover',
						},
					},
				},

				'& table tr:not(:last-child) td': {
					borderBottom: '1px solid !important',
					borderBottomColor: 'other.border.inverted.default !important',
				},

				'& a.button-primary': {
					color: 'text.regular.default',
					backgroundColor: 'actions.primary.inverted.fillDefault',
					_disabled: {
						color: 'text.inverted.disabled',
						backgroundColor: 'actions.primary.inverted.fillDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							color: 'text.regular.default',
							backgroundColor: 'actions.primary.inverted.fillHover',
						},
						_active: {
							backgroundColor: 'actions.primary.inverted.fillPressed',
							color: 'text.regular.default',
						},
					},
				},

				'& a.button-secondary': {
					color: 'text.inverted.default',
					backgroundColor: 'transparent',
					borderColor: 'actions.secondary.inverted.default',
					_disabled: {
						opacity: '30%',
						color: 'actions.secondary.inverted.default',
						borderColor: 'actions.secondary.inverted.default',
					},
					'&:not(:disabled)': {
						_hover: {
							color: 'text.inverted.default',
							backgroundColor: 'actions.secondary.inverted.fillHover',
						},
						_active: {
							color: 'text.inverted.default',
							backgroundColor: 'actions.secondary.inverted.fillPressed',
						},
					},
				},

				'& a.button-tertiary': {
					color: 'text.inverted.default',
					backgroundColor: 'actions.tertiary.inverted.fillDefault',
					_disabled: {
						color: 'text.inverted.disabled',
						backgroundColor: 'actions.tertiary.inverted.fillDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							color: 'text.inverted.default',
							backgroundColor: 'actions.tertiary.inverted.fillHover',
						},
						_active: {
							color: 'text.inverted.default',
							backgroundColor: 'actions.tertiary.inverted.fillPressed',
						},
					},
				},
			},
			Primary: {
				backgroundColor: 'transparent',
			},
			PrimaryAccent: {
				backgroundColor: 'transparent',
			},
			SecondaryAccent: {
				backgroundColor: 'transparent',
			},
			TertiaryAccent: {
				backgroundColor: 'transparent',
			},
		},
		site: {
			vosti: {},
		},
	},
});

export type TextSnippetVariants = RecipeVariant<typeof textSnippet>;
