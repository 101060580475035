import { IFormFieldFileUploadValidations } from '@/components/shared/Forms/FileUploadFormField/FileUploadFormField';
import { FileUploadError } from '@/components/shared/Forms/FileUploadFormField/components/FileUpload';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';

export interface IFormFieldUploadConstraints {
	isMandatory?: boolean;
	maximumFileSize?: number;
	allowedFileTypes?: string;
	maximumNumberOfFiles?: number;
	allowedTotalSizeOnMultiple?: number;
}

export const getFormFileUploadErrorsLabel = (
	errors: FileUploadError[],
	translations: TranslationLabelValues
): string => {
	return errors.map((error) => translate(translations, mapFormFileUploadErrorToTranslationKey(error))).join(', ');
};

export const mapFormFileUploadErrorToTranslationKey = (error: FileUploadError): string => {
	switch (error) {
		case 'FILE_TOO_LARGE':
			return 'forms.help.error.file-too-large';
		case 'FILES_TOO_LARGE':
			return 'forms.help.fileupload-max-content-size';
		case 'FILE_INVALID_TYPE':
			return 'forms.help.error.file-invalid-type';
		case 'FIELD_EMPTY':
			return 'forms.help.error.field-empty';
		case 'TOO_MANY_FILES':
			return 'forms.help.error.too-many-files';
		case 'FILE_TOO_SMALL':
		default:
			return error;
	}
};

export const getConstraintsLabels = (
	{ maximumFileSize, maximumNumberOfFiles, allowedTotalSizeOnMultiple }: IFormFieldUploadConstraints,
	translations: TranslationLabelValues,
	allowedFilesInfoText?: string
) => {
	const constraintsLabels = [];
	const constraintsDelimiter = '  |  ';

	if (maximumFileSize) {
		constraintsLabels.push(`${translate(translations, 'forms.help.fileupload-sizelimit')}: ${maximumFileSize} MB`);
	}

	if (allowedTotalSizeOnMultiple) {
		constraintsLabels.push(
			`${translate(translations, 'forms.help.fileupload-total-size')}: ${allowedTotalSizeOnMultiple} MB`
		);
	}

	if (maximumNumberOfFiles) {
		constraintsLabels.push(`${translate(translations, 'forms.help.fileupload-max-files')}: ${maximumNumberOfFiles}`);
	}

	if (allowedFilesInfoText) {
		constraintsLabels.push(
			`${translate(translations, 'forms.help.fileupload.allowed-files')}: ${allowedFilesInfoText}`
		);
	}

	return constraintsLabels.join(constraintsDelimiter);
};

export const getFieldFileUploadValueValidations = (
	{ isMandatory, maximumNumberOfFiles, allowedTotalSizeOnMultiple }: Partial<IFormFieldUploadConstraints>,
	mandatoryErrorMessage: string
): IFormFieldFileUploadValidations => {
	return {
		required: (value: File[]) => {
			if (!isMandatory) {
				return true;
			}

			if (value && value.length > 0) {
				return true;
			}

			return mandatoryErrorMessage ?? 'FIELD_EMPTY';
		},
		maxFileSize: (value: File[]) => {
			if (!allowedTotalSizeOnMultiple || allowedTotalSizeOnMultiple === 0) {
				return true;
			}

			const totalUploadedFileSize = value.reduce((acc, file) => acc + file.size, 0);

			// Convert max file size, which is provided in megabytes, to bytes
			if (totalUploadedFileSize > allowedTotalSizeOnMultiple * 1000000) {
				return `FILES_TOO_LARGE`;
			}

			return true;
		},
		maximumNumberOfFiles: (value: File[]) => {
			if (!maximumNumberOfFiles || maximumNumberOfFiles === 0) {
				return true;
			}

			if (value.length > maximumNumberOfFiles) {
				return 'TOO_MANY_FILES';
			}

			return true;
		},
	};
};
