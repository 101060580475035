export enum ColorMode {
	LIGHT = 'light',
	DARK = 'dark',
}

export enum BackgroundVariant {
	LIGHT = 'light',
	DARK = 'dark',
	SAND = 'sand',
}

export enum BackgroundColorVariant {
	PRIMARY = 'Primary',
	INVERTED = 'Inverted',
	SECONDARY = 'Secondary',
	PRIMARY_ACCENT = 'PrimaryAccent',
	PRIMARY_INVERTED = 'PrimaryInverted',
	SECONDARY_ACCENT = 'SecondaryAccent',
	TERTIARY_ACCENT = 'TertiaryAccent',
}
