import { css } from '@/styled-system/css';
import { Box, HStack } from '@/styled-system/jsx';
import { skeleton } from '@/styled-system/recipes';

import { cx } from '@/styled-system/css';

export const ArticleFilterHeaderLoader = () => {
	return (
		<HStack w="100%" alignItems="center" justifyContent="flex-start">
			<Box className={skeleton()} height="40px" flex="1"></Box>
			<Box className={skeleton()} height="40px" flex="1"></Box>
			<Box className={skeleton()} height="40px" flex="1"></Box>
		</HStack>
	);
};

export const ArticleSkeleton = () => {
	return (
		<Box
			className={cx(
				skeleton(),
				css({
					containerType: 'inline-size',
					display: 'block',
					width: '100%',
					height: '450px',
					boxShadow: 'subtleTop',
					borderRadius: 'md',
					overflow: 'hidden',
				})
			)}
		></Box>
	);
};
