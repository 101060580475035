import { EventCategory, ITrackingConfigurationItem, TrackingUseCase } from '@/types/tracking';

interface IUseCaseTrackingConfigItem extends ITrackingConfigurationItem {
	UseCase: TrackingUseCase;
}

type UseCaseTrackingConfig = IUseCaseTrackingConfigItem[];

const useCaseTrackingConfig: UseCaseTrackingConfig = [
	{
		UseCase: TrackingUseCase.Print,
		EventCategory: EventCategory.LINK_PRINT,
		EventTechCategory: 'link',
		EventAction: 'print',
		EventLabel: '{target-contentid}|{target-pagetitle}',
	},
	{
		UseCase: TrackingUseCase.Document,
		EventCategory: EventCategory.MEDIA,
		EventTechCategory: 'link',
		EventAction: 'download',
		EventLabel: '{target-file}',
		MediaType: 'document',
		MediaValue: '{target-contentid}',
		MediaSource: 'corpweb-cms',
	},
];

export { type IUseCaseTrackingConfigItem, type UseCaseTrackingConfig, useCaseTrackingConfig, TrackingUseCase };
