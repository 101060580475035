'use client';

import { FC } from '@/interfaces/blocks/shared';
import { VStack, VstackProps } from '@/styled-system/jsx';
import { memo, useEffect } from 'react';

export const FilterLocationWrapper: FC<VstackProps> = memo((props) => {
	useEffect(() => {
		const originalPushState = history.pushState;

		history.pushState = function (data, title, url) {
			originalPushState.apply(history, [data, title, url]);
			window.dispatchEvent(new Event('locationchange'));
		};

		return () => {
			history.pushState = originalPushState;
		};
	});

	return <VStack {...props}></VStack>;
});
