'use client';

import { ReactNode, RefObject, createContext, useContext, useMemo } from 'react';

interface IFormTrackingContext {
	selectionItems: IRegisteredSelectionItem[];
}

export interface IRegisteredSelectionItem {
	trackingRef: RefObject<HTMLElement>;
	id: string;
}

interface IFormTrackingProviderProps {
	children: ReactNode;
	selectionItems?: IRegisteredSelectionItem[];
}

const FormTrackingContext = createContext<IFormTrackingContext>({
	selectionItems: [],
});

export const useFormTrackingSelectionItems = () => {
	return useContext(FormTrackingContext).selectionItems;
};

export const FromTrackingProvider: React.FC<IFormTrackingProviderProps> = ({ children, selectionItems }) => {
	const contextValue: IFormTrackingContext = useMemo(() => ({ selectionItems: selectionItems ?? [] }), []);

	return <FormTrackingContext.Provider value={contextValue}>{children}</FormTrackingContext.Provider>;
};
