export enum MimeType {
	PDF = 'application/pdf',
	DOC = 'application/msword',
	DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	XLS = 'application/vnd.ms-excel',
	XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	PPT = 'application/vnd.ms-powerpoint',
	PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	AVI = 'video/x-msvideo',
	TXT = 'text/plain',
	ZIP = 'application/x-zip-compressed',
}
