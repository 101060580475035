import { styled } from '@/styled-system/jsx';
import { HTMLStyledProps } from '@/styled-system/types';
import { ICloudinaryEnvironmentVariables, getCloudinaryImageSourceUrl } from '@/utils/cloudinary';
import { FC } from 'react';

const personThumbnailImageCloudinaryTransformations = 'f_auto/q_auto:best/r_max,g_face,c_thumb,z_0.8,';

interface IPersonThumbnailImageProps extends HTMLStyledProps<'img'> {
	src: string;
	alt: string;
	fallback?: JSX.Element;
	cloudinaryOverrides?: ICloudinaryEnvironmentVariables;
}

export const PersonThumbnailImage: FC<IPersonThumbnailImageProps> = ({
	src,
	cloudinaryOverrides,
	fallback,
	alt,
	...rest
}) => {
	if (!src) {
		return fallback || null;
	}

	const cloudinarySrc = getCloudinaryImageSourceUrl({
		src,
		width: 500,
		height: 500,
		transformations: personThumbnailImageCloudinaryTransformations,
		cloudinaryVariables: cloudinaryOverrides,
	});
	const cloudinarySrcSet = `${cloudinarySrc} 100vw`;

	return <styled.img src={cloudinarySrc} alt={alt} srcSet={cloudinarySrcSet} {...rest} />;
};
