const weekdays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
const months = [
	'Janvier',
	'Février',
	'Mars',
	'Avril',
	'Mai',
	'Juin',
	'Juillet',
	'Août',
	'Septembre',
	'Octobre',
	'November',
	'Décembre',
];
const shortMonths = ['Janv', 'Févr', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'];

export default function getFrenchLocale(country: string): ILocale {
	return {
		name: 'fr-' + country,
		weekdays: weekdays,
		weekStart: 1,
		weekdaysShort: weekdays.map((day) => day.substring(0, 3)),
		weekdaysMin: weekdays.map((day) => day.substring(0, 2)),
		months: months,
		monthsShort: shortMonths,
		formats: {},
		relativeTime: {},
	};
}
