'use client';

import dynamic from 'next/dynamic';

const SelectComponent = dynamic(() => import('@/components/core/Select/Select').then((mod) => mod.SelectComponent));
const SelectIconComponent = dynamic(() =>
	import('@/components/core/Select/Select').then((mod) => mod.SelectIconComponent)
);

export const Select: typeof SelectComponent = (props) => <SelectComponent {...props} />;

export const SelectIcon: typeof SelectIconComponent = (props) => <SelectIconComponent {...props} />;
