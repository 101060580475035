import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const linkFn = /* @__PURE__ */ createRecipe('link', {
  "size": "lg",
  "mode": "standard"
}, [
  {
    "mode": "inverted",
    "css": {
      "base": {
        "color": "actions.links.inverted.default",
        "borderColor": "actions.links.inverted.default",
        "_hover": {
          "color": "actions.links.inverted.hoverAndPressed",
          "borderColor": "actions.links.inverted.hoverAndPressed"
        }
      }
    }
  }
])

const linkVariantMap = {
  "size": [
    "xl",
    "lg",
    "md",
    "sm",
    "xs",
    "unsized"
  ],
  "mode": [
    "standard",
    "inverted"
  ]
}

const linkVariantKeys = Object.keys(linkVariantMap)

export const link = /* @__PURE__ */ Object.assign(memo(linkFn.recipeFn), {
  __recipe__: true,
  __name__: 'link',
  __getCompoundVariantCss__: linkFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: linkVariantKeys,
  variantMap: linkVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, linkVariantKeys)
  },
  getVariantProps: linkFn.getVariantProps,
})