import { Heading } from '@/components/core/Heading/Heading';
import { Text } from '@/components/core/Text/Text';
import { SearchIcon } from '@/icons/SearchIcon';
import { WarningIcon } from '@/icons/WarningIcon';
import { FC } from '@/interfaces/blocks/shared';
import { Box, Flex, FlexProps } from '@/styled-system/jsx';

export enum ContentSearchState {
	ERROR = 'error',
	EMPTY = 'empty',
}

interface IContentSearchStateMessageProps extends FlexProps {
	title: string;
	state: ContentSearchState;
	content: string;
}

export const ContentSearchStateMessage: FC<IContentSearchStateMessageProps> = ({ title, content, state, ...rest }) => {
	return (
		<Flex
			flexDir={{ base: 'column', sm: 'row' }}
			p="12"
			gap="6"
			color="text.regular.default"
			bgColor="surface.interactive.card.secondary"
			borderRadius="sm"
			{...rest}
		>
			<Box>
				{state === 'error' ? <WarningIcon width="64px" height="64px" /> : <SearchIcon width="64px" height="64px" />}
			</Box>

			<Box>
				<Heading type="h4" mb="3">
					{title}
				</Heading>
				<Text fontWeight="medium">{content}</Text>
			</Box>
		</Flex>
	);
};
