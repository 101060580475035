'use client';

import { Button } from '@/components/core/Button/Button';
import { Text } from '@/components/core/Text/Text';
import { CloseIcon } from '@/icons/CloseIcon';
import { WarningIcon } from '@/icons/WarningIcon';
import { TextFormFieldDisplayMode } from '@/interfaces/blocks/forms';
import { css } from '@/styled-system/css';
import { Box } from '@/styled-system/jsx';
import { textarea } from '@/styles/theme/textarea';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import { TextareaHTMLAttributes, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	displayMode?: TextFormFieldDisplayMode;
	name?: string;
	label?: string;
	errorMessage?: string;
	isError?: boolean;
	isMandatory?: boolean;
	error?: FieldError;
	clearInput?: () => void;
	translations: TranslationLabelValues;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
	(
		{
			displayMode,
			label,
			placeholder,
			value,
			onChange,
			clearInput,
			isError,
			error,
			disabled,
			readOnly,
			isMandatory,
			errorMessage,
			hidden,
			translations,
			...rest
		},
		ref
	) => {
		const classes = textarea.raw({ disabled, readOnly, isError, hidden });
		const optionalLabel = translations ? ` (${translate(translations, 'form.optional', 'optional')})` : ` (optional)`;

		return (
			<Box className={css(classes.root)}>
				{Boolean(label?.length) && (
					<label className={css(classes.label)}>{`${label} ${isMandatory ? ' *' : optionalLabel}`}</label>
				)}
				<Box className={css(classes.textareaWrapper)}>
					<textarea
						className={css(classes.textarea)}
						placeholder={placeholder}
						onChange={onChange}
						disabled={disabled}
						readOnly={readOnly}
						value={value}
						required={isMandatory}
						hidden={hidden}
						ref={ref}
						{...rest}
					/>
					{Boolean(value) && Boolean(!readOnly && !disabled) && (
						<Button
							rootProps={classes.clearButton}
							variant="unstyled"
							size="unsized"
							onClick={clearInput}
							leftIcon={<CloseIcon color="icons.inverted.default" width="16px" height="16px" />}
							tabIndex={-1}
						/>
					)}
				</Box>
				{Boolean(isError) && (
					<Box className={css(classes.textareaFooter)}>
						<WarningIcon className={css(classes.error)} width={12} height={12} />
						<Text className={css(classes.helperText)} size="xxs">
							{error?.message}
						</Text>
					</Box>
				)}
			</Box>
		);
	}
);
