import {
	ILinkBlockTemplateProps,
	LinkTemplate,
} from '@/components/block-templates/LinkBlockTemplate/LinkBlockTemplate';
import { IBlockResponse } from '@/interfaces/coreInformation';
import { css } from '@/styled-system/css';
import { Box } from '@/styled-system/jsx';
import { FC } from 'react';

interface INavigationFooterProps {
	data?: Array<IBlockResponse<ILinkBlockTemplateProps>>;
}

export const NavigationFooter: FC<INavigationFooterProps> = ({ data }) => {
	return (
		<Box
			mt="auto"
			pb="max(24px, 2vw)"
			gap={4}
			display="flex"
			alignItems="flex-start"
			flexWrap="wrap"
			position="relative"
			w={{ base: '100%', lg: '75%' }}
		>
			{data?.map((item) => {
				return (
					<LinkTemplate
						key={item.contentLink.id}
						blockDefinition={item}
						// TODO: This as="a" is added because one of the buttons navigates to search which has a code snippet that uses onLoad (Google CSE).
						// We should remove it, keep the links as NextLinks and find a real way to execute CodeSnippets with client navigation
						as="a"
						className={css({
							'& svg': {
								width: '24px',
								height: '24px',
							},
						})}
						size="medium"
					/>
				);
			})}
		</Box>
	);
};
