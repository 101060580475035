import { ILinkBlockTemplateProps } from '@/components/block-templates/LinkBlockTemplate/LinkBlockTemplate';
import { ITrackingTarget } from '@/types/tracking';

export const getLinkTrackingTarget = (linkBlock: ILinkBlockTemplateProps): ITrackingTarget | undefined => {
	if (!linkBlock?.linkTarget) {
		return;
	}

	return {
		targetContentId: linkBlock.linkTarget.contentid,
		targetFile: linkBlock.linkTarget.file,
		targetPageTitle: linkBlock.linkTarget.pagetitle,
	};
};
