import { RecipeVariantProps, sva } from '@/styled-system/css';

/**
 * Any changes in "button" recipe should also be reflected in the "textSnippet" recipe.
 */
export const button = sva({
	slots: ['root', 'icon'],
	base: {
		root: {
			display: 'flex',
			gap: 2,
			flexDirection: 'row',
			borderColor: 'transparent',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: '48px',
			borderWidth: '1px',
			cursor: 'pointer',
			width: 'max-content',
			color: 'text.inverted.default',
			fontWeight: 'semibold',
			lineHeight: '1',
			_disabled: {
				cursor: 'not-allowed',
			},
			boxSizing: 'border-box',
		},
		icon: {
			'& > svg': {
				maxHeight: '100%',
			},
		},
	},
	defaultVariants: {
		mode: 'standard',
		variant: 'primary',
		size: 'medium',
		displayType: 'standard',
		widthVariant: 'unsized',
		isLoading: false,
	},
	variants: {
		isLoading: {
			true: {
				root: {
					pointerEvents: 'none',
				},
			},
		},
		widthVariant: {
			unsized: {
				root: {
					minWidth: 'min-content',
				},
			},
			full: {
				root: {
					width: '100%',
				},
			},
			auto: {
				root: {
					width: 'auto',
				},
			},
		},
		variant: {
			unstyled: {},
			primary: {
				root: {
					color: 'text.inverted.default',
					backgroundColor: 'actions.primary.regular.fillDefault',
					_disabled: {
						backgroundColor: 'actions.primary.regular.fillDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							backgroundColor: 'actions.primary.regular.fillHover',
						},
						_groupHover: {
							backgroundColor: 'actions.primary.regular.fillHover',
						},
						_active: {
							color: 'actions.primary.regular.textAndIconPressed',
							backgroundColor: 'actions.primary.regular.fillPressed',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.inverted.default',
					},
				},
			},
			secondary: {
				root: {
					color: 'text.regular.default',
					borderStyle: 'solid',
					borderColor: 'actions.secondary.regular.strokeDefault',
					_disabled: {
						color: 'text.regular.disabled',
						borderColor: 'actions.secondary.regular.strokeDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							backgroundColor: 'actions.secondary.regular.fillHover',
						},
						_groupHover: {
							backgroundColor: 'actions.secondary.regular.fillHover',
						},
						_active: {
							backgroundColor: 'actions.secondary.regular.fillPressed',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.regular.default',
					},
				},
			},
			tertiary: {
				root: {
					color: 'text.regular.default',
					backgroundColor: 'actions.tertiary.regular.fillDefault',
					_disabled: {
						color: 'text.regular.disabled',
						backgroundColor: 'actions.tertiary.regular.fillDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							backgroundColor: 'actions.tertiary.regular.fillHover',
						},
						_groupHover: {
							backgroundColor: 'actions.tertiary.regular.fillHover',
						},
						_active: {
							backgroundColor: 'actions.tertiary.regular.fillPressed',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.regular.default',
					},
				},
			},
			transparent: {
				root: {
					color: 'text.regular.default',
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					borderStyle: 'solid',
					borderWidth: '1px',
					_disabled: {
						color: 'text.regular.disabled',
					},
					'&:not(:disabled)': {
						_hover: {
							backgroundColor: 'actions.secondary.regular.fillHover',
						},
						_groupHover: {
							backgroundColor: 'actions.secondary.regular.fillHover',
						},
						_active: {
							backgroundColor: 'actions.secondary.regular.fillPressed',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.regular.default',
					},
				},
			},
			plain: {
				root: {
					color: 'text.regular.default',
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					borderBottomWidth: '1px',
					borderStyle: 'solid',
					_disabled: {
						color: 'text.regular.disabled',
						borderBottomWidth: '0px',
					},
					'&:not(:disabled)': {
						_hover: {
							borderBottomColor: 'text.regular.default',
						},
						_groupHover: {
							borderBottomColor: 'text.regular.default',
						},
						_active: {
							color: 'text.regular.pressed',
							borderBottomColor: 'text.regular.pressed',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.regular.default',
					},
				},
			},
			dangerPrimary: {
				root: {
					color: 'text.inverted.default',
					backgroundColor: 'actions.primaryDanger.regular.fillDefault',
					_disabled: {
						backgroundColor: 'actions.primaryDanger.regular.fillDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							backgroundColor: 'actions.primaryDanger.regular.fillHoverAndPressed',
						},
						_groupHover: {
							backgroundColor: 'actions.primaryDanger.regular.fillHoverAndPressed',
						},
						_active: {
							backgroundColor: 'actions.primaryDanger.regular.fillHoverAndPressed',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.inverted.default',
					},
				},
			},
			dangerSecondary: {
				root: {
					color: 'text.regular.danger',
					borderColor: 'actions.primaryDanger.regular.fillDefault',
					_disabled: {
						color: 'text.regular.disabled',
						borderColor: 'actions.primaryDanger.regular.fillDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							backgroundColor: 'actions.secondaryDanger.regular.fillHover',
						},
						_groupHover: {
							backgroundColor: 'actions.secondaryDanger.regular.fillHover',
						},
						_active: {
							backgroundColor: 'actions.secondaryDanger.regular.fillPressed',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.inverted.default',
					},
				},
			},
		},
		size: {
			unsized: {},
			small: {
				root: {
					paddingInline: '16px',
					paddingBlock: '7px',
					fontSize: '0.75rem',
					letterSpacing: '0.24px',
					minHeight: '32px',
				},
				icon: {
					'& > svg': {
						width: '16px',
						height: '16px',
					},
				},
			},
			medium: {
				root: {
					paddingInline: '16px',
					paddingBlock: '7px',
					fontSize: '0.875rem',
					minHeight: '40px',
				},
				icon: {
					'& > svg': {
						width: '24px',
						height: '24px',
					},
				},
			},
			large: {
				root: {
					paddingInline: '16px',
					paddingBlock: '11px',
					fontSize: '0.875rem',
					minHeight: '48px',
				},
				icon: {
					'& > svg': {
						width: '24px',
						height: '24px',
					},
				},
			},
		},
		mode: {
			standard: {},
			inverted: {},
		},
		displayType: {
			standard: {},
			iconButton: {
				root: {
					height: 'auto',
				},
			},
		},
	},
	compoundVariants: [
		{
			mode: 'inverted',
			variant: 'primary',
			css: {
				root: {
					color: 'text.regular.default',
					backgroundColor: 'actions.primary.inverted.fillDefault',
					_disabled: {
						color: 'text.inverted.disabled',
						backgroundColor: 'actions.primary.inverted.fillDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							backgroundColor: 'actions.primary.inverted.fillHover',
						},
						_groupHover: {
							backgroundColor: 'actions.primary.inverted.fillHover',
						},
						_active: {
							backgroundColor: 'actions.primary.inverted.fillPressed',
							color: 'text.regular.default',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.regular.default',
					},
				},
			},
		},
		{
			mode: 'inverted',
			variant: 'secondary',
			css: {
				root: {
					color: 'text.inverted.default',
					backgroundColor: 'transparent',
					borderColor: 'actions.secondary.inverted.default',
					_disabled: {
						opacity: '30%',
						color: 'actions.secondary.inverted.default',
						borderColor: 'actions.secondary.inverted.default',
					},
					'&:not(:disabled)': {
						_hover: {
							backgroundColor: 'actions.secondary.inverted.fillHover',
						},
						_groupHover: {
							backgroundColor: 'actions.secondary.inverted.fillHover',
						},
						_active: {
							backgroundColor: 'actions.secondary.inverted.fillPressed',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.inverted.default',
					},
				},
			},
		},
		{
			mode: 'inverted',
			variant: 'tertiary',
			css: {
				root: {
					color: 'text.inverted.default',
					backgroundColor: 'actions.tertiary.inverted.fillDefault',
					_disabled: {
						color: 'text.inverted.disabled',
						backgroundColor: 'actions.tertiary.inverted.fillDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							backgroundColor: 'actions.tertiary.inverted.fillHover',
						},
						_groupHover: {
							backgroundColor: 'actions.tertiary.inverted.fillHover',
						},
						_active: {
							backgroundColor: 'actions.tertiary.inverted.fillPressed',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.inverted.default',
					},
				},
			},
		},
		{
			mode: 'inverted',
			variant: 'transparent',
			css: {
				root: {
					color: 'text.inverted.default',
					backgroundColor: 'transparent',
					_disabled: {
						color: 'actions.secondary.inverted.default',
						backgroundColor: 'transparent',
						opacity: '30%',
					},
					'&:not(:disabled)': {
						_hover: {
							backgroundColor: 'actions.secondary.inverted.fillHover',
						},
						_groupHover: {
							backgroundColor: 'actions.secondary.inverted.fillHover',
						},
						_active: {
							backgroundColor: 'actions.secondary.inverted.fillHover',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.inverted.default',
					},
				},
			},
		},
		{
			mode: 'inverted',
			variant: 'plain',
			css: {
				root: {
					color: 'actions.primary.inverted.fillDefault',
					backgroundColor: 'transparent',
					borderWidth: '1px',
					borderColor: 'transparent',
					_disabled: {
						color: 'actions.primary.inverted.fillDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							color: 'actions.primary.inverted.fillDefault',
							borderBottomColor: 'actions.primary.inverted.fillDefault',
						},
						_groupHover: {
							color: 'actions.primary.inverted.fillDefault',
							borderBottomColor: 'actions.primary.inverted.fillDefault',
						},
						_active: {
							color: 'actions.primary.inverted.fillPressed',
							borderBottomColor: 'actions.primary.inverted.fillPressed',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.inverted.default',
					},
				},
			},
		},
		{
			mode: 'inverted',
			variant: 'dangerPrimary',
			css: {
				root: {
					color: 'text.inverted.default',
					backgroundColor: 'actions.primaryDanger.inverted.fillDefault',
					_disabled: {
						color: 'text.regular.default',
						backgroundColor: 'actions.primaryDanger.inverted.fillDisabled',
					},
					'&:not(:disabled)': {
						_hover: {
							backgroundColor: 'actions.primaryDanger.inverted.fillHoverAndPressed',
						},
						_groupHover: {
							backgroundColor: 'actions.primaryDanger.inverted.fillHoverAndPressed',
						},
						_active: {
							backgroundColor: 'actions.primaryDanger.inverted.fillHoverAndPressed',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.inverted.default',
					},
				},
			},
		},
		{
			mode: 'inverted',
			variant: 'dangerSecondary',
			css: {
				root: {
					color: 'actions.secondaryDanger.inverted.strokeDefault',
					borderColor: 'actions.secondaryDanger.inverted.strokeDefault',
					backgroundColor: 'actions.secondaryDanger.inverted.fillHover',
					_disabled: {
						color: 'text.inverted.disabled',
						borderColor: 'actions.primaryDanger.inverted.fillDisabled',
						backgroundColor: 'transparent',
					},
					'&:not(:disabled)': {
						_hover: {
							backgroundColor: 'actions.secondaryDanger.inverted.fillHover',
						},
						_groupHover: {
							backgroundColor: 'actions.secondaryDanger.inverted.fillHover',
						},
						_active: {
							backgroundColor: 'actions.secondaryDanger.inverted.fillPressed',
						},
					},
				},
				icon: {
					'& > svg': {
						fill: 'icons.regular.default',
					},
				},
			},
		},
		{
			mode: 'inverted',
			variant: 'dangerSecondary',
			isLoading: true,
			css: {
				root: {
					color: 'icons.inverted.default',
				},
			},
		},
		{
			size: 'small',
			displayType: 'iconButton',
			css: {
				root: {
					paddingBlock: 2,
					paddingInline: 2,
				},
			},
		},
		{
			size: 'medium',
			displayType: 'iconButton',
			css: {
				root: {
					paddingBlock: 2,
					paddingInline: 2,
				},
			},
		},
		{
			size: 'large',
			displayType: 'iconButton',
			css: {
				root: {
					paddingBlock: 3,
					paddingInline: 3,
				},
			},
		},
		{
			size: ['large', 'medium', 'small', 'unsized'],
			variant: 'plain',
			css: {
				root: {
					paddingBlock: 1,
					paddingInline: 0,
					height: 'auto',
					borderRadius: 0,
				},
			},
		},
	],
});

export type ButtonVariants = RecipeVariantProps<typeof button>;
