import { NavigationLink } from '@/components/core/NavigationLink/NavigationLink';
import {
	NavigationItem,
	getActiveIndicesFromPathname,
	getPaths,
} from '@/components/shared/Navigation/Navigation.utils';
import { NavigationColumnContainer } from '@/components/shared/Navigation/components/NavigationColumnContainer';
import { NavigationLeafLink } from '@/components/shared/Navigation/components/NavigationLeafLink';
import { NavigationWelcome } from '@/components/shared/Navigation/components/NavigationWelcome';
import { Box, HStack, HstackProps } from '@/styled-system/jsx';
import { FC, useMemo, useState } from 'react';

interface INavigationContentProps extends HstackProps {
	data: Array<NavigationItem>;
	pathname: string;
	claim: string;
}

export const NavigationContentDesktop: FC<INavigationContentProps> = ({ data, claim, pathname, ...rest }) => {
	const paths = getPaths(pathname);

	const [linkStack, setLinkStack] = useState<Array<number>>(() => getActiveIndicesFromPathname(pathname, data));

	const levelOneIndex = linkStack[0];
	const levelTwoIndex = linkStack[1];

	const showLevelOne = useMemo(() => {
		if (levelOneIndex === undefined) {
			return false;
		}

		const hasSubnodes = Boolean(data?.[levelOneIndex]?.subNodes?.length);

		if (!hasSubnodes) {
			return false;
		}

		return true;
	}, [levelOneIndex, data]);

	const showLevelTwo = useMemo(() => {
		if (levelTwoIndex === undefined) {
			return false;
		}

		const hasSubnodes = Boolean(data?.[levelOneIndex]?.subNodes?.[levelTwoIndex]?.subNodes?.length);

		if (!hasSubnodes) {
			return false;
		}

		return true;
	}, [levelOneIndex, levelTwoIndex, data]);

	return (
		<HStack
			alignItems="stretch"
			maxH="530px"
			boxSizing="content-box"
			padding={{
				base: '24px 0px',
			}}
			{...rest}
		>
			<NavigationColumnContainer showDelimiter={showLevelOne}>
				{data?.map((item, i) => {
					if (item.subNodes?.length === 0) {
						return (
							<NavigationLeafLink key={item.name} href={item.url} isActive={paths.includes(item?.urlSegment)}>
								{item.name}
							</NavigationLeafLink>
						);
					}

					return (
						<NavigationLink
							key={item.name}
							isActive={paths[0] === item.urlSegment || levelOneIndex === i}
							width="100%"
							size={{ base: 'large', sm: 'small', md: 'medium', lg: 'large' }}
							label={item.name}
							description={item.title}
							onClick={() => setLinkStack([i])}
						/>
					);
				})}
			</NavigationColumnContainer>
			{showLevelOne ? (
				<NavigationColumnContainer showDelimiter={showLevelTwo}>
					<NavigationLink
						as="a"
						width="100%"
						orientation="none"
						isActive={paths[0] === data?.[levelOneIndex].urlSegment && paths[1] === undefined}
						href={data?.[levelOneIndex].url || ''}
						label={data?.[levelOneIndex].name || ''}
						description={data?.[levelOneIndex].title || ''}
						size={{ base: 'large', sm: 'small', md: 'medium' }}
					/>
					{data?.[levelOneIndex].subNodes?.map((item, i) => {
						if (item.subNodes?.length === 0) {
							return (
								<NavigationLeafLink key={item.name} href={item.url} isActive={paths.includes(item?.urlSegment)}>
									{item.name}
								</NavigationLeafLink>
							);
						}

						return (
							<NavigationLink
								key={item.name}
								isActive={paths[1] === item.urlSegment || levelTwoIndex === i}
								width="100%"
								size={{ base: 'large', sm: 'small', md: 'medium' }}
								label={item.name}
								description={item.title}
								onClick={() => setLinkStack([linkStack[0], i])}
							/>
						);
					})}
				</NavigationColumnContainer>
			) : null}
			{showLevelTwo ? (
				<NavigationColumnContainer>
					<NavigationLink
						as="a"
						width="100%"
						orientation="none"
						size={{ base: 'large', sm: 'small', md: 'medium' }}
						label={data?.[levelOneIndex].subNodes?.[levelTwoIndex].name || ''}
						description={data?.[levelOneIndex].subNodes?.[levelTwoIndex].title || ''}
						href={data?.[levelOneIndex].subNodes?.[levelTwoIndex].url || ''}
					/>
					{data?.[levelOneIndex].subNodes?.[levelTwoIndex]?.subNodes?.map((item) => {
						return (
							<NavigationLeafLink key={item.name} href={item.url} isActive={paths.includes(item?.urlSegment)}>
								{item.name}
							</NavigationLeafLink>
						);
					})}
				</NavigationColumnContainer>
			) : null}
			{!showLevelOne ? <NavigationWelcome data={claim} /> : null}
			{showLevelOne && !showLevelTwo ? <Box flex="1" /> : null}
		</HStack>
	);
};
