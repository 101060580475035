'use client';

import type { ISelectOptions, ISelectProps } from '@/components/core/Select/Select';
import { Select } from '@/components/core/Select';
import { changeMarketToNewCountry } from '@/components/shared/language/MarketSelect.utils';
import { Tracked } from '@/components/shared/tracking/Tracked';
import { useCulture } from '@/hooks/useCulture';
import { ITranslatableComponentProps } from '@/interfaces/ITranslatableComponentProps';
import { IPageLanguage } from '@/interfaces/coreInformation';

import { translate } from '@/utils/i18n/translation-labels/translationLabels';
import { ILanguageSettings, compareCountries, splitLocale } from '@/utils/language';
import { SelectValueChangeDetails, createListCollection } from '@ark-ui/react';
import { useRef } from 'react';

interface IMarketSelectProps extends Omit<ISelectProps, 'collection'>, ITranslatableComponentProps {
	forceCulture?: string;
	languageSettings: ILanguageSettings;
	existingLanguages: Array<IPageLanguage>;
}

export const MarketSelect = ({
	translations,
	forceCulture,
	existingLanguages,
	languageSettings,
	...rest
}: IMarketSelectProps) => {
	const cultureInstance = useCulture({ existingLanguages, savePreferredLanguage: true });
	const { changeCulture, createCultureUrl, resetCulture, ...restCulture } = cultureInstance;

	const { language, country } = forceCulture ? splitLocale(forceCulture) : restCulture;
	const trackingRef = useRef<HTMLButtonElement>(null);

	if (!languageSettings?.availableSiteCultures) {
		return null;
	}

	const countriesData = createListCollection({
		items: Object.keys(languageSettings.availableSiteCultures).map((culture) => {
			return {
				value: culture.toLocaleLowerCase(),
				label: translate(translations, `country.${culture.toLocaleLowerCase()}`),
			};
		}),
	});

	const defaultCountry = countriesData.items?.find((item) => {
		return compareCountries(item.value, country);
	});

	const onCountryChange = (details: SelectValueChangeDetails<ISelectOptions>) => {
		if (details) {
			changeMarketToNewCountry(
				details.value.toString(),
				cultureInstance,
				language,
				country,
				existingLanguages,
				languageSettings,
				trackingRef
			);
		}
	};

	return (
		<Tracked trackingElementRef={trackingRef} trackingInformation={{ change: {} }}>
			<Select
				disabled={countriesData.size <= 1}
				collection={countriesData}
				defaultValue={defaultCountry?.value ? [defaultCountry.value] : undefined}
				onValueChange={onCountryChange}
				{...rest}
				trackingElementRef={trackingRef}
			/>
		</Tracked>
	);
};
