/**
 * @packageDocumentation
 * @module translationLabels_utils
 */

import { cmsHost } from '@/constants/global';
import { ITranslationInstance } from '@/types/translationLabels';

type ILocalizationApiResult = {
	culture: string;
	translations: TranslationLabelValues;
}[];

export type TranslationLabelValues = { [key: string]: string };

async function fetchLabelValues(locale: string): Promise<TranslationLabelValues> {
	const url = cmsHost + '/api/localization/GetByCulture/' + locale;
	const result = await fetch(url, {
		next: {
			revalidate: 4 * 60 * 60, // 4 hours
		},
	});

	if (!result.ok) {
		return {};
	}

	const translations: ILocalizationApiResult = (await result.json()) satisfies ILocalizationApiResult;

	return translations[0]?.translations ?? {};
}

/**
 * This function works server-side only.
 *
 * Fetches information on Label values from the Translation API. The Values are cached for 4 hours until they are requested again.
 * @param locale Language and country to get te translation, e.g. en-ch
 * @returns Contains the translation function t which will be used to get translation values.
 */
export const getTranslationInstance = async (
	locale: string,
	namespaces?: Array<string>
): Promise<ITranslationInstance> => {
	const translationLabelValues = await fetchLabelValues(locale);

	const namespaceTranslations: TranslationLabelValues = {};

	Object.keys(translationLabelValues).forEach((key) => {
		if (namespaces?.some((namespace) => key.startsWith(namespace))) {
			namespaceTranslations[key] = translationLabelValues[key];
		}
	});

	return {
		t: (key: string, fallback?: string | undefined): string => translationLabelValues[key] ?? fallback ?? key,
		namespaceTranslations,
	};
};

/**
 * Translates a key into a value. If the key is not found, the fallback value is returned.
 * @param translations Contains the translation key-value pairs
 * @param key The key to translate
 * @param fallback The fallback value to use if the key is not found
 * @returns The translated value
 */
export const translate = (translations: TranslationLabelValues, key: string, fallback?: string | undefined): string => {
	return translations[key] ?? fallback ?? key;
};

export const translateInterpolation = (
	translations: TranslationLabelValues,
	key: string,
	data: { count: number } & Record<string, number | string>,
	fallback?: string | undefined
): string => {
	const translation = translations[key] ?? fallback ?? key;

	return Object.keys(data).reduce((acc, key) => {
		return acc.replace(`{{${key}}}`, data[key].toString());
	}, translation);
};

export const translatePlural = (
	translations: TranslationLabelValues,
	_language: string,
	key: string,
	data: { count: number } & Record<string, number | string>,
	fallback?: string | undefined
): string => {
	return translateInterpolation(translations, key, data, fallback);
};
