import { FC, SVGAttributes } from 'react';

export const BadgeIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect x="2" y="2" width="20" height="20" rx="10" fill="#009A17" />
			<path d="M10.5441 15.5802L17.8215 8.3029L17.1485 7.62988L9.87107 14.9072L6.78093 11.817L6.10791 12.4901L9.87107 16.2532L10.5441 15.5802Z" />
		</svg>
	);
};
