'use client';

import {
	areArticleFiltersApplied,
	objToQueryParams,
} from '@/components/block-templates/ArticleFilterBlock/components/ArticleFilterBlock.utils';
import { Button } from '@/components/core/Button/Button';
import { getArticleTeaserProps } from '@/components/shared/ArticleTeaser/ArticleTeaser.utils';
import { ArticleTeaser } from '@/components/shared/ArticleTeaser/ArticleTeaser';
import { FC } from '@/interfaces/blocks/shared';
import { css } from '@/styled-system/css';
import { Flex, Grid, VStack } from '@/styled-system/jsx';
import { fetcher } from '@/utils/fetcher';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import useSWRInfinite from 'swr/infinite';

import { ArticleSkeleton } from '@/components/block-templates/ArticleFilterBlock/components/ArticleFilterSkeletons';
import { ArticleFilterTags } from '@/components/block-templates/ArticleFilterBlock/components/ArticleFilterTags';
import { FilterCount } from '@/components/shared/filters/FilterCount';
import { FilterEmptyState } from '@/components/shared/filters/FilterEmptyState';
import useQueryParams from '@/hooks/useQueryParams';
import { ArticleFilterResponse, ArticleResult } from '@/types/article-filter';
import { ICloudinaryEnvironmentVariables } from '@/utils/cloudinary';
import last from 'lodash/last';

interface IArticleFilterResultsProps {
	articleTranslations: TranslationLabelValues;
	timezoneTranslations: TranslationLabelValues;
	filterData: Record<string, any>;
	pageSize: number;
	cloudinaryOverrides?: ICloudinaryEnvironmentVariables;
}

const getKey = (pageIndex: number, previousPageData: ArticleFilterResponse, searchParams: string) => {
	if (previousPageData?.remainingFilteredCount && previousPageData?.remainingFilteredCount === 0) {
		return null;
	} // reached the end

	return `/api/articles?pageIndex=${pageIndex || 0}&${searchParams}`;
};

export const ArticleFilterResults: FC<IArticleFilterResultsProps> = ({
	articleTranslations,
	timezoneTranslations,
	pageSize,
	filterData,
	cloudinaryOverrides,
	...rest
}) => {
	const { query } = useQueryParams();

	const filterDataString = new URLSearchParams(filterData).toString();

	const {
		data: articlePaginatedResponse,
		isLoading,
		size,
		setSize,
	} = useSWRInfinite(
		(pageIndex, previousPageData) => {
			return getKey(pageIndex, previousPageData, `${filterDataString}&${objToQueryParams(query)}`);
		},
		(url: string) => fetcher(url).then((res) => res.ok && res.json()),
		{ revalidateOnFocus: false, revalidateFirstPage: false, revalidateAll: false }
	);

	const onLoadMoreClick = () => {
		setSize(size + 1);
	};

	const articleResponse = (() => {
		const data =
			articlePaginatedResponse?.reduce((acc, iter) => {
				return [...acc, ...iter.data];
			}, []) || [];

		const lastResponse = last(articlePaginatedResponse);

		return { ...lastResponse, data } as ArticleFilterResponse;
	})();

	return (
		<VStack w="100%" {...rest}>
			<Flex
				w="100%"
				display={areArticleFiltersApplied(query) ? 'flex' : 'none'}
				flexDirection={{ base: 'column', md: 'row' }}
				justifyContent="space-between"
				alignItems="baseline"
				mt={3}
				gap={2}
			>
				<ArticleFilterTags filters={articleResponse?.filterBarOptions} />
				<FilterCount
					count={articleResponse?.totalFilteredCount || 0}
					label={translate(articleTranslations, 'article.filter.resultsCountLabel')}
				/>
			</Flex>

			<Grid
				w="100%"
				gap={{ base: 6, md: 4, lg: 8 }}
				mt={{ base: 4, md: 8 }}
				className={css({
					gridRowGap: '32px',
					gridTemplateColumns: 'repeat(1, auto)',

					md: {
						gridTemplateColumns: 'repeat(2, 1fr)',
					},
					lg: {
						gridTemplateColumns: 'repeat(3, 1fr)',
					},
					_print: {
						gridTemplateColumns: 'repeat(3, 1fr)',
					},
				})}
			>
				{isLoading
					? Array.from(Array(pageSize).keys()).map((i) => {
							return <ArticleSkeleton key={i} />;
						})
					: articleResponse?.data?.map((article: ArticleResult) => {
							return (
								<ArticleTeaser
									key={article.id}
									{...getArticleTeaserProps(article, articleTranslations, timezoneTranslations, cloudinaryOverrides)}
								/>
							);
						})}
			</Grid>
			{articleResponse.data?.length === 0 && !isLoading ? (
				<FilterEmptyState
					title={translate(articleTranslations, 'article.filter.empty.title')}
					body={translate(articleTranslations, 'article.filter.empty.text')}
				/>
			) : null}
			{articleResponse?.remainingFilteredCount > 0 ? (
				<Button mt={8} variant="secondary" onClick={onLoadMoreClick}>
					{translate(articleTranslations, 'article.filter.loadMore')}
				</Button>
			) : null}
		</VStack>
	);
};
