'use client';

import { sendTealiumTrackingEvent } from '@/utils/tracking/tracking';
import { isMobile, isTablet } from 'react-device-detect';
import { useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { DeviceType } from '@/enums/DeviceType';

export const ScrollTracker: React.FC = () => {
	const alreadyTracked: { [percent: string]: boolean } = useMemo(
		() => ({
			'20': false,
			'40': false,
			'60': false,
			'80': false,
			'100': false,
		}),
		[]
	);

	const handleScroll = (event: Event) => {
		const percent = calculateScrollPercent();

		for (let i = 20; i <= 100; i += 20) {
			if (percent < i || percent >= i + 20) {
				continue;
			}

			if (alreadyTracked[String(i)]) {
				break;
			}

			// Send all events for lower scroll values, that have not yet been sent.
			// It's most important that the later values are not missed, but tracking lower values
			// poses no disadvantage
			for (let j = 20; j <= i; j += 20) {
				if (alreadyTracked[String(j)]) {
					continue;
				}
				let conversionId: string | undefined = undefined;

				if (j === 80) {
					conversionId = 'vt-scroll-80';
				}

				alreadyTracked[String(j)] = true;
				sendScrollingTrackingEvent(event, j, conversionId);
			}

			break;
		}
	};

	const debouncedScrollEvent = debounce(handleScroll, 300);

	useEffect(() => {
		if (!document) {
			return;
		}

		window.addEventListener('scroll', debouncedScrollEvent, true);

		return function cleanup() {
			window.removeEventListener('scroll', debouncedScrollEvent, {
				capture: true,
			});
		};
	}, []);

	return <></>;
};

const calculateScrollPercent = (): number => {
	const body = document.body;
	const html = document.documentElement;
	const windowHeight = Math.max(
		body.scrollHeight,
		body.offsetHeight,
		html.clientHeight,
		html.scrollHeight,
		html.offsetHeight
	);
	const currentPosition = html.scrollTop;
	const windowViewingArea = window.innerHeight;
	const bottomSrollPosition = currentPosition + windowViewingArea;
	const percentScrolled = parseInt(((bottomSrollPosition / windowHeight) * 100).toFixed(0));

	return percentScrolled;
};

const sendScrollingTrackingEvent = (
	originalEvent: Event,
	percent: number,
	conversionId: string | undefined = undefined
): void => {
	let deviceType: DeviceType = DeviceType.Desktop;

	if (isMobile) {
		deviceType = DeviceType.Mobile;
	} else if (isTablet) {
		deviceType = DeviceType.Tablet;
	}

	sendTealiumTrackingEvent(
		{
			event: {
				action: String(percent),
				label: deviceType,
				techCategory: 'scroll',
				category: 'scroll',
				nonInteraction: false,
				...(conversionId && { conversionId: conversionId }),
			},
			levels: [],
			nestingLevel: 0,
		},
		document.documentElement,
		originalEvent
	);
};
