import { Icon, IconPosition } from '@/enums/Icon';
import { IBlockContent } from '@/interfaces/coreInformation';

export enum ButtonType {
	PRIMARY = 'Primary',
	SECONDARY = 'Secondary',
	TERTIARY = 'Tertiary',
	TRANSPARENT = 'Transparent',
	PLAIN = 'Plain',
	PRIMARY_DANGER = 'PrimaryDanger',
	SECONDARY_DANGER = 'SecondaryDanger',
}

export interface IHeaderButton extends IBlockContent {
	buttonType: ButtonType;
	displayText: string;
	icon: Icon;
	iconPosition: IconPosition;
	link: string;
	linkClickBehaviour: 'SameWindow' | 'NewWindow';
	title: string;
}
