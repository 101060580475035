import { useLayoutEffect, useState } from 'react';

const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

	const handleSize = () => {
		setWindowSize({
			width: window.innerWidth as number,
			height: window.innerHeight as number,
		});
	};

	useLayoutEffect(() => {
		handleSize();

		window.addEventListener('resize', handleSize);

		return () => window.removeEventListener('resize', handleSize);
	}, []);

	return windowSize;
};

export default useWindowSize;
