import { ILinkListBlockTemplateProps } from '@/components/block-templates/LinkListBlockTemplate/LinkListBlockTemplate';
import { NavigationLink } from '@/components/core/NavigationLink/NavigationLink';
import { NavigationColumnContainer } from '@/components/shared/Navigation/components/NavigationColumnContainer';
import { NavigationLeafLink } from '@/components/shared/Navigation/components/NavigationLeafLink';
import { IBlockResponse } from '@/interfaces/coreInformation';
import { HStack, HstackProps } from '@/styled-system/jsx';
import { logger } from '@/utils/logger';
import { FC, useState } from 'react';

interface INavigationContentProps extends HstackProps {
	data: Array<IBlockResponse<ILinkListBlockTemplateProps>>;
}

export const LoginNavigationContentMobile: FC<INavigationContentProps> = ({ data, ...rest }) => {
	const [activeListIndex, setActiveListIndex] = useState<number>();

	return (
		<HStack
			alignItems="flex-start"
			padding={{
				base: '24px 0px',
				lg: '28px 0px 45px 0px',
				xl: '44px 0px 196px 0px',
			}}
			{...rest}
		>
			<NavigationColumnContainer showDelimiter={false} gap={activeListIndex !== undefined ? 6 : 8}>
				{activeListIndex !== undefined && (
					<NavigationLink
						width="100%"
						size="large"
						label={data[activeListIndex].contentLink?.expanded?.headline || ''}
						orientation="left"
						borderColor="actions.links.regular.default"
						mb={3}
						onClick={(e: Event) => {
							e.stopPropagation();
							e.preventDefault();
							setActiveListIndex(undefined);
						}}
					/>
				)}

				{activeListIndex === undefined &&
					data?.map((item, i) => {
						const label = item.contentLink?.expanded?.headline;
						const items = item.contentLink?.expanded?.items;

						if (!items?.length) {
							logger.warn(`Login item ${label} has no items.`);

							return null;
						}

						return (
							<NavigationLink
								key={item.contentLink?.id}
								width="100%"
								size="large"
								label={label || ''}
								onClick={(e: Event) => {
									e.stopPropagation();
									e.preventDefault();
									setActiveListIndex(i);
								}}
							/>
						);
					})}
				{activeListIndex !== undefined &&
					data[activeListIndex].contentLink?.expanded?.items?.map((item) => {
						return (
							<NavigationLeafLink
								color="actions.links.regular.default"
								borderColor="actions.links.regular.default"
								key={item.contentLink?.id}
								href={item.contentLink?.expanded?.link}
								mb="0px"
							>
								{item.contentLink?.expanded?.displayText}
							</NavigationLeafLink>
						);
					})}
			</NavigationColumnContainer>
		</HStack>
	);
};
