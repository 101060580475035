import type { SVGAttributes } from 'react';

export const SpinnerIconAlternative = (props: SVGAttributes<SVGElement>) => (
	<svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx="32" cy="32" r="19.531" stroke="currentColor" stroke-width="3.906" />
		<path
			d="M32 51.531c10.787 0 19.531-8.744 19.531-19.53 0-5.06-1.923-9.67-5.08-13.14"
			stroke="icons.regular.default"
			stroke-width="3.906"
		/>
	</svg>
);

export const SpinnerIcon = (props: SVGAttributes<SVGElement>) => (
	<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M10 3.5A6.5 6.5 0 0 0 3.5 10 .75.75 0 0 1 2 10a8 8 0 1 1 8 8 .75.75 0 0 1 0-1.5 6.5 6.5 0 1 0 0-13Z"
			fill="currentColor"
			fillRule="nonzero"
		/>
	</svg>
);
