'use client';

import dynamic from 'next/dynamic';
import { FC, ReactNode } from 'react';

const NoSSRComp: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>;

export const NoSSR = dynamic(() => Promise.resolve(NoSSRComp), {
	ssr: false,
});
