import type { FC, SVGAttributes } from 'react';

export const ChevronRightIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	const { width = '24px', height = '24px', ...rest } = props;

	return (
		<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...rest}>
			<path d="m12.48 26.5-1.5-1.5 9.21-9-9.21-9 1.5-1.5L23.02 16 12.48 26.5Z" fill="#373A36" />
		</svg>
	);
};
