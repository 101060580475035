'use client';
import { FC, useContext, useState } from 'react';

import { NavigationFooter } from '@/components/shared/Navigation/components/NavigationFooter';
import { NavigationHeader } from '@/components/shared/Navigation/components/NavigationHeader';

import { ILinkBlockTemplateProps } from '@/components/block-templates/LinkBlockTemplate/LinkBlockTemplate';
import { ContentContainer } from '@/components/shared/ContentContainer/ContentContainer';
import { HamburgerButton } from '@/components/shared/Header/components/HamburgerButton/HamburgerButton';
import { NavigationItem } from '@/components/shared/Navigation/Navigation.utils';
import { NavigationContentDesktop } from '@/components/shared/Navigation/components/NavigationContent.desktop';
import { NavigationContentMobile } from '@/components/shared/Navigation/components/NavigationContent.mobile';
import { NavigationEvolution } from '@/components/shared/Navigation/components/NavigationEvolution';
import { ITranslatableComponentProps } from '@/interfaces/ITranslatableComponentProps';
import { IBlockResponse, IPageLanguage } from '@/interfaces/coreInformation';
import { VStack } from '@/styled-system/jsx';
import { navigation, scrollbar } from '@/styled-system/recipes';
import { ILanguageSettings } from '@/utils/language';
import { Dialog, Portal } from '@ark-ui/react';
import { usePathname } from 'next/navigation';
import { HeaderContext } from '@/components/shared/Header/components/HeaderProvider/HeaderProvider';
import { useLoginNavigationContext } from '@/components/shared/LoginNavigation/LoginNavigationProvider';

interface IDialogProps extends ITranslatableComponentProps {
	onClose?: () => void;
	forceCulture?: string;
	navigationItems?: NavigationItem[];
	mainNavigationQuickLinks?: IBlockResponse<ILinkBlockTemplateProps>[];
	mainNavigationHomeClaim?: string;
	languageSettings: ILanguageSettings;
	existingLanguages: Array<IPageLanguage>;
	displayEvolutionSystem?: boolean;
}

export const Navigation: FC<IDialogProps> = ({
	navigationItems,
	mainNavigationQuickLinks,
	mainNavigationHomeClaim,
	forceCulture,
	languageSettings,
	existingLanguages,
	translations,
	displayEvolutionSystem = false,
}) => {
	const classes = navigation();
	const pathname = usePathname();
	const [isOpen, setIsOpen] = useState(false);
	const { isLoginOpen } = useLoginNavigationContext();
	const { headerRef } = useContext(HeaderContext);
	const headerHeight = headerRef?.current?.clientHeight || 0;

	if (!navigationItems?.length) {
		return null;
	}

	return (
		<Dialog.Root
			open={isOpen}
			onOpenChange={(e) => setIsOpen(e.open)}
			// Ark-UI Dialog has an issue where clicking on navigation links is mistakenly treated as an outside click.
			// To prevent the dialog from closing when a navigation link is clicked, we need to check if the click occurred inside the dialog (not on the HTML element).
			// If the click is outside (on the HTML tag), we prevent the default behavior to avoid unintended closing.
			// This is a temporary workaround until the issue is fixed in Ark-UI.
			onInteractOutside={(event) => {
				const rootElement = event.detail.originalEvent.target;

				if (rootElement instanceof HTMLElement && rootElement.tagName !== 'HTML') {
					event.preventDefault();
				}
			}}
			lazyMount
			unmountOnExit
		>
			<HamburgerButton isOpen={isOpen || isLoginOpen} onClick={() => setIsOpen(true)} />

			<Portal>
				<Dialog.Backdrop className={classes.backdrop} style={{ top: `${headerHeight}px` }} />
				<Dialog.Positioner
					className={classes.positioner}
					style={{ top: `${headerHeight}px`, height: isOpen ? `calc(100% - ${headerHeight}px)` : 'min-content' }}
					data-state={isOpen ? 'open' : 'closed'}
				>
					<ContentContainer overflowY="auto" className={scrollbar({ variant: 'hidden' })}>
						<Dialog.Content className={classes.content}>
							<VStack alignItems="flex-left" h="full">
								<NavigationHeader
									forceCulture={forceCulture}
									languageSettings={languageSettings}
									existingLanguages={existingLanguages}
									translations={translations}
								/>
								<NavigationContentDesktop
									pathname={pathname}
									data={navigationItems}
									claim={mainNavigationHomeClaim ?? ''}
									display={{ base: 'none', md: 'flex' }}
								/>
								<NavigationContentMobile
									pathname={pathname}
									data={navigationItems}
									display={{ base: 'flex', md: 'none' }}
									translations={translations}
								/>
								<NavigationFooter data={mainNavigationQuickLinks} />
							</VStack>
						</Dialog.Content>
					</ContentContainer>
					{displayEvolutionSystem && <NavigationEvolution />}
				</Dialog.Positioner>
			</Portal>
		</Dialog.Root>
	);
};
