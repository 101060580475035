import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const navigationlinkFn = /* @__PURE__ */ createRecipe('navigationLink', {
  "orientation": "right",
  "size": "large"
}, [])

const navigationlinkVariantMap = {
  "orientation": [
    "none",
    "right",
    "left"
  ],
  "size": [
    "large",
    "medium",
    "small"
  ]
}

const navigationlinkVariantKeys = Object.keys(navigationlinkVariantMap)

export const navigationlink = /* @__PURE__ */ Object.assign(memo(navigationlinkFn.recipeFn), {
  __recipe__: true,
  __name__: 'navigationlink',
  __getCompoundVariantCss__: navigationlinkFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: navigationlinkVariantKeys,
  variantMap: navigationlinkVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, navigationlinkVariantKeys)
  },
  getVariantProps: navigationlinkFn.getVariantProps,
})