import { BoxProps, Container } from '@/styled-system/jsx';

interface IContentContainerProps extends BoxProps {
	providePadding?: boolean;
}

export const ContentContainer = ({ providePadding = true, ...rest }: IContentContainerProps) => {
	return (
		<Container
			w="full"
			maxW="var(--content-max-width)"
			marginX="auto"
			boxSizing="border-box"
			paddingX={{ base: providePadding ? 8 : 0, xl: 0 }}
			{...rest}
		/>
	);
};
