import { sva } from '@/styled-system/css';
import { Dialog } from '@ark-ui/react';

interface IHamburgerButtonProps {
	onClick: () => void;
	isOpen: boolean;
}

const styles = sva({
	slots: ['root', 'bar'],
	base: {
		root: {
			position: 'relative',
			cursor: 'pointer',
			p: '0.5rem 0.25rem',
			h: 8,
			w: 8,
			_print: { display: 'none' },
		},
		bar: {
			h: '2px',
			w: 6,
			bg: 'icons.regular.default',
			display: 'block',
			m: '4px auto',
			position: 'relative',
			borderRadius: 2,
			transitionDuration: '300ms',

			'&:first-of-type': {
				mt: 0,
			},
			'&:nth-of-type(2)': {
				opacity: 1,
			},
			'&:last-of-type': {
				mb: 0,
			},
		},
	},
	variants: {
		open: {
			true: {
				bar: {
					'&:nth-of-type(1)': {
						transform: 'translateY(6px) rotate(45deg)',
					},
					'&:nth-of-type(2)': {
						opacity: 0,
					},
					'&:nth-of-type(3)': {
						transform: 'translateY(-6px) rotate(-45deg)',
					},
				},
			},
		},
	},
});

export const HamburgerButton = ({ onClick, isOpen }: IHamburgerButtonProps) => {
	const classes = styles({ open: isOpen });

	return (
		<>
			<Dialog.Trigger className={classes.root} onClick={onClick} title="Navigation" aria-label="Navigation">
				<div className={classes.bar}></div>
				<div className={classes.bar}></div>
				<div className={classes.bar}></div>
			</Dialog.Trigger>
		</>
	);
};
