import { FC, SVGAttributes } from 'react';

export const TimeIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<circle r="12" transform="matrix(-1 0 0 1 16 16)" fill="white" />
			<path
				d="M16 4.06349C9.40698 4.06349 4.06349 9.40698 4.06349 16C4.06349 22.593 9.40698 27.9365 16 27.9365C22.593 27.9365 27.9365 22.593 27.9365 16C27.9365 9.40698 22.593 4.06349 16 4.06349ZM16 26.4127C10.2603 26.4127 5.5873 21.7397 5.5873 16C5.5873 10.2603 10.2603 5.5873 16 5.5873C21.7397 5.5873 26.4127 10.2603 26.4127 16C26.4127 21.7397 21.7397 26.4127 16 26.4127Z"
				fill="currentColor"
			/>
			<path
				d="M22.5879 15.2381H16.7619V7.87302C16.7619 7.45143 16.4216 7.11111 16 7.11111C15.5784 7.11111 15.2381 7.45143 15.2381 7.87302V16C15.2381 16.4216 15.5784 16.7619 16 16.7619H22.5879C23.0095 16.7619 23.3498 16.4216 23.3498 16C23.3498 15.5784 23.0095 15.2381 22.5879 15.2381Z"
				fill="currentColor"
			/>
		</svg>
	);
};
