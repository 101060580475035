import { sva } from '@/styled-system/css';

export const textarea = sva({
	slots: ['root', 'textareaWrapper', 'label', 'textarea', 'clearButton', 'textareaFooter', 'helperText', 'error'],
	variants: {
		disabled: {
			true: {
				textareaWrapper: {
					color: 'text.regular.disabled',
					bg: 'surface.interactive.transparent.regular.disabled',
					borderColor: 'other.border.regular.disabled',
				},
				textarea: {
					color: 'text.regular.disabled',
					bg: 'surface.interactive.transparent.regular.disabled',
				},
			},
		},
		readOnly: {
			true: {
				textareaWrapper: {
					color: 'text.regular.subtitle',
					bg: 'surface.interactive.transparent.regular.disabled',
					borderColor: 'other.border.regular.disabled',

					_focusWithin: {
						borderColor: 'other.border.regular.disabled',
					},
				},
				textarea: {
					color: 'text.regular.subtitle',
					bg: 'surface.interactive.transparent.regular.disabled',
				},
			},
		},
		isError: {
			true: {
				textareaWrapper: {
					borderColor: 'trafficLights.error.default',

					_focusWithin: {
						borderColor: 'trafficLights.error.default',
					},
				},
				helperText: {
					color: 'text.regular.danger',
				},
				error: {
					color: 'text.regular.danger',
				},
			},
		},
		hidden: {
			true: {
				root: {
					display: 'none',
				},
			},
		},
	},
	base: {
		root: {
			w: 'full',
		},
		label: {
			fontSize: '0.75rem',
			fontWeight: 'semibold',
			color: 'text.regular.default',
		},
		textareaWrapper: {
			position: 'relative',
			display: 'flex',
			alignItems: 'flex-start',
			w: 'full',
			borderWidth: 1,
			borderColor: 'other.border.regular.default',
			borderRadius: 6,
			p: 1.5,
			pl: 3,

			_focusWithin: {
				borderColor: 'other.border.regular.focus',
			},
		},
		textarea: {
			w: 'full',
			minH: 140,
			fontSize: '1rem',
			outline: 0,
			border: '1px solid transparent',
			my: 0.5,

			_placeholder: {
				color: 'text.regular.subtitle',
			},
		},
		clearButton: {
			position: 'absolute',
			top: 1,
			right: 1,
			w: 4,
			h: 4,
			minW: 4,
			minH: 4,
			bg: 'icons.regular.default',
			border: 0,
			m: 1,
		},
		textareaFooter: {
			display: 'flex',
			alignItems: 'center',
			mt: 2,
			gap: 1,
		},
	},
});
