'use client';

import { PageContext } from '@/utils/pageContextProvider';
import { SVGAttributes, useContext } from 'react';

export const WarningIcon = (props: SVGAttributes<SVGElement>) => {
	const { siteName } = useContext(PageContext);

	if (siteName === 'vosti') {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16.7068 16.0036L25.9018 25.1986L25.1947 25.9057L15.9997 16.7107L6.80476 25.9057L6.09766 25.1986L15.2926 16.0036L6.09766 6.80867L6.80476 6.10156L15.9997 15.2965L25.1947 6.10156L25.9018 6.80867L16.7068 16.0036Z"
					fill="currentColor"
				/>
			</svg>
		);
	}

	return (
		<svg viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12 0.65332L0.0634766 21.4127H23.9365L12 0.65332ZM12 3.60951L21.3409 19.8533H2.65903L12 3.60951Z"
				fill="currentColor"
			/>
			<path d="M12.4469 15.1753L13.2749 8.73462H10.8622L11.6901 15.1753H12.4469Z" fill="currentColor" />
			<path
				d="M12.0711 15.7441C11.4514 15.7441 10.9841 16.2064 10.9841 16.8413C10.9841 17.4762 11.4464 17.9384 12.0711 17.9384C12.6959 17.9384 13.1581 17.4762 13.1581 16.8413C13.1581 16.2064 12.6959 15.7441 12.0711 15.7441Z"
				fill="currentColor"
			/>
		</svg>
	);
};
