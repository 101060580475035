import { getArticlesUrl } from '@/constants/global';
import { ArticleFilterResponse } from '@/types/article-filter';
import { fetcher } from '@/utils/fetcher';

// convert object to query params
export const convertRecordToQueryParams = (queryObj: URLSearchParams) => {
	const queryParamString: Record<string, string> = {};

	for (const [key, value] of queryObj) {
		if (value === 'undefined' || value === 'null') {
			continue;
		}

		if (key === 'search') {
			queryParamString['text'] = value;
			continue;
		}

		if (['category', 'year', 'author', 'text'].includes(key)) {
			queryParamString[`filter[${key}]`] = value;
			continue;
		}

		queryParamString[key] = value;
	}

	return decodeURIComponent(new URLSearchParams(queryParamString).toString());
};

export const objToQueryParams = (query: Record<string, any>): string => {
	const urlParams = new URLSearchParams();
	const sanitizedQuery = sainitizeQueryParam(query);

	for (const key in sanitizedQuery) {
		// eslint-disable-next-line no-prototype-builtins
		if (sanitizedQuery.hasOwnProperty(key)) {
			const value = sanitizedQuery[key];

			urlParams.append(key, value.join(','));
		}
	}

	return decodeURIComponent(urlParams.toString());
};

export const sainitizeQueryParam = (searchParams: Record<string, any>) => {
	const params: Record<string, any> = {};

	Object.keys(searchParams).forEach((key: string) => {
		const value = searchParams[key];

		if (key !== 'search' || (key === 'search' && value[0]?.length >= 3)) {
			params[key] = value;
		}
	});

	return params;
};

export const getArticles = async (params: URLSearchParams): Promise<ArticleFilterResponse | undefined> => {
	const culture = params.get('language');
	let baseUrl = getArticlesUrl + (culture ? `/${culture}` : '/');

	// Remove language from params it is already in the baseUrl
	params.delete('language');
	baseUrl += `?${convertRecordToQueryParams(params)}`;

	const articlesResults = await fetcher(baseUrl);

	if (!articlesResults.ok) {
		console.error(`Failed to fetch articles. Status `, articlesResults.status);

		return undefined;
	}

	const articles = await articlesResults.json();

	return articles;
};

export const areArticleFiltersApplied = (query: Record<string, string[]>) => {
	return Boolean(query?.search || query?.category || query?.year);
};

export const possibleVisibilitySettings = [
	'ShowArticleFilterBar',
	'ShowSearchInput',
	'ShowCategoryTopics',
	'ShowCategoryInvestorType',
	'ShowCategoryYear',
];
