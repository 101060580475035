'use client';

import { ComponentProps, FC } from 'react';
import { styled } from 'styled-system/jsx';
import { ClearIcon } from '@/icons/ClearIcon';
import { poly } from '@/libs/poly';

const ButtonContainer = styled(poly('button'));

export const TagsCloseButton: FC<Omit<ComponentProps<typeof ButtonContainer>, 'css'>> = ({ children, ...rest }) => {
	return (
		<ButtonContainer aria-label="close" {...rest}>
			{children || <ClearIcon color="icons.inverted.default" width="16px" height="16px" />}
		</ButtonContainer>
	);
};
