'use client';

import { PageContext } from '@/utils/pageContextProvider';
import { FC, SVGAttributes, useContext } from 'react';

export const SendIconWithBackground: FC<SVGAttributes<SVGElement>> = (props) => {
	const { siteName } = useContext(PageContext);

	if (siteName === 'vosti') {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M26.1742 5.72974V25.2289C26.1742 25.505 25.9503 25.7289 25.6742 25.7289H6.17505V24.7289H24.4671L6.22361 6.48541L6.93072 5.7783L25.1742 24.0217V5.72974H26.1742Z"
					fill="currentColor"
				/>
			</svg>
		);
	}

	return (
		<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="m8.24878 25.3003 17.57462-8.386-.0254-1.8336L8.24878 6.69971l4.50542 8.60449v1.3917l-4.50542 8.6044Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.2318 15.3195 7.42608 4.91188c-.65524-.31492-1.33588.39111-.99556 1.03619L11.6978 16.0001 6.43052 26.0573c-.34032.6451.34032 1.3511.99556 1.0362L29.2318 16.6909c.574-.2743.574-1.092 0-1.3663v-.0051ZM8.82798 7.25855 25.5493 15.2382h-12.541L8.82798 7.25855ZM13.0083 16.757h12.5562L8.8229 24.7417l4.1854-7.9847Z"
				fill="#373A36"
			/>
		</svg>
	);
};
