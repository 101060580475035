import { IBlockContent } from '@/interfaces/coreInformation';

export interface NavigationItem {
	name: string;
	url: string;
	title: string;
	level: number;
	subNodes?: Array<NavigationItem>;
	urlSegment: string;
}

export interface INavigationSettings extends IBlockContent {
	navigation: Array<NavigationItem>;
}

export const isExternalLink = (url: string) => {
	return url.startsWith('http') || url.startsWith('https');
};

export const getPaths = (pathname = '') => {
	if (!pathname) {
		return [];
	}

	let processedPathname = pathname;

	if (pathname.endsWith('/')) {
		processedPathname = pathname.slice(0, -1);
	}

	const paths = processedPathname.split('/').slice(2);

	return paths;
};

export const findActiveNode = (
	data: Array<NavigationItem>,
	paths: Array<string>,
	result: Array<number> = []
): Array<number> => {
	let index;

	if (!data || data.length === 0) {
		return result;
	}

	if (paths.length === 0) {
		return [];
	}

	// Check if the current path is in the data
	const activeItem: NavigationItem | undefined = data?.find((item, i) => {
		if (item.urlSegment === paths[0]) {
			index = i;

			return true;
		}

		return false;
	});

	// If not, return the current result
	if (index === undefined) {
		return result;
	}

	if (activeItem?.subNodes?.length === 0) {
		return result;
	}

	if (paths.length === 1) {
		return [...result, index];
	}

	// If it does, check if there are any more paths to check
	return findActiveNode(data[index]?.subNodes || [], paths.slice(1), [...result, index]);
};

export const getActiveIndicesFromPathname = (pathname = '', data: Array<NavigationItem>) => {
	const paths = getPaths(pathname);

	return findActiveNode(data, paths);
};

export const getActiveStack = (pathname = '', data: Array<NavigationItem>) => {
	const paths = getPaths(pathname);

	return findActiveNode(data, paths);
};

// recursively get a node to show
export const getNavigationSubNodes = (
	linkStack: Array<number>,
	data: Array<NavigationItem>
): Array<NavigationItem> | NavigationItem => {
	// no active item? return the list of root nodes
	if (linkStack.length === 0) {
		return data;
	}

	// if there is only one item left - we reached the end of the chain. this is the active element
	if (data && linkStack.length === 1) {
		return data[linkStack[0]];
	}

	return getNavigationSubNodes(linkStack.slice(1), data?.[linkStack[0]]?.subNodes || []);
};
