'use client';

import { useRef } from 'react';
import { ITrackedProps, Tracked } from '@/components/shared/tracking/Tracked';
import { IPensionCalculatorAppProps, PensionCalculatorApp } from '@vontobel/pension-calculator';
import '@vontobel/pension-calculator/build/styles.css';

export interface IPensionCalculatorProps extends Omit<IPensionCalculatorAppProps, 'trackingElementRef'> {
	id: string;
	trackedProps: Partial<ITrackedProps>;
}

export const PensionCalculator = ({ trackedProps, id, ...appProps }: IPensionCalculatorProps) => {
	const trackingElementRef = useRef<HTMLDivElement>(null);

	return (
		<Tracked
			trackingElementRef={trackingElementRef}
			trackingInformation={{
				pcInteraction: {
					category: 'volt-3a-pension-calculator',
					techCategory: 'interactive-element',
					nonInteraction: false,
				},
			}}
			{...trackedProps}
		>
			<div id={id}>
				<PensionCalculatorApp trackingElementRef={trackingElementRef} {...appProps} />
			</div>
		</Tracked>
	);
};
