import type { SVGAttributes } from 'react';

export const TwitterIcon = (props: SVGAttributes<SVGElement>) => (
	<svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M17.9999 14.3931L25.8189 5.5H23.9667L17.1746 13.2202L11.7538 5.5H5.5L13.6991 17.1754L5.5 26.5H7.3522L14.5202 18.3455L20.2462 26.5H26.5M8.0207 6.86666H10.8662L23.9653 25.2005H21.1191"
			fill="currentColor"
		/>
	</svg>
);
