import type { SVGAttributes } from 'react';

export const ExternalIcon = (props: SVGAttributes<SVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" width="32" height="32" {...props}>
		<path
			fill="currentColor"
			d="M22.704 8 24 7.99v5.13h-1.296V10.14l-6.725 6.725-.811-.812L21.88 9.34h-2.967V8h3.79Z"
		/>
		<path fill="currentColor" d="M15.492 8.035H8.01V24h15.965v-6.985h-1.307v5.652H9.333V9.333h6.16V8.035Z" />
	</svg>
);
