import { styled } from '@/styled-system/jsx';
import { ICloudinaryEnvironmentVariables, getCloudinaryImageProps } from '@/utils/cloudinary';
import type { HTMLStyledProps } from '@/styled-system/jsx';

export interface ImageProps extends HTMLStyledProps<'img'> {
	src: string;
	alt: string;
	sizes?: string;
	cloudinaryOverrides?: ICloudinaryEnvironmentVariables;
}

export const Image = ({ src, alt, sizes = '100vw', cloudinaryOverrides, ...rest }: ImageProps) => {
	if (!src) {
		return;
	}

	const imgProps = getCloudinaryImageProps({ src, cloudinaryVariables: cloudinaryOverrides });

	return <styled.img loading="lazy" src={imgProps.imgSrc} alt={alt} srcSet={imgProps.srcSet} sizes={sizes} {...rest} />;
};
