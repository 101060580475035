export enum ContentType {
	AppBannerBlock = 'AppBannerBlock',
	AccordionBlock = 'AccordionBlock',
	AccordionItemBlock = 'AccordionItemBlock',
	ArticleData = 'ArticleData',
	ArticleFilterBlock = 'ArticleFilterBlock',
	ArticleFilterCriteriaBlock = 'ArticleFilterCriteriaBlock',
	ArticleFilterSettingsBlock = 'ArticleFilterSettingsBlock',
	ArticlePage = 'ArticlePage',
	ArticleListBlock = 'ArticleListBlock',
	CategoriesFolder = 'CategoriesFolder',
	Category = 'Category',
	CategoryRoot = 'CategoryRoot',
	CodeSnippetBlock = 'CodeSnippetBlock',
	CompanyBranchAddressBlock = 'CompanyBranchAddressBlock',
	CompanyBranchBlock = 'CompanyBranchBlock',
	CompanyBranchContactBlock = 'CompanyBranchContactBlock',
	CompanyDetailsBlock = 'CompanyDetailsBlock',
	ContentSearchBlock = 'ContentSearchBlock',
	ContentSummaryBlock = 'ContentSummaryBlock',
	CtaTeaserBlock = 'CtaTeaserBlock',
	CVPage = 'CVPage',
	DailyTelegramPage = 'DailyTelegramPage',
	DisclaimerBlock = 'DisclaimerBlock',
	DisclaimerDisplaySettingsBlock = 'DisclaimerDisplaySettingsBlock',
	DisclaimerFunctionalityBlock = 'DisclaimerFunctionalityBlock',
	DefaultPage = 'DefaultPage',
	DocumentFile = 'DocumentFile',
	ExecutionQualityReportsBlock = 'ExecutionQualityReportsBlock',
	FactsBlock = 'FactsBlock',
	FactsHighlightBlock = 'FactsHighlightBlock',
	FooterSettings = 'FooterSettings',
	FormCampaignBlock = 'FormCampaignBlock',
	FormContainerBlock = 'FormContainerBlock',
	FormFieldHiddenElementBlock = 'FormFieldHiddenElementBlock',
	FormFieldHiddenHoneyPotBlock = 'FormFieldHiddenHoneyPotBlock',
	FormFieldSelectionContainerBlock = 'FormFieldSelectionContainerBlock',
	FormFieldSelectionItemBlock = 'FormFieldSelectionItemBlock',
	FormFieldTextBlock = 'FormFieldTextBlock',
	FormFieldFileUploadBlock = 'FormFieldFileUploadBlock',
	FormAtlassianBlock = 'FormAtlassianBlock',
	FormSalesforceBlock = 'FormSalesforceBlock',
	FormEmailBlock = 'FormEmailBlock',
	GenericMedia = 'GenericMedia',
	HeaderSettings = 'HeaderSettings',
	HeroBlock = 'HeroBlock',
	HeroTextBlock = 'HeroTextBlock',
	JobFilterBlock = 'JobFilterBlock',
	JobPage = 'JobPage',
	JobPortalSettings = 'JobPortalSettings',
	LandingPage = 'LandingPage',
	LanguagesSettings = 'LanguagesSettings',
	LayoutGroupBlock = 'LayoutGroupBlock',
	LinkBlock = 'LinkBlock',
	LinkListBlock = 'LinkListBlock',
	LocalizableImageFile = 'LocalizableImageFile',
	LocalizableVectorImageFile = 'LocalizableVectorImageFile',
	LocationPage = 'LocationPage',
	MediaAssetsSettings = 'MediaAssetsSettings',
	MediaAssetsSettingsCloudinaryBlock = 'MediaAssetsSettingsCloudinaryBlock',
	MediaAssetsSettingsMediaTypesBlock = 'MediaAssetsSettingsMediaTypesBlock',
	NotFoundPage = 'NotFoundPage',
	PageDisclaimerBlock = 'PageDisclaimerBlock',
	PageElementsBlock = 'PageElementsBlock',
	PageFolder = 'PageFolder',
	PageWithHero = 'PageWithHero',
	PageWithoutHero = 'PageWithoutHero',
	PersonalisationSettings = 'PersonalisationSettings',
	PersonBlock = 'PersonBlock',
	PersonBlocksExportPage = 'PersonBlocksExportPage',
	PersonDetailsBlock = 'PersonDetailsBlock',
	PersonTeaserBlock = 'PersonTeaserBlock',
	PropertyHeadingBlock = 'PropertyHeadingBlock',
	SalesforceConfigsBlock = 'SalesforceConfigsBlock',
	SEOSettings = 'SEOSettings',
	SEOSettingsOpenGraphDataBlock = 'SEOSettingsOpenGraphDataBlock',
	SEOSettingsOrganizationDataBlock = 'SEOSettingsOrganizationDataBlock',
	SEOSettingsRobotsTxtBlock = 'SEOSettingsRobotsTxtBlock',
	SEOSettingsSecurityTxtBlock = 'SEOSettingsSecurityTxtBlock',
	SEOSettingsSiteBlock = 'SEOSettingsSiteBlock',
	SettingsFolder = 'SettingsFolder',
	SharePriceBlock = 'SharePriceBlock',
	StartPage = 'StartPage',
	SysContentAssetFolder = 'SysContentAssetFolder',
	SysContentFolder = 'SysContentFolder',
	SysRecycleBin = 'SysRecycleBin',
	SysRoot = 'SysRoot',
	TabItemBlock = 'TabItemBlock',
	TabsBlock = 'TabsBlock',
	TealiumSettingsBlock = 'TealiumSettingsBlock',
	TextBlock = 'TextBlock',
	TextImageTeaserBlock = 'TextImageTeaserBlock',
	TrackingSettings = 'TrackingSettings',
	VideoFile = 'VideoFile',
	VimeoVideoBlock = 'VimeoVideoBlock',
	WealthCheckBlock = 'WealthCheckBlock',
	VoltPortfolioSimulatorBlock = 'VoltPortfolioSimulatorBlock',
	Volt3aPensionCalculatorBlock = 'Volt3aPensionCalculatorBlock',
}
