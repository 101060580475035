'use client';

import { ITrackingEventData } from '@/types/tracking';
import { createContext } from 'react';

/**
 * Do not use.
 * @internal
 */
export interface ITrackedContextValue {
	/**
	 * Gets the trackingEventData recursively for the next tracked parent component in the component tree.
	 */
	collectTrackingData(eventName: string): ITrackingEventData;
}

/**
 * Do not use.
 *
 * Collects and merges Tracking Data recursively.
 *
 * @internal
 */
export const TrackedContext = createContext<ITrackedContextValue>({
	collectTrackingData: (_) => ({
		event: {},
		levels: [],
		nestingLevel: -1,
	}),
});
