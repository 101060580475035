import { logger } from '@/utils/logger';

export const fetcher = async (
	input: RequestInfo | URL,
	init?: RequestInit | undefined,
	searchParams?: URLSearchParams
) => {
	let url = input;

	if (searchParams && searchParams.toString().length) {
		url += `?${searchParams.toString()}`;
	}

	const { headers: initHeaders, ...initRest } = init || {};

	logger.debug(`Fetching ${url}`);

	try {
		const result = await fetch(url, {
			...(init?.next?.revalidate ? {} : { cache: 'no-cache' }),
			...initRest,
			headers: { ...initHeaders, Accept: 'application/json' },
		});

		logger.debug(`${url} returned ${result.status}`);

		return result;
	} catch (error) {
		logger.error(`Failed to fetch ${url} with error: ${error}`);

		if (initHeaders) {
			Object.keys(initHeaders as unknown as Record<any, any>).forEach((key) => {
				logger.debug(key, (initHeaders as Record<any, any>)[key]);
			});
		}

		return new Response(null, { status: 500 });
	}
};
