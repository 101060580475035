import { sva } from '@/styled-system/css';

export const previewModeBannerRecipe = sva({
	slots: ['container', 'contentWrapper', 'iconTextContainer', 'title', 'description'],
	base: {
		container: {
			display: 'flex',
			justifyContent: 'center',
			backgroundColor: 'evolution.citrine.40',
			py: 3,
			px: 6,
			md: {
				py: 6,
				px: 8,
			},
		},
		contentWrapper: {
			maxW: 'var(--content-max-width)',
			width: 'full',
			display: 'flex',
			justifyContent: 'space-between',
		},
		iconTextContainer: {
			flexGrow: 1,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			gap: 3,
			md: {
				justifyContent: 'flex-start',
				gap: 4,
			},
		},
		title: {
			fontWeight: 'bold',
			fontSize: '0.825rem',
			md: {
				fontSize: '1rem',
			},
		},
		description: {
			fontSize: '0.75rem',
			fontWeight: 'medium',
		},
	},
});
