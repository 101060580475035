import { evolutionConfig } from '@/constants/evolutionConfig';
import { Box, BoxProps } from '@/styled-system/jsx';
import { PageContext } from '@/utils/pageContextProvider';
import { useContext } from 'react';

export const NavigationEvolution = (props: BoxProps) => {
	const { siteName, themeName } = useContext(PageContext);
	const IconComponent = evolutionConfig[siteName]?.navigationEvolution;

	if (!IconComponent) {
		return null;
	}

	return (
		<Box position="absolute" bottom={0} right={0} hideBelow="lg" {...props}>
			<IconComponent width={themeName !== 'foundations' ? 'min(25vw, 428px)' : undefined} />
		</Box>
	);
};
