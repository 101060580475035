import { EvolutionConfig, SiteEvolutionConfig } from '@/types/feature-flags';
import dynamic from 'next/dynamic';

const defaultEvolutionConfig: EvolutionConfig = {
	navigationEvolution: dynamic(() => import('@/icons/EvolutionSystemIcon').then((mod) => mod.EvolutionSystemIcon)),
	loginEvolution: dynamic(() => import('@/icons/LoginEvolutionSvg').then((mod) => mod.LoginEvolutionSvg)),
	heroEvolution: dynamic(() =>
		import('@/components/shared/Hero/components/HeroEvolution/DefaultHeroEvolution').then(
			(mod) => mod.DefaultHeroEvolution
		)
	),
};

const stripedEvolutionConfig: EvolutionConfig = {
	navigationEvolution: dynamic(() =>
		import('@/icons/HeroEvolutionStripedLightSvg').then((mod) => mod.HeroEvolutionStripedLightSvg)
	),
	loginEvolution: dynamic(() =>
		import('@/icons/HeroEvolutionStripedLightSvg').then((mod) => mod.HeroEvolutionStripedLightSvg)
	),
	heroEvolution: dynamic(() =>
		import('@/components/shared/Hero/components/HeroEvolution/StripedHeroEvolution').then(
			(mod) => mod.StripedHeroEvolution
		)
	),
};

const longStripedHeroEvolutionConfig: EvolutionConfig = {
	navigationEvolution: dynamic(() =>
		import('@/icons/FoundationsNavigationEvolutionIcon').then((mod) => mod.FoundationsNavigationEvolutionIcon)
	),
	loginEvolution: dynamic(() =>
		import('@/icons/FoundationsNavigationEvolutionIcon').then((mod) => mod.FoundationsNavigationEvolutionIcon)
	),
	heroEvolution: null,
	displayFoundationsHeroEvolution: true,
};

export const evolutionConfig: SiteEvolutionConfig = {
	corpweb: defaultEvolutionConfig,
	vonsec: defaultEvolutionConfig,
	vorsorgestiftung: defaultEvolutionConfig,
	spendenstiftung: defaultEvolutionConfig,
	cosmofunding: stripedEvolutionConfig,
	deritrade: stripedEvolutionConfig,
	lyra: null,
	vosti: longStripedHeroEvolutionConfig,
	tb: defaultEvolutionConfig,
	volt: defaultEvolutionConfig,
};
