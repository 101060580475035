import { Button, ButtonProps } from '@/components/core/Button/Button';
import { FilterIcon } from '@/icons/FilterIcon';
import { css } from '@/styled-system/css';
import { FC } from 'react';

export const FilterToggle: FC<{ isActive: boolean } & ButtonProps> = ({ isActive, ...rest }) => {
	const buttonClasses = isActive
		? css({
				color: 'text.inverted.default',
				backgroundColor: 'actions.links.regular.default',
				borderColor: 'actions.links.regular.default',
				_hover: {
					backgroundColor: 'actions.links.regular.hoverAndPressed !important',
				},
			})
		: css({
				color: 'text.regular.default',
			});

	return (
		<Button
			display={{ base: 'flex', md: 'none' }}
			className={buttonClasses}
			size="medium"
			variant="secondary"
			leftIcon={<FilterIcon />}
			{...rest}
		/>
	);
};
