import englishLocale from '@/utils/i18n/dayjs/en';
import germanLocale from '@/utils/i18n/dayjs/de';
import frenchLocale from '@/utils/i18n/dayjs/fr';
import italianLocale from '@/utils/i18n/dayjs/it';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

const isDayjsInitialized = false;

export const initializeDayJS = () => {
	if (isDayjsInitialized) {
		return;
	}

	dayjs.extend(updateLocale);
	dayjs.extend(utc);
	dayjs.extend(timezone);
	dayjs.locale('en', englishLocale(''));

	dayjs.locale('en-ch', englishLocale('ch'));
	dayjs.locale('en-de', englishLocale('de'));
	dayjs.locale('en-us', englishLocale('us'));

	dayjs.locale('de-ch', germanLocale('ch'));
	dayjs.locale('de-de', germanLocale('de'));

	dayjs.locale('it-ch', italianLocale('ch'));
	dayjs.locale('it-it', italianLocale('it'));

	dayjs.locale('fr-ch', frenchLocale('ch'));
};
