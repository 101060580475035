import { sva } from '@/styled-system/css';

export const dropdown = sva({
	slots: [
		'root',
		'label',
		'select',
		'trigger',
		'icon',
		'positioner',
		'content',
		'option',
		'tag',
		'footer',
		'helperText',
		'error',
		'placeholder',
	],
	variants: {
		disabled: {
			true: {
				select: {
					background: 'surface.interactive.transparent.regular.disabled',
					pointerEvents: 'none',
				},
				trigger: {
					color: 'text.regular.disabled',
					borderColor: 'other.border.regular.default',
				},
				icon: {
					color: 'icons.regular.disabled',
				},
			},
		},
		readOnly: {
			true: {
				select: {
					background: 'surface.interactive.transparent.regular.disabled',
					pointerEvents: 'none',
				},
				trigger: {
					color: 'text.regular.subtitle',
					borderColor: 'other.border.regular.default',
				},
				icon: {
					color: 'icons.regular.disabled',
				},
			},
		},
		multiple: {
			true: {
				trigger: {
					py: 1,
				},
				option: {
					width: 'full',
					height: 'auto',
					px: 0,
					py: 0,
					border: 'none',

					'&[data-selected="false"]': {
						fontWeight: 'regular',

						'& [data-state="unchecked"]': {
							fontWeight: 'regular',
						},
					},

					'& [data-scope="checkbox"]': {
						'&[data-part="root"]': {
							height: 'full',
							width: 'full',
							color: 'text.regular.default',
							py: 3,
							px: 2,
						},
					},
				},
			},
		},
		isError: {
			true: {
				trigger: {
					borderColor: 'trafficLights.error.default',

					_focus: {
						borderColor: 'trafficLights.error.default',
					},
				},
				helperText: {
					color: 'text.regular.danger',
				},
				error: {
					color: 'text.regular.danger',
				},
			},
		},
		hidden: {
			true: {
				root: {
					display: 'none',
				},
			},
		},
		scrolling: {
			true: {
				content: {
					overflow: 'auto',
					maxH: '400px',
				},
			},
		},
	},
	compoundVariants: [
		{
			multiple: true,
			readOnly: true,
			css: {
				tag: {
					bg: 'actions.filterTags.regular.fillDefault',
					color: 'text.regular.default',
					fontWeight: 'semibold',
					pr: 2,
					borderWidth: '1px',
					borderStyle: 'solid',
					borderColor: 'other.border.regular.default',

					'& div': {
						display: 'none',
					},
				},
			},
		},
		{
			multiple: true,
			disabled: true,
			css: {
				tag: {
					bg: 'actions.filterTags.regular.fillDisabled',

					'& span': {
						opacity: 0.3,
						color: 'actions.filterTags.regular.textAndIconDisabled',
					},

					'& div': {
						opacity: 0.3,

						'& svg': {
							color: 'actions.filterTags.regular.textAndIconDisabled',
						},
					},
				},
			},
		},
	],
	base: {
		root: {
			w: 'full',
			display: 'flex',
			flexDirection: 'column',
			color: 'text.regular.default',
		},
		label: {
			mb: 2,
		},
		select: {
			w: 'full',
		},
		trigger: {
			w: 'full',
			minH: 10,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			cursor: 'pointer',
			py: 2,
			px: 3,
			lineHeight: '20.44px',
			borderWidth: 1,
			borderColor: 'other.border.regular.default',
			borderRadius: 'sm',
			color: 'text.regular.subtitle',

			'& svg': {
				minW: 4,
			},

			'&[data-selected="true"]': {
				color: 'text.regular.default',
			},

			'&[data-open="true"]': {
				borderColor: 'other.border.regular.focus',
			},

			_hover: {
				backgroundColor: 'surface.interactive.input.regular.hoverAndPressed',
			},

			_focus: {
				borderColor: 'other.border.regular.focus',
				outline: 'none',
			},

			_pressed: {
				borderColor: 'other.border.regular.focus',
			},
		},
		positioner: {
			overflow: 'hidden',

			md: {
				position: 'relative',
			},

			'&[data-open="true"]': {
				overflow: 'visible',
			},
		},
		content: {
			position: 'absolute',
			display: 'flex',
			flexDirection: 'column',
			left: 8,
			width: 'calc(100vw - 4rem)',
			bg: 'surface.interactive.transparent.regular.default',
			boxShadow: 'strongBottom',
			zIndex: 1,
			gap: 2,
			p: 2,
			borderRadius: 8,

			md: {
				left: 'unset',
				width: 'full',
			},
		},
		option: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			cursor: 'pointer',
			border: '1px solid transparent',
			borderRadius: 8,
			py: 3,
			px: 3,

			'&[data-selected="true"]': {
				bg: 'surface.interactive.transparent.regular.default',
				fontWeight: 'semibold',
			},

			_hover: {
				bg: 'surface.interactive.transparent.regular.hover',
			},

			_pressed: {
				bg: 'surface.interactive.transparent.regular.pressed',
			},

			_active: {
				bg: 'surface.interactive.transparent.regular.pressed',
			},

			_disabled: {
				pointerEvents: 'none',
				bg: 'surface.interactive.transparent.regular.default',
				color: 'text.regular.disabled',
			},
		},
		footer: {
			display: 'flex',
			alignItems: 'center',
			mt: 2,
			gap: 1,
		},
		helperText: {
			color: 'text.regular.subtitle',
			mt: 1,
		},
	},
});
