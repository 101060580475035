import type { FC, SVGAttributes } from 'react';

export const ChevronLeftIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	const { width = '24px', height = '24px', ...rest } = props;

	return (
		<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...rest}>
			<path d="m19.52 5.5 1.5 1.5-9.21 9 9.21 9-1.5 1.5L8.98 16 19.52 5.5Z" fill="currentColor" />
		</svg>
	);
};
