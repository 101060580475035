const weekdays = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
const months = [
	'Januar',
	'Februar',
	'März',
	'April',
	'Mai',
	'Juni',
	'Juli',
	'August',
	'September',
	'Oktober',
	'November',
	'Dezember',
];
const shortMonths = ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Juni', 'Juli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dez'];

export default function getGermanLocale(country: string): ILocale {
	return {
		name: 'de-' + country,
		weekdays: weekdays,
		weekStart: 1,
		weekdaysShort: weekdays.map((day) => day.substring(0, 3)),
		weekdaysMin: weekdays.map((day) => day.substring(0, 2)),
		months: months,
		monthsShort: shortMonths,
		formats: {},
		relativeTime: {},
	};
}
