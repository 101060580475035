import { ILinkListBlockTemplateProps } from '@/components/block-templates/LinkListBlockTemplate/LinkListBlockTemplate';
import { NavigationLink } from '@/components/core/NavigationLink/NavigationLink';
import { NavigationColumnContainer } from '@/components/shared/Navigation/components/NavigationColumnContainer';
import { NavigationLeafLink } from '@/components/shared/Navigation/components/NavigationLeafLink';
import { IBlockResponse } from '@/interfaces/coreInformation';
import { Box, HStack, HstackProps } from '@/styled-system/jsx';
import { logger } from '@/utils/logger';
import { FC, useState } from 'react';

interface INavigationContentProps extends HstackProps {
	data: Array<IBlockResponse<ILinkListBlockTemplateProps>>;
}

export const LoginNavigationContentDesktop: FC<INavigationContentProps> = ({ data, ...rest }) => {
	const [activeListIndex, setActiveListIndex] = useState<number>();

	return (
		<HStack
			alignItems="flex-start"
			maxH="530px"
			boxSizing="content-box"
			padding={{
				base: '24px 0px',
				sm: '0px',
			}}
			{...rest}
		>
			<NavigationColumnContainer
				ignoreDelimiterSpace={true}
				showDelimiter={false}
				flex={1}
				gap={{ base: 6, xl: 8 }}
				pr={{ base: '12px', lg: '24px', xl: '32px' }}
			>
				{data?.map((item, i) => {
					const label = item.contentLink?.expanded?.headline;
					const items = item.contentLink?.expanded?.items;

					if (!items?.length) {
						logger.warn(`Login item ${label} has no items.`);

						return null;
					}

					return (
						<NavigationLink
							key={item.contentLink?.id}
							isActive={activeListIndex === i}
							width="100%"
							pb={2}
							size={{ base: 'large', sm: 'small', md: 'medium', lg: 'large' }}
							label={label || ''}
							minH="unset"
							onClick={() => setActiveListIndex(i)}
						/>
					);
				})}
			</NavigationColumnContainer>
			{activeListIndex !== undefined && data[activeListIndex]?.contentLink?.expanded?.items?.length !== 0 ? (
				<NavigationColumnContainer
					ignoreDelimiterSpace={true}
					showDelimiter={false}
					flex={1}
					gap={{ base: 4, xl: 6 }}
					pr={{ base: '12px', lg: '24px', xl: '32px' }}
				>
					{data[activeListIndex]?.contentLink?.expanded?.items?.map((item) => {
						return (
							<NavigationLeafLink
								color="actions.links.regular.default"
								key={item.contentLink?.id}
								href={item.contentLink?.expanded?.link}
								title={item.contentLink?.expanded?.title}
								mb="0px"
							>
								{item.contentLink?.expanded?.displayText}
							</NavigationLeafLink>
						);
					})}
				</NavigationColumnContainer>
			) : null}
			{activeListIndex === undefined ? <Box flex="2" /> : null}
			{activeListIndex !== undefined ? <Box flex="1" hideBelow="lg" /> : null}
		</HStack>
	);
};
