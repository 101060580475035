'use client';
import {
	ILinkBlockTemplateProps,
	mapBlockPropsToLinkProps,
} from '@/components/block-templates/LinkBlockTemplate/LinkBlockTemplate';
import { ButtonLink, IButtonLinkProps } from '@/components/core/ButtonLink/ButtonLink';
import { Tracked } from '@/components/shared/tracking/Tracked';
import { Icon } from '@/enums/Icon';
import { FacebookIcon } from '@/icons/FacebookIcon';
import { LinkedinIcon } from '@/icons/LinkedinIcon';
import { TwitterIcon } from '@/icons/TwitterIcon';
import { IBlockDefinition } from '@/interfaces/IBlockDefinition';
import { IBlockResponse } from '@/interfaces/coreInformation';

import { getTrackingPropertiesFromCMSBlock } from '@/utils/tracking/tracking';

import React, { FC } from 'react';

interface IFooterLink extends Partial<IButtonLinkProps> {
	blockDefinition: IBlockResponse<ILinkBlockTemplateProps>;
	className?: string;
}

const getLinkBlockProps = (blockDefinition: IBlockDefinition) => {
	return {
		iconPosition: blockDefinition?.contentLink?.expanded?.iconPosition,
		link: blockDefinition?.contentLink?.expanded?.link,
		icon: blockDefinition?.contentLink?.expanded?.icon,
		displayText: blockDefinition?.contentLink?.expanded?.displayText,
		linkClickBehaviour: blockDefinition?.contentLink?.expanded?.linkClickBehaviour,
		title: blockDefinition?.contentLink?.expanded?.title,
		buttonType: blockDefinition?.contentLink?.expanded?.buttonType,
	} as ILinkBlockTemplateProps;
};

export const FooterLinkBlock: FC<IFooterLink> = ({ blockDefinition, className, ...rest }) => {
	const linkBlockProps = getLinkBlockProps(blockDefinition);
	const linkProps = linkBlockProps ? mapBlockPropsToLinkProps(linkBlockProps) : undefined;

	const trackingRef = React.createRef<HTMLLinkElement>();

	const icon = (() => {
		const iconIdentifier = linkBlockProps?.icon;

		switch (iconIdentifier) {
			case Icon.LINKEDIN:
				return <LinkedinIcon width="24" height="24" />;
			case Icon.TWITTER:
				return <TwitterIcon width="24" height="24" />;
			case Icon.FACEBOOK:
				return <FacebookIcon width="24" height="24" />;

			default:
				return undefined;
				break;
		}
	})();

	if (!linkProps) {
		return null;
	}

	return (
		<Tracked
			trackingElementRef={trackingRef}
			{...getTrackingPropertiesFromCMSBlock(blockDefinition.contentLink?.expanded)}
			href={linkProps.href}
		>
			<ButtonLink
				color="text.inverted.default"
				variant="unstyled"
				role="link"
				size="unsized"
				textDecoration="none"
				_hover={{
					textDecoration: 'underline',
				}}
				{...linkProps}
				{...rest}
				leftIcon={icon}
				ref={trackingRef}
			>
				{linkBlockProps.displayText}
			</ButtonLink>
		</Tracked>
	);
};
