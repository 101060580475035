const weekdays = ['Domenica', 'Lunedi', 'Martedi', 'Mercoledi', 'Giovedi', 'Venerdi', 'Sabato'];
const months = [
	'Gennaio',
	'Febbraio',
	'Marzo',
	'Aprile',
	'Maggio',
	'Giugno',
	'Luglio',
	'Agosto',
	'Settembre',
	'Ottobre',
	'Novembre',
	'Dicembre',
];
const shortMonths = ['Genn', 'Febbr', 'Mar', 'Apr', 'Magg', 'Giugno', 'Luglio', 'Ag', 'Sett', 'Ott', 'Nov', 'Dic'];

export default function getItalianLocale(country: string): ILocale {
	return {
		name: 'it-' + country,
		weekdays: weekdays,
		weekStart: 1,
		weekdaysShort: weekdays.map((day) => day.substring(0, 3)),
		weekdaysMin: weekdays.map((day) => day.substring(0, 2)),
		months: months,
		monthsShort: shortMonths,
		formats: {},
		relativeTime: {},
	};
}
